import { ColorLike } from 'ol/colorlike';

export interface StyleColorPalette {
  readonly maskFill: ColorLike;
  /** The background fill will change country colours because the OSM ocean is displayed on top. */
  readonly backgroundFill: ColorLike;
  readonly greeneryFill: ColorLike;
  readonly textStroke: ColorLike;
  readonly textFill: ColorLike;
  readonly sandFill: ColorLike;
  /** The ocean is used to perform an OSM land boundary cut around countries. */
  readonly oceanFill: ColorLike;
  readonly waterBodiesFill: ColorLike;
  readonly buildingStroke: ColorLike;
  readonly buildingFill: ColorLike;
  readonly smallStreetStroke: ColorLike;
  readonly largeStreetStroke: ColorLike;
  readonly nationalAdminBoundaryStroke: ColorLike;
  readonly subnationalAdminBoundaryStroke: ColorLike;
  readonly largePlaceCircleFill: ColorLike;
  readonly largePlaceCircleStroke: ColorLike;
  readonly smallPlaceCircleFill: ColorLike;
  readonly smallPlaceCircleStroke: ColorLike;
  /** A cluster is a group of points shown as a single item at higher zoom levels. */
  readonly clusterGroupTextFill: ColorLike;
  readonly clusterGroupBackgroundFill: ColorLike;
  /** Misc. structures e.g. bridges. */
  readonly structureFill: ColorLike;
}

export const lightStyleColorPalette: StyleColorPalette = {
  maskFill: 'rgb(255, 255, 255)',
  backgroundFill: 'rgb(255, 255, 255)',
  greeneryFill: 'rgba(0, 0, 0, 0.05)',
  textFill: 'rgba(0, 0, 0, 0.8)',
  textStroke: 'rgba(255, 255, 255, 0.6)',
  sandFill: 'rgb(246, 233, 221)',
  oceanFill: 'rgb(242, 238, 232)',
  waterBodiesFill: 'rgb(242, 238, 232)',
  buildingStroke: 'rgb(255, 255, 255)',
  buildingFill: 'rgb(225, 225, 225)',
  smallStreetStroke: 'rgb(209, 209, 209)',
  largeStreetStroke: 'rgb(209, 209, 209)',
  nationalAdminBoundaryStroke: 'rgb(100, 100, 100)',
  subnationalAdminBoundaryStroke: 'rgba(0, 0, 0, 0.3)',
  largePlaceCircleFill: '#000',
  largePlaceCircleStroke: '#EEE',
  smallPlaceCircleFill: '#EEE',
  smallPlaceCircleStroke: '#222',
  clusterGroupTextFill: '#333',
  clusterGroupBackgroundFill: 'white',
  structureFill: 'rgb(209, 209, 209)',
};

export const darkStyleColorPalette: StyleColorPalette = {
  maskFill: 'rgb(125, 125, 125)',
  backgroundFill: 'rgb(125, 125, 125)',
  greeneryFill: 'rgb(204, 222, 185)',
  textFill: 'rgba(255, 255, 255, 0.85)',
  textStroke: 'rgba(0, 0, 0, 0.4)',
  sandFill: 'rgb(112, 112, 112)',
  oceanFill: 'rgb(85, 85, 85)',
  waterBodiesFill: 'rgb(85, 85, 85)',
  buildingStroke: 'rgb(75, 75, 75)',
  buildingFill: 'rgb(95, 95, 95)',
  smallStreetStroke: 'rgb(70, 70, 70)',
  largeStreetStroke: 'rgb(70, 70, 70)',
  nationalAdminBoundaryStroke: 'rgb(60, 60, 60)',
  subnationalAdminBoundaryStroke: 'rgba(0, 0, 0, 0.3)',
  largePlaceCircleFill: '#000',
  largePlaceCircleStroke: '#EEE',
  smallPlaceCircleFill: '#EEE',
  smallPlaceCircleStroke: '#222',
  clusterGroupTextFill: 'rgb(245, 245, 245)',
  clusterGroupBackgroundFill: 'rgb(70, 70, 70)',
  structureFill: 'rgb(70, 70, 70)',
};
