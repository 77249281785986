/** The type of a column in a Item Information Listing  */
export enum ItemInformationListingColumnType {
  /** Just prints the value */
  PLAIN = 'plain',
  /** Prints the value as plain text after passing it through a pipe to remove HTML tags */
  STRIPPEDPLAIN = 'stripped plain',
  /** Binds safe html content to innerHTML */
  HTML = 'html',
  /** Renders the value with fixed Date pipe */
  DATE = 'date',
  /** Used to render the actions menu */
  ACTIONS = 'actions',
  /** Used for info popdown icon column */
  INFO = 'info',
  /** Used for announcement icon column */
  ANNOUNCEMENT = 'announcements',
  /** Prints the value as plain text after passing it through a pipe to remove HTML tags - Ellipsis and non truncated via pipe */
  DESCRIPTION = 'description',
  /** Used for expandable arrow icon (carrot) */
  EXPAND = 'expand',
}
