import { GeoLocation, GeoLocationTypeLabels } from '@grid-ui/common';
import { ApiGeoLocation } from '../models';
/**
 * Maps a location as returned by the API to a GeoLocation for
 * internal App use.
 *
 * @param apiLocation A location returned by the API
 */
export function mapLocations(apiLocation: ApiGeoLocation): GeoLocation {
  let typeLabel: GeoLocationTypeLabels;
  switch (apiLocation.collection) {
    case 'airports':
      typeLabel = 'Airports';
      break;
    case 'cities':
      typeLabel = 'Cities';
      break;
    case 'ports':
      typeLabel = 'Ports';
      break;
    case 'sites':
      typeLabel = 'Sites';
      break;
    default:
      typeLabel = apiLocation.type === 'country' ? 'Countries' : 'Sites';
  }
  const location: GeoLocation = {
    ...apiLocation,
    typeLabel,
  };
  return location;
}
