import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconModule, LoadingModule, SearchableDropdownModule, UnexpectedErrorStatusModule } from '@grid-ui/common';
import { FeatureFlagModule } from '@grid-ui/feature-flag';
import { PermissionsModule } from '@grid-ui/permissions';
import { WhoAmIEffects } from '@grid-ui/whoami';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ApiServicesModule } from '../api-services';
import { PreviewModeWatermarkComponent } from '../preview-mode-watermark/preview-mode-watermark.component';
import { GridSharedPipesModule } from '../shared-ui-components/shared-pipes/shared-pipes.module';
import { WindowProviderModule } from '../shared-utilities/window-services';
import { AppSideAreaComponent, AppSideAreaLinksComponent } from './app-side-area';
import {
  AccountSettingsModalComponent,
  AccountSettingsModalService,
  AppTopAreaComponent,
  TopRightHelpMenuComponent,
  TopRouteBreadcrumbsComponent,
  TopUserMenuComponent,
} from './app-top-area';
import { RoutedErrorComponent } from './routed-error';
import { NavService, SvgService, TopBreadcrumbTrailService } from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbTooltipModule,
    ApiServicesModule.forRoot(),
    LoadingModule,
    SearchableDropdownModule,
    GridSharedPipesModule,
    WindowProviderModule.forRoot(),
    UnexpectedErrorStatusModule,
    FeatureFlagModule,
    IconModule,
    PermissionsModule,
  ],
  declarations: [
    AccountSettingsModalComponent,
    AppSideAreaComponent,
    AppTopAreaComponent,
    TopRouteBreadcrumbsComponent,
    AppSideAreaLinksComponent,
    TopUserMenuComponent,
    RoutedErrorComponent,
    TopRightHelpMenuComponent,
    PreviewModeWatermarkComponent,
  ],
  exports: [AppSideAreaComponent, AppTopAreaComponent, RoutedErrorComponent, PreviewModeWatermarkComponent],
  providers: [AccountSettingsModalService, TopBreadcrumbTrailService, NavService, SvgService, WhoAmIEffects],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
