import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '../../shared-pipes';
import { BodyItemModule } from '../body-item';
import { ErrorModule } from '../error';
import { IconModule } from '../icon';
import { LoadingModule } from '../loading';
import { IndexInfoPopoverComponent } from './index-info-popover';
import { IndexInfoPopoverService } from './service';

@NgModule({
  imports: [CommonModule, NgbModalModule, NgbTooltipModule, SharedPipesModule, ErrorModule, LoadingModule, BodyItemModule, IconModule],
  declarations: [IndexInfoPopoverComponent],
  exports: [IndexInfoPopoverComponent],
  providers: [IndexInfoPopoverService],
})
export class IndexInfoPopoverModule {}
