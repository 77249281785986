import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { environment } from '@grid-ui/environment';
import { ErrorType, LoggingService } from '@grid-ui/logging';
import { Severity } from '@sentry/browser';
import { isNil } from 'ramda';
import { firstValueFrom, of, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { FeatureFlagKey, FeatureFlagKeyArg } from '../../feature-flag-key.model';
import { APIFeatureFlags } from '../models';
import { FEATURE_FLAG_RETRIES, FEATURE_FLAG_URL, FEATURE_FLAGS_CONFIG } from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly injector = inject(Injector);
  private readonly http = inject(HttpClient);
  private readonly loggingService = inject(LoggingService);

  private readonly url = this.injector.get(FEATURE_FLAG_URL);
  private readonly retries = this.injector.get(FEATURE_FLAG_RETRIES);
  private readonly config = this.injector.get(FEATURE_FLAGS_CONFIG);

  private flags?: APIFeatureFlags | null;

  /**
   * A set of missing flags that we have logged to the console. This is used to prevent logging the missing flag multiple times.
   */
  private readonly reportedMissingFlags = new Set<FeatureFlagKey>();

  public initialise(): Promise<void> {
    return firstValueFrom(
      this.http.get<APIFeatureFlags>(this.url).pipe(
        retry(this.retries),
        /**
         * Swallow 403 and 401 errors to be handled by a global error interceptor.
         */
        catchError((error) => {
          if ([401, 403].includes(error.status)) {
            return of(null);
          }

          return throwError(error);
        }),
        tap((flags) => this.setFlags(flags)),
        map(() => undefined),
      ),
    );
  }

  /**
   * Checks if a feature flag exists and returns its value.
   */
  public getFlagValue(key: FeatureFlagKeyArg): boolean {
    if (!this.flags) {
      throw new Error('FeatureFlagService not initialised');
    }

    if (!key) {
      return true;
    }

    let sanitisedKey: FeatureFlagKey;
    let inverse = false;

    if (key?.startsWith('!')) {
      inverse = true;
      sanitisedKey = key.substring(1) as FeatureFlagKey;
    } else {
      sanitisedKey = key as FeatureFlagKey;
    }

    let flag = this.flags[key];

    if (flag === undefined && !this.reportedMissingFlags.has(sanitisedKey)) {
      this.reportedMissingFlags.add(sanitisedKey);

      console.warn(
        `FeatureFlagService::getFlag() was called for "${sanitisedKey}" however "${sanitisedKey}" was not defined. Defaulting to false.`,
      );
    }

    if (inverse) {
      flag = !flag;
    }

    if (flag === true) {
      return true;
    }

    return false;
  }

  private setFlags(flags: APIFeatureFlags | null | undefined): void {
    if (flags) {
      this.flags = flags;

      this.reportUnconfiguredFlags();

      this.reportFlagsToRemove();
    }
  }

  private reportUnconfiguredFlags(): void {
    /**
     * Flag keys that have been retrieved from the API but not configured in the FEATURE_FLAGS_CONFIG.
     */
    const unconfiguredAPIKeys = Object.keys(this.flags).filter((flagKey) => isNil(this.config[flagKey]));

    if (unconfiguredAPIKeys.length) {
      this.log(
        'api',
        ErrorType.FlagsUnconfigured,
        `There are feature flags defined in GRiD admin that are not configured in the frontend: ${unconfiguredAPIKeys.join(', ')}`,
      );
    }

    /**
     * Flag keys that have been retrieved from the API but not configured in the FEATURE_FLAGS_CONFIG.
     */
    const unconfiguredConfigKeys = Object.keys(this.config).filter((flagKey) => isNil(this.flags[flagKey]));

    if (unconfiguredConfigKeys.length) {
      this.log(
        'config',
        ErrorType.FlagsMissingInAPI,
        `There are feature flags configured in the frontend that are not defined in GRiD admin: ${unconfiguredConfigKeys.join(', ')}`,
      );
    }
  }

  private reportFlagsToRemove(): void {
    const now = new Date();

    const flagsToRemove = Object.entries(this.config).filter(([_, { removeBy }]) => removeBy && removeBy < now);

    if (flagsToRemove.length) {
      this.log(
        'expired',
        ErrorType.FlagsToRemove,
        `There are feature flags configured that should have been removed: ${flagsToRemove
          .map(([key, { removeBy }]) => `${key} by ${removeBy.toLocaleDateString('en-GB')}`)
          .join(', ')}`,
      );
    }
  }

  private log(type: 'api' | 'config' | 'expired', errorType: ErrorType, message: string): void {
    const storeageKey = `flags-logged.${type}`;
    const storedLog = localStorage.getItem(storeageKey);

    if (storedLog !== message) {
      this.loggingService.log(message, { level: Severity.Warning, errorType });

      localStorage.setItem(storeageKey, message);
    }

    if (!environment.production) {
      console.warn(message);
    }
  }
}
