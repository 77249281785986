import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthorInformation } from '../../../shared-models';
import { AskAnAnalystSourceEnum } from '../../../shared-services';
import { AskAnAnalystModalComponent } from '../ask-an-analyst-modal';
import { AskAnAnalystAboutSubject, AskAnAnalystMetaData } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AskAnAnalystModalService {
  public constructor(private readonly ngBoostrapModalService: NgbModal) {}

  /**
   * Show the "Ask an Analyst Modal" for the specified Analyst, or
   * the general "unknown analyst" version, if none was specified.
   *
   * @param analystInfo Author information for a specific analyst to contact,
   * or `null`, if not known. Defaults to `null`.
   * @param metaData Meta data to be used for Ask An Analyst API request
   */
  public show(
    metaData: AskAnAnalystMetaData | null = null,
    analystInfo: AuthorInformation | null = null,
    source: AskAnAnalystSourceEnum,
    ngbModalOptions?: NgbModalOptions,
  ): NgbModalRef {
    const modalOptions: NgbModalOptions = { centered: true, backdrop: true, keyboard: true, ...ngbModalOptions };

    const modalRef = this.ngBoostrapModalService.open(AskAnAnalystModalComponent, modalOptions);

    (<AskAnAnalystModalComponent>modalRef.componentInstance).analystInfo = analystInfo;
    (<AskAnAnalystModalComponent>modalRef.componentInstance).metaData = metaData;
    (<AskAnAnalystModalComponent>modalRef.componentInstance).source = source;

    if (metaData) {
      if (metaData.type === 'fixedSubject') {
        (<AskAnAnalystModalComponent>modalRef.componentInstance).fixedSubject = metaData.subject;
      }
      const questionPlaceholder = (metaData as AskAnAnalystAboutSubject).questionPlaceholder;
      if (questionPlaceholder) {
        (<AskAnAnalystModalComponent>modalRef.componentInstance).questionPlaceholder = questionPlaceholder as string;
      }
    }
    return modalRef;
  }
}
