import { Injectable } from '@angular/core';
import { EnvironmentAppConfig, GridEnvironment, environment } from '@grid-ui/environment';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  public getConfig(): EnvironmentAppConfig {
    return R.clone(environment.appConfig);
  }

  public getEnvironmentName(): string {
    return environment.environment;
  }

  public getEnvironment(): GridEnvironment {
    return R.clone(environment);
  }
}
