import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  CommodityRiskResourcesConfig,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { CommodityRiskFeaturedContent } from '../../../shared-models';
import { ApiCommodityRiskFeaturedContentCollection } from '../models';

@Injectable()
/**
 * Service for accessing the Commodity Risk Feature Content API
 */
export class CommodityRiskFeaturedContentService {
  private commodityRiskResourceConfig: CommodityRiskResourcesConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.commodityRiskResourceConfig = API_SERVICES_CONFIG.v3.commodityRisk;
  }

  /**
   * Get a list of all featured content.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getFeaturedContents(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<CommodityRiskFeaturedContent> {
    // TODO: Caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiCommodityRiskFeaturedContentCollection>(
      this.commodityRiskResourceConfig.content.featured._configuration,
      options,
    );
  }
}
