import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorType, LoggingService } from '@grid-ui/logging';
import { Severity } from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadResourceService {
  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
  ) {}

  public download(url: string): Observable<HttpResponse<any>> {
    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) => {
          const message = `PDF Download Failed: ${error && error.message ? error.message : url}`;
          this.loggingService.log(message, { level: Severity.Error, errorType: ErrorType.PDFDownload });
          return throwError(error as any);
        }),
      );
  }

  public downloadAsXlsx(url: string): Observable<Blob> {
    return this.http.get(url, {
      headers: new HttpHeaders({ Accept: 'application/vnd.ms-excel' }),
      responseType: 'blob',
    });
  }

  public getFilenameByContendDisposition(contentDisposition: string): string {
    return contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
  }
}
