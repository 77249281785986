import { ColDef } from '@ag-grid-community/core';
import { GridFilterType } from './models';

export const determineIndexGridFilterType = (colDef: ColDef): GridFilterType => {
  let filterType = GridFilterType.TEXT;
  switch (colDef.field) {
    case 'theme':
    case 'theme_name':
    case 'restricted':
    case 'is_subnational':
    case 'sector_name':
    case 'sub_sector':
      filterType = GridFilterType.SET;
      break;
    case 'maplecroftRiskViews':
      filterType = GridFilterType.MAPLECROFT_RISK_VIEW;
      break;
  }
  return filterType;
};
