import { PaginatedV4Response, PaginatedCollection } from '../../..';

export enum DataAlertTriggerLookup {
  GreaterThanOrEqual = 'gte',
  LesserThanOrEqual = 'lte',
}

export enum DataAlertTriggerLookupLabels {
  gte = 'Score greater than or equal to',
  lte = 'Score less than or equal to',
}

export interface DataAlertThreshold {
  value: string;
  lookup_type: `${DataAlertTriggerLookup}`;
}

export interface DataAlertTrigger {
  trigger_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  thresholds: DataAlertThreshold[];
  location_ids: string[];
  index_ids: string[];
  url: string;
}

export interface DataAlertTriggerBody {
  name: string;
  thresholds: DataAlertThreshold[];
  location_ids: string[];
  index_ids: string[];
}

export type DataAlertTriggers = PaginatedV4Response<DataAlertTrigger>;
export type DataAlertTriggerPaginatedCollection = PaginatedCollection<DataAlertTrigger, DataAlertTriggerQueryParams>;

export interface DataAlertTriggerQueryParams {
  search?: string;
  // comma separated: created_at, name
  sort?: string;
  page?: number;
  page_size?: number;
}
