import { ColDef } from '@ag-grid-community/core';
import { CustomSelectableHeaderComponent } from '../../../shared-components';

/* eslint-disable @typescript-eslint/no-shadow */
export enum CustomGridColumnTypeNames {
  ALWAYS_HIDE_COLUMN = 'Always Hide Column',
  FILTER_TEXT = 'Filter Text',
  FILTER_DATE = 'Filter Date',
  FILTER_NUMBER = 'Filter Number',
  FIXED_FIRST_COLUMN = 'FixedFirstColumn',
  HEADER_SELECTABLE_COLUMN_FILTERED_ONLY = 'HeaderSelectableColumnFilterOnly',
  HEADER_SELECTABLE_COLUMN_SELECT_ALL = 'HeaderSelectableColumnSelectAll',
  CUSTOM_SELECTABLE_COLUMN_HEADER = 'CustomSelectableColumnHeader',
  SELECTABLE_COLUMN = 'SelectableColumn',
  SORTABLE_COLUMN_WITH_UNSORT_ICON = 'SortableColumnWithUnsortIcon',
  SORTABLE_COLUMN_WITHOUT_UNSORT_ICON = 'SortableColumnWithoutUnsortIcon',
  UNSORTABLE_COLUMN = 'UnsortableColumn',
}
/* eslint-enable @typescript-eslint/no-shadow */

export const ALWAYS_HIDE_COLUMN: ColDef = {
  hide: true,
  lockVisible: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
};

export const FILTER_TEXT: ColDef = {
  filter: 'agTextColumnFilter',
  filterParams: {
    buttons: ['clear', 'apply'],
    filterOptions: ['contains'],
    defaultOption: 'contains',
    suppressAndOrCondition: true,
    closeOnApply: true,
  },
};

export const FILTER_DATE: ColDef = {
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['clear', 'apply'],
    browserDatePicker: true,
    filterOptions: ['greaterThanOrEqual', 'lessThanOrEqual', 'inRange', 'equalsDate'],
    defaultOption: 'inRange',
    suppressAndOrCondition: true,
    closeOnApply: true,
  },
};

export const FILTER_NUMBER: ColDef = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    buttons: ['clear', 'apply'],
    filterOptions: ['equals'],
    defaultOption: 'equals',
    suppressAndOrCondition: true,
    closeOnApply: true,
  },
};

export const FIXED_FIRST_COLUMN: ColDef = {
  minWidth: 200,
  initialWidth: 300,
  pinned: 'left',
  lockPinned: true,
  lockVisible: true,
  lockPosition: true,
  suppressMovable: true,
};

export const HEADER_SELECTABLE_COLUMN_FILTERED_ONLY: ColDef = {
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
};

export const HEADER_SELECTABLE_COLUMN_SELECT_ALL: ColDef = {
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: false,
};

export const CUSTOM_SELECTABLE_COLUMN_HEADER: ColDef = {
  headerComponent: CustomSelectableHeaderComponent,
};

export const SELECTABLE_COLUMN: ColDef = {
  checkboxSelection: true,
};

export const SORTABLE_COLUMN_WITH_UNSORT_ICON: ColDef = {
  sortable: true,
  sortingOrder: ['asc', 'desc', null],
  unSortIcon: true,
};

export const SORTABLE_COLUMN_WITHOUT_UNSORT_ICON: ColDef = {
  sortable: true,
  sortingOrder: ['asc', 'desc', null],
  unSortIcon: false,
};

export const UNSORTABLE_COLUMN: ColDef = {
  sortable: false,
};

const defaultCustomGridColumnTypes: { [key: string]: ColDef } = {};

defaultCustomGridColumnTypes[CustomGridColumnTypeNames.ALWAYS_HIDE_COLUMN] = ALWAYS_HIDE_COLUMN;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.FILTER_TEXT] = FILTER_TEXT;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.FILTER_DATE] = FILTER_DATE;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.FILTER_NUMBER] = FILTER_NUMBER;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.FIXED_FIRST_COLUMN] = FIXED_FIRST_COLUMN;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.HEADER_SELECTABLE_COLUMN_FILTERED_ONLY] = HEADER_SELECTABLE_COLUMN_FILTERED_ONLY;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.HEADER_SELECTABLE_COLUMN_SELECT_ALL] = HEADER_SELECTABLE_COLUMN_SELECT_ALL;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.CUSTOM_SELECTABLE_COLUMN_HEADER] = CUSTOM_SELECTABLE_COLUMN_HEADER;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.SELECTABLE_COLUMN] = SELECTABLE_COLUMN;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.SORTABLE_COLUMN_WITH_UNSORT_ICON] = SORTABLE_COLUMN_WITH_UNSORT_ICON;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.SORTABLE_COLUMN_WITHOUT_UNSORT_ICON] = SORTABLE_COLUMN_WITHOUT_UNSORT_ICON;
defaultCustomGridColumnTypes[CustomGridColumnTypeNames.UNSORTABLE_COLUMN] = UNSORTABLE_COLUMN;

export const DEFAULT_CUSTOM_GRID_COLUMN_TYPES = defaultCustomGridColumnTypes;
