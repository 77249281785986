import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WrittenContentAnchorClickListenerDirective } from './written-content-anchor-click-listener.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [WrittenContentAnchorClickListenerDirective],
  exports: [WrittenContentAnchorClickListenerDirective],
})
export class WrittenContentAnchorClickListenerModule {}
