import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VideoItemValue } from '../../../../shared-models';
import { BLANK_VIDEO_ITEM } from './blank-video-item.constant';
import { BLANK_VIDEO_OPTION } from './blank-video-option.constant';

@Component({
  selector: 'grid-ui-body-item-video-player',
  templateUrl: './body-item-video-player.component.html',
  styleUrls: ['./body-item-video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemVideoPlayerComponent implements OnChanges {
  @Input() public value: VideoItemValue = BLANK_VIDEO_ITEM;

  public url: SafeResourceUrl | null = null;

  public constructor(private sanitizer: DomSanitizer) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.value = changes.value.currentValue;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://player.vimeo.com/video/${this.value.video}?${this.parseOptions()}`);
  }

  private parseOptions(): void {
    const queryString: any = [];
    this.value.options = this.value.options ? this.value.options : BLANK_VIDEO_OPTION;
    for (const option in this.value.options) {
      if (Object.prototype.hasOwnProperty.call(this.value.options, option)) {
        queryString.push(encodeURIComponent(option) + '=' + encodeURIComponent(this.value.options[option]));
      }
    }
    return queryString.join('&');
  }
}
