import { Injectable } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AccountSettingsModalResolvedValues } from '@grid-ui/account-personal-details';
import { CountryChoice } from '@grid-ui/common';
import { SavingState, WhoAmIUserPersonalInformation, WhoAmIUserPersonalInformationChange } from '@grid-ui/whoami';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccountSettingsModalComponent } from '../account-settings-modal';

@Injectable({
  providedIn: 'root',
})
export class AccountSettingsModalService {
  constructor(private readonly ngBootstrapModalService: NgbModal) {}

  public showModal(
    userPersonalInformation: WhoAmIUserPersonalInformation,
    savingStatus: Observable<SavingState>,
    countryChoices: Observable<CountryChoice[]>,
    saveDispatcher: (change: WhoAmIUserPersonalInformationChange) => void,
  ): Promise<AccountSettingsModalResolvedValues | null> {
    const modalRef = this.ngBootstrapModalService.open(AccountSettingsModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    const accountSettingsModalComponent: AccountSettingsModalComponent = modalRef.componentInstance;

    accountSettingsModalComponent.currentAccountSettings = userPersonalInformation;
    accountSettingsModalComponent.savingStatus$ = savingStatus;
    accountSettingsModalComponent.countries$ = countryChoices;
    accountSettingsModalComponent.saveDispatcher = saveDispatcher;

    return modalRef.result;
  }
}
