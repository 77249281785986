import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from '@grid-ui/environment';
import {
  ApiLocalCacheService,
  CountryRiskIndicesService,
  CountryServiceV4,
  DataAlertService,
  PaginationService,
  PortalHttpClient,
  QueryParametersService,
  RetryAttemptsMapperService,
} from './api';
import { CustomSelectableHeaderModule } from './shared-components/custom-selectable-header/custom-selectable-header.module';
import {
  AllCountriesService,
  FrontendAssetsService,
  IndustryMetadataService,
  SavedSearchesService,
  SubscriptionService,
} from './shared-services';
import { DownloadResourceService } from './shared-utilities';

@NgModule({
  imports: [CommonModule, CustomSelectableHeaderModule],
  providers: [
    ApiLocalCacheService,
    CountryRiskIndicesService,
    IndustryMetadataService,
    SubscriptionService,
    QueryParametersService,
    PortalHttpClient,
    RetryAttemptsMapperService,
    PaginationService,
    DownloadResourceService,
    FrontendAssetsService,
    CountryServiceV4,
    SavedSearchesService,
    DataAlertService,
    AllCountriesService,
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: environment.csrfToken,
        headerName: 'X-CSRFToken',
      }),
    ),
  ],
})
export class GridCommonModule {}
