/**
 * An enum to distinguish the nested nodes in a
 * view trees search result:
 * - parent group node
 * - child group node
 * - match leaf node (i.e. matching search term)
 * - other leaf node (i.e. not matchin search term)
 */
export enum ViewTreesSearchNodeType {
  parent,
  child,
  other,
  match,
}
