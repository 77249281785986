<div class="grid-ui-no-data-available-container" [style.margin-top.px]="topMargin">
  <p
    class="grid-ui-no-data-available-message"
    [innerHTML]="message | safeHtml"
    mcRouterLinkClickListener
    data-hook="no-data-available-text"
  ></p>
  <button
    *ngIf="actionButtonLabel && actionButtonLabel!.length > 0"
    class="btn mc-btn-primary grid-ui-no-data-available-btn"
    (click)="performAction()"
  >
    {{ actionButtonLabel }}
  </button>
</div>
