<div
  class="additional-countries-offer-footer-container"
  [style.padding-left]="sidePadding"
  [style.padding-right]="sidePadding"
  [class.continuous]="continuous"
>
  <p class="small" data-hook="additional-countries-offer-footer-message">
    {{ alertMessage }}
    <a [routerLink]="subscriptionsRouterLink" data-hook="additional-countries-offer-footer-link">your subscription</a>
  </p>
</div>
