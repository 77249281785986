import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiCountryRiskProfileDetails } from '../../country-risk-profiles';
import { ApiBodyContentItem } from '../../models';
import { ApiRiskInsightDetail, ApiRiskInsightsCollection } from '../../risk-insights';
import { DeprecatedContentBlockTypeError } from '../models';

type URLMatchValue =
  | 'country-risk-insight'
  | 'country-risk-insight-detail'
  | 'country-risk-insight-detail-context'
  | 'country-risk-profile-detail'
  | 'country-risk-profiles-detail-context';

interface URLMatcher {
  matcher: RegExp;
  value: URLMatchValue;
}

/**
 * Monitored URL part matchers. IMPORTANT: ORDER IS CRITICAL MOST SPECIFIC MATCH FIRST.
 */
export const MONITORED_API_URL_PARTS: URLMatcher[] = [
  {
    matcher: /country-risk\/content\/profiles\/[0-9]{1,}\/context\/$/,
    value: 'country-risk-profiles-detail-context',
  },
  {
    matcher: /country-risk\/content\/profiles\/[0-9]{1,}\/$/,
    value: 'country-risk-profile-detail',
  },
  {
    matcher: /country-risk\/content\/insights\/[0-9]{1,}\/context\/$/,
    value: 'country-risk-insight-detail-context',
  },
  {
    matcher: /country-risk\/content\/insights\/[0-9]{1,}\/$/,
    value: 'country-risk-insight-detail',
  },
  {
    matcher: /(?!.*type=pdf)country-risk\/content\/insights\/(?!autocomplete)/,
    value: 'country-risk-insight',
  },
  // TODO: add written content of other modules, when it is added to the app, e.g. /v3/commodity-risk/content
];

export const DEPRECATED_CONTENT = ['carto', 'keyevents', 'stakeholderview'];

@Injectable()
export class HttpDeprecatedBlockInterceptor implements HttpInterceptor {
  constructor(private readonly errorLogger: ErrorHandler) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          const urlMatch = this.getURLMatch(ev.url);
          const url = ev.url;
          let profileDetail: ApiCountryRiskProfileDetails;
          let insight: ApiRiskInsightsCollection;
          let insightDetail: ApiRiskInsightDetail;

          switch (urlMatch) {
            case 'country-risk-profile-detail':
              profileDetail = ev.body;
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.parseBodyItemArray(profileDetail.section.body as ApiBodyContentItem[], url!);
              profileDetail.related_content
                .map((rc) => rc.excerpt)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .forEach((excerpt) => this.parseBodyItemArray(excerpt, url!));
              break;

            case 'country-risk-insight':
              insight = ev.body;
              insight.results
                .map((rc) => rc.excerpt)
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .forEach((excerpt) => this.parseBodyItemArray(excerpt, url!));
              break;

            case 'country-risk-insight-detail':
              insightDetail = ev.body;
              if (insightDetail.entitled) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.parseBodyItemArray(insightDetail.body, url!);
                insightDetail.related_content
                  .map((rc) => rc.excerpt)
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .forEach((excerpt) => this.parseBodyItemArray(excerpt, url!));
              }
              break;
          }
        }
      }),
    );
  }

  // HACK: public to allow test to spy on it
  public checkContentType(type: string, url: string): void {
    if (DEPRECATED_CONTENT.includes && DEPRECATED_CONTENT.includes(type)) {
      const error = new TypeError('Deprecated content type found');
      const value: DeprecatedContentBlockTypeError = new DeprecatedContentBlockTypeError(type, url, error);
      this.sendLog(value);
    }
  }

  private parseBodyItemArray(bodyItems: ApiBodyContentItem[], url: string): void {
    bodyItems.forEach((bodyItem) => this.checkContentType(bodyItem.type, url));
  }

  private getURLMatch(url: string | null): URLMatchValue | null {
    if (url === null) {
      return null;
    }

    let urlMatch: URLMatchValue | null = null;
    const len = MONITORED_API_URL_PARTS.length;
    let i = 0;
    while (urlMatch === null && i < len) {
      if (MONITORED_API_URL_PARTS[i].matcher.test(url)) {
        urlMatch = MONITORED_API_URL_PARTS[i].value;
      }
      i++;
    }
    return urlMatch;
  }

  private sendLog(value: DeprecatedContentBlockTypeError): void {
    this.errorLogger.handleError(value);
  }
}
