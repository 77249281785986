import { FeatureLike } from 'ol/Feature';

/**
 * Handy method to find out if an OpenLayers feature is a cluster.
 *
 * Used for dynamic styling on sites.
 *
 * @param feature The OpenLayers feature.
 * @returns true if the feature is a cluster.
 */
export function isCluster(feature: FeatureLike): boolean {
  return feature.get('features')?.length > 1;
}
