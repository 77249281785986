import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagKey } from '../../feature-flag-key.model';
import { FeatureFlagService } from '../services/feature-flag.service';

export const featureFlagGuard: CanActivateFn = (route) => {
  const router = inject(Router);

  const flagKey = route.data?.featureFlagKey as FeatureFlagKey;

  if (!flagKey) {
    return true;
  }

  const canActivate = inject(FeatureFlagService).getFlagValue(flagKey);

  if (canActivate) {
    return true;
  }

  router.navigate(['/']);

  return false;
};
