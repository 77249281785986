import { ItemInformationListingItemDatatype } from '../item-information-listing';
import { SaveActionType } from '../save-action-type.enum';
import { RiskViewSelectionDropdownActionsUnion, RiskViewSelectionDropdownActionTypes } from '../risk-view-selection-dropdown';

/** Action types for actions directly supported by the Analyse Navigation Toolbar */
export enum AnalyseToolbarDirectActionTypes {
  CHANGE_CURRENT_EDITION = '[Analyse Navigation Toolbar] Change the current edition ("end" edition)',
  CONVERT_VIEW_TO_DRAFT = '[Analyse Navigation Toolbar] Convert the current view to a draft view',
  CREATE_NEW_VIEW = '[Analyse Navigation Toolbar] Create New Risk View',
  EXTRACT = '[Analyse Navigation Toolbar] Use Data Wizard - Extract for Active View',
  SAVE_VIEW = '[Analyse Navigation Toolbar] "Save" or "Save As" the current view',
  UPLOAD = '[Analyse Navigation Toolbar] Use Data Wizard - Upload',
  OPEN_GET_STARTED_MODAL = '[Analyse Navigation Toolbar] Open Get Started Modal',
}

/** Action types for all actions supported by the Analyse Navigation Toolbar */
export type AnalyseToolbarActionTypes = AnalyseToolbarDirectActionTypes | RiskViewSelectionDropdownActionTypes;

/** Action emitted when the current edition needs to be changed, based on a new end value for the time slider. */
export class AnalyseToolbarChangeCurrentEdition {
  public readonly type = AnalyseToolbarDirectActionTypes.CHANGE_CURRENT_EDITION;
  constructor(public payload: { edition: string }) {}
}

/** Action emitted when the current view should be converted to a draft view for editing or saving as a separate view. */
export class AnalyseToolbarConvertToDraft {
  public readonly type = AnalyseToolbarDirectActionTypes.CONVERT_VIEW_TO_DRAFT;
  constructor() {}
}

/** Action emitted when a new view should be created */
export class AnalyseToolbarCreateNew {
  public readonly type = AnalyseToolbarDirectActionTypes.CREATE_NEW_VIEW;
  constructor() {}
}

/** Action emitted to trigger a navigation to Data Wizard/Extract */
export class AnalyseToolbarExtract {
  public readonly type = AnalyseToolbarDirectActionTypes.EXTRACT;
  constructor() {}
}

/** Action emitted when the current draft view shuld be saved to the original view it was created from or saved as a separate new view. **/
export class AnalyseToolbarSaveView {
  public readonly type = AnalyseToolbarDirectActionTypes.SAVE_VIEW;
  constructor(public payload: SaveActionType) {}
}

/** Action emitted to trigger a navigation to Data Wizard/Upload */
export class AnalyseToolbarUpload {
  public readonly type = AnalyseToolbarDirectActionTypes.UPLOAD;
  constructor() {}
}

/** Action emitted to open the Get Started modal */
export class AnalyseToolbarOpenGetStartedModal {
  public readonly type = AnalyseToolbarDirectActionTypes.OPEN_GET_STARTED_MODAL;
  constructor() {}
}

/** Union type of actions directly supported by the Analyse Toolbar */
export type AnalyseToolbarDirectActionsUnion =
  | AnalyseToolbarChangeCurrentEdition
  | AnalyseToolbarCreateNew
  | AnalyseToolbarConvertToDraft
  | AnalyseToolbarExtract
  | AnalyseToolbarSaveView
  | AnalyseToolbarUpload
  | AnalyseToolbarOpenGetStartedModal;

/** Union type of all actions supported by the Analyse Toolbar */
export type AnalyseToolbarActionsUnion<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> =
  | AnalyseToolbarDirectActionsUnion
  | RiskViewSelectionDropdownActionsUnion<T>;
