import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ActionResponseState } from './models';

@Component({
  selector: 'grid-ui-action-success-modal',
  templateUrl: './action-success-modal.component.html',
  styleUrls: ['./action-success-modal.component.scss'],
})
export class ActionSuccessModalComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public subMessage?: string;
  @Input() public warning?: boolean;
  @Input() public messageDetail: string | null = null;
  @Input() public buttonLabel: string | null = null;
  @Input() public responseState: ActionResponseState | null = null;
  @Input() public allowHeaderDismiss = false;
  @Input() public dismissReason: any = null;
  @Input() public closeReason: any = null;
  @Input() public allowSecondaryButton = false;
  @Input() public secondaryButtonLabel?: string;
  @Input() public secondaryButtonReason?: any;
  @Input() public trigger$?: Subject<any>;

  /** Used in template for state comparison */
  public ActionResponseState = ActionResponseState;

  public get hasFailedOrSucceeded(): boolean {
    return this.hasFailed || this.responseState === ActionResponseState.Success;
  }

  public get hasFailed(): boolean {
    return this.responseState === ActionResponseState.Failure;
  }

  public get isProcessing(): boolean {
    return this.responseState === ActionResponseState.Processing;
  }

  public constructor(private readonly activeModal: NgbActiveModal) {}

  public close(): void {
    if (this.trigger$) {
      this.trigger$.next(this.closeReason);
    } else {
      this.activeModal.close(this.closeReason);
    }
  }

  public dismiss(): void {
    this.activeModal.dismiss(this.dismissReason);
  }

  public closeSecondary(): void {
    this.activeModal.close(this.secondaryButtonReason);
  }
}
