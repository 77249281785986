import { LocationAttributeChoice } from '../../shared-models';

/**
 * Parses an array of region-related choices and then maps certain
 * `choices` values to shorten their label for display purposes.
 *
 * @param choices An array of location attribute choices
 */
export function mapRegionChoices(choices: LocationAttributeChoice[]): LocationAttributeChoice[] {
  let tempChoices: LocationAttributeChoice[] = choices;
  tempChoices = tempChoices.map((x) => {
    if (x.value.trim().toUpperCase() === 'MENA') {
      return { ...x, label: 'MENA' };
    }
    return x;
  });
  return tempChoices;
}
