import { Injectable } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TOOLTIP_CONFIG } from './tooltip-config';

/*
 * Inject this service into the constructor of the component where you are using a tooltip to apply the default tooltip settings. This needs to be done individually for each component in the library, but can be added just to app.component to apply it to the components defined in each angular app.
 */
@Injectable({
  providedIn: 'root',
})
export class NgbTooltipConfigService {
  public constructor(private tooltipConfig: NgbTooltipConfig) {
    tooltipConfig.container = TOOLTIP_CONFIG.container;
  }
}
