import { Injectable } from '@angular/core';
import { PollingResourceConfig } from '@grid-ui/common';
import { select, Store } from '@ngrx/store';
import { PollConfig, PollStatusEnum } from '../../../shared-models';
import * as fromPollingActions from '../store/actions/polling.actions';
import * as fromPolling from '../store/reducers';

import { PollItem } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  constructor(private readonly store: Store<fromPolling.State>) {}

  public start(apiConfig: PollingResourceConfig, requestBody: any): PollItem {
    const id = Date.now();
    const poll: PollConfig = {
      apiConfig,
      id,
      pollCount: 0,
      requestBody,
      status: PollStatusEnum.NotStarted,
    };

    this.store.dispatch(new fromPollingActions.StartPolling({ poll }));
    const poll$ = this.store.pipe(select(fromPolling.selectPollById(id)));
    return { id, poll$ };
  }

  public cancel(id: number): void {
    this.store.dispatch(new fromPollingActions.UserCancel({ id }));
  }

  public continueOngoingPoll(apiConfig: PollingResourceConfig, pollServerId: number): PollItem {
    const id = Date.now();
    const poll: PollConfig = {
      apiConfig,
      id,
      serverPollingSessionId: pollServerId,
      pollCount: 0,
      requestBody: null,
      status: PollStatusEnum.Active,
    };

    this.store.dispatch(new fromPollingActions.ContinueOngoingPoll({ poll }));
    const poll$ = this.store.pipe(select(fromPolling.selectPollById(id)));
    return { id, poll$ };
  }
}
