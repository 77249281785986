import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CookiesNotificationBannerComponent } from './cookies-notification-banner';

@NgModule({
  imports: [CommonModule],
  declarations: [CookiesNotificationBannerComponent],
  exports: [CookiesNotificationBannerComponent],
})
export class PrivacySettingsModule {}
