import { ColumnMenuTab, GridOptions, SideBarDef } from '@ag-grid-community/core';
import { TEXT_CUSTOM_ICONS } from '../util';

export const STANDARD_GRID_OPTIONS: Readonly<GridOptions> = Object.freeze({
  suppressMenuHide: true,
  suppressContextMenu: true,
  defaultColDef: {
    suppressHeaderMenuButton: false,
    menuTabs: ['filterMenuTab'] as ColumnMenuTab[],
    resizable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['clear', 'apply'],
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  icons: {
    sortAscending: TEXT_CUSTOM_ICONS.sortAscending,
    sortDescending: TEXT_CUSTOM_ICONS.sortDescending,
    swapIcon: TEXT_CUSTOM_ICONS.swapIcon,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    position: 'right',
  } as SideBarDef,
});
