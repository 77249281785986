import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SimpleNavItem } from '../../shared-models';
import { BoxedAppearance } from './models/boxed-appearance.enum';

@Component({
  selector: 'grid-ui-simple-tab-nav-bar',
  templateUrl: './simple-tab-nav-bar.component.html',
  styleUrls: ['./simple-tab-nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleTabNavBarComponent {
  @Input() public navItems: SimpleNavItem[] = [];
  /** The index of the selected tab */
  @Input() public selectedIndex = 0;
  /** Flag indicating whether the tab label text should be uppercased */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() public uppercase: boolean = true;
  @Input() public extendClassName = '';
  /**
   * Flag indicating whether the tabs should have a "boxed" appearance.
   * By default or when set to _BoxedAppearance.NONE_, the tabs will have an "underlined" appearance.
   *
   * When a boxed appearance is intended, please choose one of the following values, depending
   * on whether the navbar is shown in an encased container div with it own border and how it
   * is aligned:
   *
   * - _BoxedAppearance.ENCASED_LEFT_: Prevent the first tab from having a left border, as it would give
   * the appearance of a double border on the left side for a left-aligned navbar in a container div with border,
   * - _BoxedAppearance.ENCASED_RIGHT_: Prevent the last tab from having a right border, as it would give
   * the appearance of a double border on the right side for a right-aligned navbar in a container div with border,
   * - _BoxedAppearance.ENCASED_BOTH_: Prevent the first tab from having a left border and last tab from having a right border,
   * as it would give the appearance of a double border on either side for a completely encased navbar in a container div with border.
   * - _BoxedAppearance.NON_ENCASED_: All tabs have normal borders, as the navbar is not encased in a bordered container div.
   */
  @Input() public boxedAppearance: BoxedAppearance = BoxedAppearance.NONE;
  /** Flag indicating, if the tabs in a boxed appearance navbar should have a top border */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() public boxedAppearanceTopBorder: boolean = false;

  /*
   * Set the background to transparent or white
   */
  @Input() public transparentBackground = false;

  /** Emitted when tab clicked. Sends the index of the navItems element clicked */
  @Output() public tabClicked = new EventEmitter<number>();

  public showTitleOnHover = false;

  public readonly BoxedAppearance = BoxedAppearance;

  public selectTab(index: number): void {
    this.tabClicked.emit(index);
  }

  public trackByIndex(index: number, item: SimpleNavItem): number {
    return index;
  }
}
