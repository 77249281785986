import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ADDITIONAL_COUNTRIES_OFFER_ALERT_MESSAGE_COUNTRIES } from '../constants';

@Component({
  selector: 'grid-ui-additional-countries-offer-footer',
  templateUrl: './additional-countries-offer-footer.component.html',
  styleUrls: ['./additional-countries-offer-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalCountriesOfferFooterComponent {
  @Input() public alertMessage: string = ADDITIONAL_COUNTRIES_OFFER_ALERT_MESSAGE_COUNTRIES;
  @Input() public subscriptionsRouterLink: any[] = ['/subscriptions', 'details', 'country-risk', 'countries'];
  /** CSS string with value for padding left and right of footer */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() public sidePadding: string = '30px';
  /** Whether to show top separating line and margin or not */
  @Input() public continuous = true;
}
