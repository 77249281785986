import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { WhoAmIService } from '../services';
import {
  getWhoAmI,
  getWhoAmIFailed,
  getWhoAmISuccess,
  whoAmILoadUser,
  whoAmILoadUserFailed,
  whoAmILoadUserSucceeded,
  whoAmISaveUser,
  whoAmISaveUserFailed,
  whoAmISaveUserSucceeded,
} from './who-am-i.actions';

@Injectable()
export class WhoAmIEffects {
  private readonly actions$ = inject(Actions);
  private readonly whoAmIService = inject(WhoAmIService);

  public readonly getWhoAmI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWhoAmI),
      switchMap(() =>
        this.whoAmIService.getWhoAmI().pipe(
          map((whoAmI) => getWhoAmISuccess({ whoAmI })),
          catchError((error) => of(getWhoAmIFailed({ error }))),
        ),
      ),
    ),
  );

  public readonly getWhoAmISuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWhoAmISuccess),
      switchMap(() => of(whoAmILoadUser())),
    ),
  );

  public readonly loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(whoAmILoadUser),
      switchMap(() =>
        this.whoAmIService.getUserPersonalInformation().pipe(
          map((user) => whoAmILoadUserSucceeded({ payload: user })),
          catchError((error) => of(whoAmILoadUserFailed({ payload: error }))),
        ),
      ),
    ),
  );

  public readonly saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(whoAmISaveUser),
      mergeMap((action) =>
        this.whoAmIService.changeUserPersonalInformation(action.payload).pipe(
          map((user) => whoAmISaveUserSucceeded({ payload: user })),
          catchError((error) => of(whoAmISaveUserFailed({ payload: error }))),
        ),
      ),
    ),
  );
}
