import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorModule } from '../error';
import { IconModule } from '../icon';
import { SubscriptionDetailsNorowsOverlayComponent } from './';

@NgModule({
  declarations: [SubscriptionDetailsNorowsOverlayComponent],
  imports: [CommonModule, IconModule, ErrorModule],
  exports: [SubscriptionDetailsNorowsOverlayComponent],
})
export class SubscriptionDetailsNorowsOverlayModule {}
