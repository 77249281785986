import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { ApiFaqQuestionsResponse } from '../models/api-faq-questions-response.model';
import { FaqQuestionsResponse } from '../models/faq-questions-response.model';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly httpClient: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.faq._configuration;
  }

  public loadFaqQuestions(module: string): Observable<FaqQuestionsResponse> {
    return this.httpClient.get<ApiFaqQuestionsResponse>(this.resourceConfig, { pathParams: { module } });
  }
}
