import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ErrorComponentData } from '../../shared-models';
import { ActionSuccessModalComponent } from '../action-success-modal';
import { DownloadScorePanelAsImageService } from './service';

@Component({
  selector: 'grid-ui-score-panel-image-download-button',
  templateUrl: './score-panel-image-download-button.component.html',
  styleUrls: ['./score-panel-image-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScorePanelImageDownloadButtonComponent implements OnDestroy {
  // Requires the native/html element and title as input
  @Input() public addScoreLegendToImage = true;
  @Input() public headerRef?: ElementRef<HTMLElement>;
  // pass in selector for elements that will be hidden from print
  @Input() public hideElementSelector?: string;
  @Input() public linkedElement: HTMLElement | undefined | null;
  // subtitle is optional
  @Input() public subtitle?: string;
  @Input() public title: string | null = null;
  @Input() public useDownloadIcon = false;

  @Output() public downloadButtonClick = new EventEmitter<void>();

  // Link to the offscreen node that will be modified rather than modify the
  // original
  @ViewChild('offscreenclone', { read: ElementRef }) public cloneRef: ElementRef<HTMLDivElement> | null = null;

  @ViewChild('legend', { read: ElementRef }) public legendRef: ElementRef<HTMLDivElement> | null = null;

  // Default style for new image
  public optionalStyle: any = {
    width: '900px',
    padding: '40px',
  };

  private convertHtmlToImageSubscription: Subscription | null = null;
  private error: ErrorComponentData | null = null;
  private isDownloadProcessing = false;

  public constructor(
    private readonly downloadScorePanelAsImageService: DownloadScorePanelAsImageService,
    private readonly ngbModal: NgbModal,
    private readonly renderer: Renderer2,
  ) {}

  public ngOnDestroy(): void {
    if (this.convertHtmlToImageSubscription) {
      this.convertHtmlToImageSubscription.unsubscribe();
    }
  }

  public downloadImage(event: Event): void {
    event.stopPropagation();

    // check if a download is already being processed
    if (this.isDownloadProcessing || !this.linkedElement || !this.cloneRef || !this.legendRef || !this.title) {
      return;
    }
    this.isDownloadProcessing = true;

    this.convertHtmlToImageSubscription = this.downloadScorePanelAsImageService
      .convertHtmlToImageAndDownload(this.renderer, {
        cloneRef: this.cloneRef,
        headerRef: this.headerRef,
        linkedElement: this.linkedElement,
        legendRef: this.legendRef,
        hideElementSelector: this.hideElementSelector,
        title: this.title,
        subtitle: this.subtitle,
        addScoreLegendToImage: this.addScoreLegendToImage,
        optionalStyle: this.optionalStyle,
      })
      .subscribe(
        (): boolean => (this.isDownloadProcessing = false),
        (errorMessage: string) => {
          this.isDownloadProcessing = false;
          this.raiseError(errorMessage);
        },
      );

    this.downloadButtonClick.emit();
  }

  private raiseError(err: string): void {
    const modalRef = this.ngbModal.open(ActionSuccessModalComponent, { centered: true, size: 'sm', backdrop: 'static' });
    const modal: ActionSuccessModalComponent = modalRef.componentInstance;
    modal.title = 'There was an error saving the image.';
    modal.message = err + ' Please try again.';
    modal.buttonLabel = 'Close';
    modal.messageDetail = 'If the error keeps reoccuring, ' + 'please contact support@maplecroft.com';
  }
}
