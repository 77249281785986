import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../../shared-pipes';
import { RoutingTabNavBarComponent } from './routing-tab-nav-bar.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedPipesModule],
  declarations: [RoutingTabNavBarComponent],
  exports: [RoutingTabNavBarComponent],
})
export class RoutingTabNavBarModule {}
