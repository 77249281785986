import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/browser';
import { extractError } from './utils';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    // Unwrap HttpErrors that are within promise rejections
    if (!(error instanceof HttpErrorResponse) && error.rejection && error.rejection instanceof HttpErrorResponse) {
      error = error.rejection;
    }

    // swallow 403 errors as they will result in a redirect to login page and are an "expected" scenario
    // 404 errors are again "expected" so we're blocking those from being logged
    if (error.status === 403 || error.status === 404) {
      return;
    }

    captureException(extractError(error));

    console.error(error);
  }
}
