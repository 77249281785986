import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PollingResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDataWizardExtract, ApiDataWizardExtractCollection, DataWizardExtract } from '../models';

@Injectable()
export class DataWizardExtractService {
  private extractResourcesConfig: PollingResourceConfig;
  private extractDetailResourcesConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.extractResourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.extract._configuration;
    this.extractDetailResourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.extract.detail._configuration;
  }

  public cancelExtract(id: number): Observable<DataWizardExtract> {
    return this.http.delete<ApiDataWizardExtract>(this.extractDetailResourcesConfig, {
      pathParams: { id },
    });
  }

  /**
   * Returns the in_progress DataWizardExtract returned byt the backend or
   * null if there was none.
   * It's assumed that only one extract can be in_progress at any time.
   */
  public getInProgressExtract(): Observable<DataWizardExtract | null> {
    return this.http
      .get<ApiDataWizardExtractCollection>(this.extractResourcesConfig, { queryParams: { status: 'in_progress' } })
      .pipe(map((collection: ApiDataWizardExtractCollection) => (collection.results.length > 0 ? collection.results[0] : null)));
  }
}
