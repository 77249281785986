import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FixedUpPipe } from './fixed-up.pipe';
import { ImageUrlPipe } from './image-url.pipe';

const components = [ImageUrlPipe, FixedUpPipe];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
  providers: [DecimalPipe],
})
export class GridSharedPipesModule {}
