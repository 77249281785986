import { ContentTableReportDropdownConfig } from './content-table-report-dropdown-config.interface';

export interface ContentStreamReportDropdownConfig extends ContentTableReportDropdownConfig {
  /** order CSS property value that corresponds to the flex container row below the selected ContentBlock */
  flexOrder: number;
  /** Number of pixels from the left of the parent component to position the dropdown arrow pointing at the
   * selected ContentBlock */
  arrowLeftPosition: number;
  /** Width of the ContentStream container used to style properly this ReportDropdown */
  width: number;
  /** Indicates if the dropdown is in the first flex container row */
  isFirstRow: boolean;
}

export function isContentStreamReportDropdownConfig(
  config: ContentTableReportDropdownConfig | ContentStreamReportDropdownConfig,
): config is ContentStreamReportDropdownConfig {
  return Object.prototype.hasOwnProperty.call(config, 'flexOrder');
}
