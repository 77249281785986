<div class="mc-table-item">
  <h4 class="mc-table-title">Verisk Maplecroft's Language of Probability</h4>

  <table class="table">
    <thead>
      <tr class="mc-header-row">
        <th class="mc-col-header-cell">Language</th>
        <th class="mc-col-header-cell">Probability</th>
      </tr>
    </thead>
    <tbody>
      <tr class="mc-data-row">
        <td>Expected</td>
        <td>75% to &lt;100%</td>
      </tr>
      <tr class="mc-data-row">
        <td>Likely</td>
        <td>&gt;50% to &lt;75%</td>
      </tr>
      <tr class="mc-data-row">
        <td>Unlikely</td>
        <td>25% to &lt;50%</td>
      </tr>
      <tr class="mc-data-row">
        <td>Remote</td>
        <td>&gt;0% to &lt;25%</td>
      </tr>
    </tbody>
  </table>

  <span class="attribution"> Source: Verisk Maplecroft </span>
</div>
