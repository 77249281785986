import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../icon';
import { CustomSelectableHeaderComponent } from './custom-selectable-header.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconModule, AgGridModule],
  declarations: [CustomSelectableHeaderComponent],
  exports: [CustomSelectableHeaderComponent],
})
export class CustomSelectableHeaderModule {}
