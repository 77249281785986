import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { PrivacySettingsAcceptAction, PrivacySettingsActionsUnion } from '../models';

@Component({
  selector: 'mc-cookies-notification-banner',
  templateUrl: './cookies-notification-banner.component.html',
  styleUrls: ['./cookies-notification-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesNotificationBannerComponent {
  @Output() action = new EventEmitter<PrivacySettingsActionsUnion>();

  public acceptCookies(): void {
    this.action.emit(new PrivacySettingsAcceptAction());
  }
}
