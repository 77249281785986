import { ContentBlock } from '../content-choice';
import { RiskInsight } from '../risk-insight';

// TODO: This typeguard was written before the introduction of
// Commodity Risk related written content, it may have to be
// revisited

/** Type Guard to distinguish RiskInsight from CountryRiskProfile */
export function isRiskInsight(block: ContentBlock): block is RiskInsight {
  return Object.prototype.hasOwnProperty.call(block, 'authors') && Object.prototype.hasOwnProperty.call(block, 'title');
}
