import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { ItemInformationListingToggleIndexInformationDetailAction } from '../../../shared-models';
import { CellRendererInfoCustomParams } from '../models';

@Component({
  selector: 'grid-ui-cell-renderer-info',
  templateUrl: './cell-renderer-info.component.html',
  styleUrls: ['./cell-renderer-info.component.scss'],
})
export class CellRendererInfoComponent implements ICellRendererAngularComp {
  public active = false;
  public hover = false;
  public disabled: any;
  public cellValue: CellRendererInfoCustomParams | null = null;

  public refresh(params: any): boolean {
    throw new Error('Method not implemented.');
  }

  public agInit(params: CellRendererInfoCustomParams): void {
    this.cellValue = params;
  }

  public clickInfoIcon(): void {
    if (!this.cellValue) {
      return;
    }

    const payload = {
      item: this.cellValue as any,
      index: 1,
    };

    this.cellValue.handleAction(new ItemInformationListingToggleIndexInformationDetailAction({ itemPayload: { ...payload } }));
  }
}
