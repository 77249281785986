import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '../../..';
import { SubscribeRequest } from './';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private resourceConfig: NonPaginatedResourceConfig;

  public constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.subscription._configuration;
  }

  public sendSubscriptionRequest(payload: SubscribeRequest, customRetryAttempts?: number): Observable<null> {
    return this.http
      .post<void>(this.resourceConfig, {
        body: payload,
        retryOptions: { customRetryAttempts },
        httpOptions: {},
      })
      .pipe(map(() => null));
  }
}
