import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PaginatedResourceConfig,
  PaginationService,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiDocumentsCollectionPage, DocumentLibraryCategory, DocumentLibraryListQueryParams, DocumentsCollectionPage } from '../models';

@Injectable()
export class DocumentLibraryService {
  private getAllDocumentsResourceConfig: PaginatedResourceConfig;
  private getDocumentResourceConfig: NonPaginatedResourceConfig;
  private getIndexCatalogueConfig: NonPaginatedResourceConfig;
  private getAllExtractsResourceConfig: PaginatedResourceConfig;
  private getExtractResourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly http: PortalHttpClient,
    private readonly paginationService: PaginationService,
  ) {
    this.getAllDocumentsResourceConfig = API_SERVICES_CONFIG.v3.documentLibrary.documentList._configuration;
    this.getDocumentResourceConfig = API_SERVICES_CONFIG.v3.documentLibrary.document._configuration;
    this.getIndexCatalogueConfig = API_SERVICES_CONFIG.v3.documentLibrary.indexCatalogue._configuration;
    this.getAllExtractsResourceConfig = API_SERVICES_CONFIG.v3.documentLibrary.extractList._configuration;
    this.getExtractResourceConfig = API_SERVICES_CONFIG.v3.documentLibrary.extract._configuration;
  }

  /**
   * Fetch documents of a particular category from the API.
   *
   * @param category A document category for which to retrieve documents.
   * @param listQueryParams
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getDocumentsCollection(
    category: DocumentLibraryCategory,
    listQueryParams: DocumentLibraryListQueryParams,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<DocumentsCollectionPage> {
    const apiConfig = this.getGetAllConfig(category);
    const queryParams = Object.assign(
      {},
      listQueryParams,
      this.paginationService.getPageQueryParameter(listQueryParams, apiConfig.maxPageSize, apiConfig.defaultPageSize),
    );
    return this.http
      .get<ApiDocumentsCollectionPage>(apiConfig, {
        ...options,
        queryParams,
      })
      .pipe(map((apiPage) => this.mapDocumentsCollectionPage(apiPage, listQueryParams)));
  }

  /**
   * Fetch current page and all previous ones of documents of a particular
   * category from the API, with the specified filters applied.
   *
   * @param category A document category for which to retrieve documents.
   * @param listQueryParams
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getAllPreviousPagesDocumentsCollection(
    category: DocumentLibraryCategory,
    listQueryParams: DocumentLibraryListQueryParams,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<DocumentsCollectionPage> {
    const apiConfig = this.getGetAllConfig(category);
    const firstPageQueryParams = Object.assign(
      {},
      listQueryParams,
      this.paginationService.getPageQueryParameter(listQueryParams, apiConfig.maxPageSize, apiConfig.defaultPageSize),
      { page: 1 },
    );

    const firstPage$ = this.getDocumentsCollection(category, firstPageQueryParams, options);
    if (!listQueryParams.page || listQueryParams.page === 1) {
      return firstPage$;
    } else {
      const seedPage: DocumentsCollectionPage = {
        results: [],
        paginationContext: {
          prev: null,
          self: firstPageQueryParams,
          next: firstPageQueryParams,
        },
        total: 0,
      };
      return this.combinePages(category, firstPage$, seedPage, listQueryParams.page, options);
    }
  }

  public getDocumentsURLPathSegment(category: DocumentLibraryCategory): string {
    return this.getGetOneConfig(category).path;
  }

  public getIndexCatalogueDownloadURL(): string {
    return this.getIndexCatalogueConfig.path;
  }

  /**
   * Helper method to return all pages up to targetPage
   */
  private combinePages(
    category: DocumentLibraryCategory,
    page$: Observable<DocumentsCollectionPage>,
    accPage: DocumentsCollectionPage,
    targetPage: number,
    options: HttpGETCustomOptions,
  ): Observable<DocumentsCollectionPage> {
    let next = accPage.paginationContext.next;
    if (next !== null && next.page && next.page <= targetPage) {
      return page$.pipe(
        switchMap((page) => {
          accPage = {
            total: page.total,
            results: accPage.results.concat(page.results),
            paginationContext: page.paginationContext,
          };
          next = accPage.paginationContext.next;
          if (next !== null && next.page && next.page <= targetPage) {
            return this.combinePages(category, this.getDocumentsCollection(category, next, options), accPage, targetPage, options);
          } else {
            return of(accPage);
          }
        }),
      );
    } else {
      return page$;
    }
  }

  private getGetAllConfig(category: DocumentLibraryCategory): PaginatedResourceConfig {
    return category === 'downloads' ? this.getAllDocumentsResourceConfig : this.getAllExtractsResourceConfig;
  }

  private getGetOneConfig(category: DocumentLibraryCategory): NonPaginatedResourceConfig {
    return category === 'downloads' ? this.getDocumentResourceConfig : this.getExtractResourceConfig;
  }

  private mapDocumentsCollectionPage(
    apiPage: ApiDocumentsCollectionPage,
    queryParams: DocumentLibraryListQueryParams,
  ): DocumentsCollectionPage {
    return {
      total: apiPage.total,
      results: apiPage.results,
      paginationContext: this.paginationService.getNewPaginationContext(apiPage.links, queryParams),
    };
  }
}
