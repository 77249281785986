import { Injectable } from '@angular/core';

export class DatasetEntryFilter<T extends { [key: string]: any } = { [key: string]: any }> {
  public exact: any;

  private columnNames: string[];
  private _contains: string | null = null;

  public constructor(columnNames: string[]) {
    this.columnNames = columnNames;
  }

  public set contains(contains: string) {
    this._contains = contains ? contains.toLocaleLowerCase() : null;
  }

  public applyFilterTo(entry: T): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (!entry) {
      return false;
    }
    if (this._contains === null || (entry instanceof Array && entry.length === 0 && this.exact === null)) {
      return true;
    }

    return this.columnNames.some((columnName) => {
      const entryField = entry[columnName];
      if (self._contains && typeof entryField === 'string') {
        return entryField.toLocaleLowerCase().includes(self._contains);
      } else if (!self._contains) {
        if (entryField instanceof Date) {
          return (<Date>entryField).getTime() === (<Date>self.exact).getTime();
        } else {
          return entryField === self.exact;
        }
      }
      return false;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class DatasetFilterService {
  public filter<T extends { [key: string]: any }>(dataset: T[], entryFilters: DatasetEntryFilter[], key?: keyof T): T[] {
    return dataset.filter((entry): boolean => entryFilters.every((entryFilter) => entryFilter.applyFilterTo(key ? entry[key] : entry)));
  }
}
