import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { ErrorComponentSize } from '../../shared-models';

@Component({
  selector: 'grid-ui-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  @Input() public contactEmail: string | undefined = 'support@maplecroft.com';
  @Input() public errorCode: string | number | undefined | null;
  @Input() public errorMessage: string | undefined | null;
  @Input() public errorPrimaryAction: string | undefined | null;
  @Input() public errorSecondaryAction: string | undefined | null;
  @Input() public showContact: boolean | undefined = false;
  @Input() public size: ErrorComponentSize = 'full';

  @Output() public primaryActionClick = new EventEmitter();
  @Output() public secondaryActionClick = new EventEmitter();

  public triggerPrimaryAction($event: Event): void {
    $event.stopPropagation();
    this.primaryActionClick.emit();
  }
  public triggerSecondaryAction($event: Event): void {
    $event.stopPropagation();
    this.secondaryActionClick.emit();
  }
}
