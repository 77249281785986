import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { MajorRisksItemValue } from '../../../shared-models';

/**
 * The component to display the content block of type "majorrisks"
 * @export
 * @class BodyItemMajorrisksComponent
 * @implements OnInit
 */
@Component({
  selector: 'grid-ui-body-item-major-risks',
  templateUrl: './body-item-major-risks.component.html',
  styleUrls: ['./body-item-major-risks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemMajorRisksComponent {
  @Input() public value: MajorRisksItemValue[] = [
    {
      title: '',
      body: '',
    },
  ];

  public trackByIndex(item: MajorRisksItemValue, index: number): number {
    return index;
  }
}
