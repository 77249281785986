<!-- Use img tag with data URI src by default -->
<img
  *ngIf="!useInlineSVG"
  (mouseenter)="updateImage()"
  (mouseleave)="updateImage()"
  class="icon-container"
  [ngClass]="{
    'icon-container--no-color': noColor,
    'icon-container--filter-active': name === 'filter-active',
    'chevron--right': name === 'chevron-right',
    'chevron--down': name === 'chevron-down',
    'chevron--left': name === 'chevron-left',
    'chevron-circle--up': name === 'chevron-circle-up',
    'chevron-circle--down': name === 'chevron-circle-down',
    'chevron-circle--left': name === 'chevron-circle-left',
    'circle-border': name === 'exclamation-circle-border',
  }"
  height="100%"
  width="100%"
  [src]="src"
/>

<!-- Or Use inline SVGs so we can apply classes and change the colors dynamically using color CSS variables -->
<svg
  *ngIf="name === 'download'"
  #icon
  id="download-icon-midnight"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 11.75 16"
>
  <g id="Group_13243" data-name="Group 13243" transform="translate(0 0)">
    <path
      class="color-icon-fill-primary"
      id="Path_5537"
      data-name="Path 5537"
      d="M17.8,10.45A.976.976,0,1,0,16.3,9.2l-3.25,3.75V4.2H11.175v8.625l-3.25-3.75a.832.832,0,0,0-1.25,0,.891.891,0,0,0-.375.75.955.955,0,0,0,.25.625l5.625,6.5Z"
      transform="translate(-6.3 -4.2)"
    />
    <rect
      class="color-icon-fill-primary"
      id="Rectangle_1399"
      data-name="Rectangle 1399"
      width="11.75"
      height="1.875"
      transform="translate(0 14.125)"
    />
  </g>
</svg>
<svg *ngIf="name === 'edit'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <path
    class="color-icon-fill-primary"
    id="Path_8374"
    data-name="Path 8374"
    d="M138,66l-8,8-2,6,6-2,8-8Zm6,.85A2.838,2.838,0,0,0,139,65h0l4,4A2.921,2.921,0,0,0,144,66.85ZM135,79Z"
    transform="translate(-128 -64)"
  />
</svg>
<svg *ngIf="name === 'copy'" #icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
  <path
    class="color-icon-fill-primary"
    id="Path_837999"
    data-name="Path 837999"
    d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
  ></path>
</svg>
<svg *ngIf="name === 'video'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 12">
  <path
    id="Video"
    d="M15.35.5A56,56,0,0,0,8,0,56,56,0,0,0,.65.5,24.03,24.03,0,0,0,0,6a24.03,24.03,0,0,0,.65,5.5A55.4,55.4,0,0,0,8,12a56,56,0,0,0,7.35-.5A21.543,21.543,0,0,0,16,6,24.03,24.03,0,0,0,15.35.5ZM6,9V3l5,3Z"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'info-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="Group_21427" data-name="Group 21427" transform="translate(-9501 2574)">
    <g
      id="Rectangle_5029"
      data-name="Rectangle 5029"
      transform="translate(9501 -2574)"
      class="color-icon-fill-primary color-icon-stroke-primary"
      stroke-width="1"
    >
      <rect width="16" height="16" rx="8" stroke="none" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="none" />
    </g>
    <rect
      id="Rectangle_8448"
      data-name="Rectangle 8448"
      width="2"
      height="5"
      rx="1"
      transform="translate(9508 -2567)"
      class="color-icon-fill-secondary"
    />
    <rect
      id="Rectangle_8449"
      data-name="Rectangle 8449"
      width="2"
      height="2"
      rx="1"
      transform="translate(9508 -2570)"
      class="color-icon-fill-secondary"
    />
  </g>
</svg>
<svg *ngIf="name === 'exclamation-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="Group_21774" data-name="Group 21774" transform="translate(9517 -2558) rotate(180)">
    <g id="Path_8462" data-name="Path 8462" transform="translate(9501 -2574)" class="color-icon-fill-primary">
      <path
        d="M 8 15.5 C 3.864490032196045 15.5 0.5 12.13551044464111 0.5 8 C 0.5 3.864490032196045 3.864490032196045 0.5 8 0.5 C 12.13551044464111 0.5 15.5 3.864490032196045 15.5 8 C 15.5 12.13551044464111 12.13551044464111 15.5 8 15.5 Z"
        stroke="none"
      />
      <path
        d="M 8 1 C 4.140190124511719 1 1 4.140190124511719 1 8 C 1 11.85980987548828 4.140190124511719 15 8 15 C 11.85980987548828 15 15 11.85980987548828 15 8 C 15 4.140190124511719 11.85980987548828 1 8 1 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z"
        stroke="none"
        class="color-icon-fill-primary"
      />
    </g>
    <path
      id="Line_640"
      data-name="Line 640"
      d="M0,4A1,1,0,0,1-1,3V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V3A1,1,0,0,1,0,4Z"
      transform="translate(9509 -2566)"
      class="color-icon-fill-secondary"
    />
    <path id="Line_641" data-name="Line 641" transform="translate(9509 -2569)" class="color-icon-fill-secondary" />
    <circle
      id="Ellipse_523"
      data-name="Ellipse 523"
      cx="1.022"
      cy="1.022"
      r="1.022"
      transform="translate(9507.956 -2570.263)"
      class="color-icon-fill-secondary"
    />
  </g>
</svg>
<svg *ngIf="name === 'png'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 18.26">
  <g id="PNG" transform="translate(-772 -4680.948)">
    <path
      id="Path_8378"
      data-name="Path 8378"
      d="M2.623,5.8,12.175,5.8l1.323.553,3.517,3.817v12.44l-.783.658-13.61-.227-1.063-.992.395-15.7Z"
      transform="translate(770.442 4675.388)"
      class="color-icon-fill-secondary"
    />
    <path
      class="color-icon-fill-primary"
      id="Path_7325"
      data-name="Path 7325"
      d="M1908.662,531.2l-2.565-2.565a4.017,4.017,0,0,0-2.443-1.038h-8.55a1.42,1.42,0,0,0-1.4,1.4v15.45a1.42,1.42,0,0,0,1.4,1.4H1908.3a1.421,1.421,0,0,0,1.4-1.4v-10.87A4.006,4.006,0,0,0,1908.662,531.2Zm-3.542-1.893.183.183,2.565,2.565.183.183h-2.931Zm3.419,15.206a.288.288,0,0,1-.305.305H1895.1a.289.289,0,0,1-.305-.305v-15.45a.288.288,0,0,1,.305-.305h8.916v4.58h4.58v11.176Z"
      transform="translate(-1121.7 4153.348)"
    />
    <path
      class="color-icon-fill-primary"
      id="Path_7326"
      data-name="Path 7326"
      d="M969.327,138.3c-.718,0-1.435.717-2.152.717s-.717-2.87-2.152-2.87-2.87,1.435-3.587,4.3h8.609S970.044,138.3,969.327,138.3ZM960,134v7.892h11.479V134Zm10.761,7.174H960.718v-6.457h10.044Zm-2.87-4.663a1.076,1.076,0,1,0,1.076-1.076A1.057,1.057,0,0,0,967.892,136.511Z"
      transform="translate(-185.496 4554.918)"
    />
  </g>
</svg>
<svg *ngIf="name === 'minimize'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <path
    class="color-icon-fill-primary"
    id="Full_screen_min"
    data-name="Full screen min"
    d="M583,1801v6.5l-2.5-2.5-3,3-1.5-1.5,3-3-2.5-2.5Zm9-7.5-3,3,2.5,2.5H585v-6.5l2.5,2.5,3-3Z"
    transform="translate(-576 -1792)"
  />
</svg>
<svg *ngIf="name === 'expand'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 15.196">
  <path
    class="color-icon-fill-primary"
    id="Full_screen_max"
    data-name="Full screen max"
    d="M16,0V6.173L13.5,3.8l-3,2.849L9,5.224l3-2.849L9.5,0ZM7,9.973,4,12.822,6.5,15.2H0V9.023L2.5,11.4l3-2.849Z"
  />
</svg>
<svg *ngIf="name === 'print'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15.156 16.3">
  <g id="Group_21731" data-name="Group 21731" transform="translate(-274.801 -308.765)">
    <g id="Group_21730" data-name="Group 21730" transform="translate(274.951 308.915)">
      <path
        class="color-icon-fill-primary"
        id="Path_8458"
        data-name="Path 8458"
        d="M287.515,311.228h-.324V308.3a.382.382,0,0,0-.383-.383h-8.857a.382.382,0,0,0-.383.383h0v2.93h-.324a2.3,2.3,0,0,0-2.294,2.294v4.736a2.3,2.3,0,0,0,2.294,2.294h.324v2.981a.382.382,0,0,0,.383.383h8.856a.382.382,0,0,0,.383-.383h0v-2.981h.324a2.3,2.3,0,0,0,2.294-2.294v-4.736A2.3,2.3,0,0,0,287.515,311.228Zm-9.181-2.549h8.092v2.549h-8.092Zm8.092,14.471h-8.092v-4.714h8.092Zm2.617-4.893a1.531,1.531,0,0,1-1.529,1.529h-.324v-1.351h.827a.383.383,0,0,0,0-.764H276.855a.383.383,0,0,0-.039.764.249.249,0,0,0,.039,0h.713v1.351h-.324a1.531,1.531,0,0,1-1.529-1.529v-4.735a1.531,1.531,0,0,1,1.529-1.532h10.27a1.531,1.531,0,0,1,1.529,1.529v4.736h0Z"
        transform="translate(-274.951 -307.915)"
      />
      <path
        class="color-icon-fill-primary"
        id="Path_8458_-_Outline"
        data-name="Path 8458 - Outline"
        d="M277.951,307.765h8.857a.533.533,0,0,1,.533.533v2.78h.174a2.451,2.451,0,0,1,2.443,2.443v4.736a2.452,2.452,0,0,1-2.443,2.444h-.174v2.831a.533.533,0,0,1-.533.533h-8.856a.533.533,0,0,1-.533-.533V320.7h-.174a2.452,2.452,0,0,1-2.444-2.443v-4.736a2.452,2.452,0,0,1,2.443-2.444h.174V308.3A.533.533,0,0,1,277.951,307.765Zm9.563,12.636a2.151,2.151,0,0,0,2.144-2.144v-4.736a2.151,2.151,0,0,0-2.143-2.144h-.474V308.3a.233.233,0,0,0-.233-.233h-8.857a.233.233,0,0,0-.233.233v3.08h-.474a2.151,2.151,0,0,0-2.144,2.144v4.736a2.151,2.151,0,0,0,2.144,2.144h.474v3.131a.233.233,0,0,0,.233.233h8.856a.233.233,0,0,0,.233-.233V320.4Zm-9.33-11.872h8.392v2.849h-8.392Zm8.092.3h-7.792v2.249h7.792Zm-9.031,3.01h10.27a1.684,1.684,0,0,1,1.679,1.679v4.775a1.684,1.684,0,0,1-1.679,1.643h-.474v-1.651h.972a.233.233,0,0,0,0-.464H276.847a.233.233,0,0,0-.244.221.232.232,0,0,0,.221.244h.894v1.651h-.474a1.684,1.684,0,0,1-1.679-1.679v-4.735a1.681,1.681,0,0,1,1.679-1.682Zm11.65,6.38v-4.7a1.383,1.383,0,0,0-1.38-1.379h-10.27a1.381,1.381,0,0,0-1.379,1.382v4.735a1.383,1.383,0,0,0,1.38,1.379h.174v-1.051h-.558a.394.394,0,0,1-.056,0,.533.533,0,0,1,.054-1.064h11.166a.533.533,0,0,1,0,1.064h-.685v1.051h.174a1.383,1.383,0,0,0,1.38-1.38Zm-10.71.067h8.392V323.3h-8.392Zm8.092.3h-7.792V323h7.792Z"
        transform="translate(-274.951 -307.915)"
      />
      <path
        class="color-icon-fill-primary"
        id="Path_8459"
        data-name="Path 8459"
        d="M286.88,313.552a.522.522,0,1,0,.522.522h0A.522.522,0,0,0,286.88,313.552Z"
        transform="translate(-275.023 -307.95)"
      />
      <path
        class="color-icon-fill-primary"
        id="Path_8460"
        data-name="Path 8460"
        d="M279.25,319.827h6.3a.383.383,0,0,0,.039-.764h-6.347a.383.383,0,0,0-.039.764h.044Z"
        transform="translate(-274.975 -307.985)"
      />
      <path
        class="color-icon-fill-primary"
        id="Path_8461"
        data-name="Path 8461"
        d="M279.25,321.25h6.3a.383.383,0,0,0,.039-.764.249.249,0,0,0-.039,0h-6.308a.383.383,0,0,0-.039.764.249.249,0,0,0,.039,0Z"
        transform="translate(-274.975 -307.994)"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'sort-alphabetical-ascending'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  class="color-icon-background-primary"
  width="100%"
  height="100%"
  viewBox="0 0 13.643 15"
>
  <g id="Group_21470" data-name="Group 21470" transform="translate(0.387)">
    <text id="A" transform="translate(8.022 6)" class="color-icon-fill-primary" font-size="7" font-family="Roboto" font-weight="700">
      <tspan x="0" y="0">A</tspan>
    </text>
    <text id="Z" transform="translate(8.256 13)" class="color-icon-fill-primary" font-size="7" font-family="Roboto" font-weight="700">
      <tspan x="0" y="0">Z</tspan>
    </text>
    <g id="Group_21469" data-name="Group 21469" transform="translate(20595.377 12255)">
      <line
        id="Line_694"
        data-name="Line 694"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8387"
        data-name="Path 8387"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'sort-alphabetical-descending'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  class="color-icon-background-primary"
  width="100%"
  height="100%"
  viewBox="0 0 13.643 15"
>
  <g id="Group_21474" data-name="Group 21474" transform="translate(20595.764 12257)">
    <text
      id="A"
      transform="translate(-20587.355 -12244)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">A</tspan>
    </text>
    <text
      id="Z"
      transform="translate(-20587.121 -12251)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">Z</tspan>
    </text>
    <g id="Group_21473" data-name="Group 21473" transform="translate(0 -2)">
      <line
        id="Line_696"
        data-name="Line 696"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8389"
        data-name="Path 8389"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'sort-alphabetical-unsorted'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 13.643 15"
>
  <g id="Group_21470" data-name="Group 21470" transform="translate(0.387)">
    <text id="A" transform="translate(8.022 6)" class="color-icon-fill-primary" font-size="7" font-family="Roboto" font-weight="700">
      <tspan x="0" y="0">A</tspan>
    </text>
    <text id="Z" transform="translate(8.256 13)" class="color-icon-fill-primary" font-size="7" font-family="Roboto" font-weight="700">
      <tspan x="0" y="0">Z</tspan>
    </text>
    <g id="Group_21469" data-name="Group 21469" transform="translate(20595.377 12255)">
      <line
        id="Line_694"
        data-name="Line 694"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8387"
        data-name="Path 8387"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'sort-numerical-ascending'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  class="color-icon-background-primary"
  width="100%"
  height="100%"
  viewBox="0 0 13.756 15"
>
  <g id="Group_21472" data-name="Group 21472" transform="translate(20595.764 12257)">
    <text
      id="_1"
      data-name="1"
      transform="translate(-20587.008 -12251)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">1</tspan>
    </text>
    <text
      id="_9"
      data-name="9"
      transform="translate(-20587.008 -12244)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">9</tspan>
    </text>
    <g id="Group_21471" data-name="Group 21471" transform="translate(0 -2)">
      <line
        id="Line_695"
        data-name="Line 695"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8388"
        data-name="Path 8388"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'sort-numerical-descending'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  class="color-icon-background-primary"
  width="100%"
  height="100%"
  viewBox="0 0 13.756 15"
>
  <g id="Group_21476" data-name="Group 21476" transform="translate(20595.764 12257)">
    <text
      id="_1"
      data-name="1"
      transform="translate(-20587.008 -12244)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">1</tspan>
    </text>
    <text
      id="_9"
      data-name="9"
      transform="translate(-20587.008 -12251)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">9</tspan>
    </text>
    <g id="Group_21475" data-name="Group 21475" transform="translate(0 -2)">
      <line
        id="Line_697"
        data-name="Line 697"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8390"
        data-name="Path 8390"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'sort-numerical-unsorted'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13.756 15">
  <g id="Group_21472" data-name="Group 21472" transform="translate(20595.764 12257)">
    <text
      id="_1"
      data-name="1"
      transform="translate(-20587.008 -12251)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">1</tspan>
    </text>
    <text
      id="_9"
      data-name="9"
      transform="translate(-20587.008 -12244)"
      class="color-icon-fill-primary"
      font-size="7"
      font-family="Roboto"
      font-weight="700"
    >
      <tspan x="0" y="0">9</tspan>
    </text>
    <g id="Group_21471" data-name="Group 21471" transform="translate(0 -2)">
      <line
        id="Line_695"
        data-name="Line 695"
        y2="11"
        transform="translate(-20592.5 -12254.5)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
      <path
        id="Path_8388"
        data-name="Path 8388"
        d="M-20598.377-12234.15l2.873,3.484,2.873-3.484"
        transform="translate(3 -12)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'filter-inactive'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18.414 13.141">
  <path
    id="Path_8386"
    data-name="Path 8386"
    d="M318.793,4355.5h18.414l-6.707,6.707v5.184l-.379.094-4.621,1.156v-6.434Zm16,1H321.207l5.293,5.293v5.566l3-.75v-4.816Z"
    transform="translate(-318.793 -4355.5)"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'filter-active'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.998 16.002">
  <g id="Group_21477" data-name="Group 21477" transform="translate(-461.002 -2099)">
    <path
      id="Subtraction_18"
      data-name="Subtraction 18"
      d="M18110,23036v-6l-6-6h9.5a3.508,3.508,0,0,0,3.031,3.467L18114,23030v5l-3.994,1Z"
      transform="translate(-17643 -20921)"
      class="color-icon-fill-primary"
    />
    <g
      id="Ellipse_482"
      data-name="Ellipse 482"
      transform="translate(470 2099)"
      class="color-icon-fill-primary"
      class="color-icon-stroke-secondary"
      stroke-width="1"
    >
      <circle cx="4" cy="4" r="4" class="color-icon-stroke-primary" />
      <circle cx="4" cy="4" r="3.5" class="color-icon-fill-primary" />
    </g>
  </g>
</svg>
<svg
  class="chevron"
  *ngIf="name === 'chevron-up' || name === 'chevron-down' || name === 'chevron-right' || name === 'chevron-left'"
  [ngClass]="{
    'chevron--right': name === 'chevron-right',
    'chevron--down': name === 'chevron-down',
    'chevron--left': name === 'chevron-left',
  }"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 10 6.633"
>
  <path
    id="Path_8367"
    data-name="Path 8367"
    d="M139.922,10.312a1,1,0,0,1-.757-.346l-4-4.633a1,1,0,0,1,1.514-1.307l3.243,3.756,3.243-3.756a1,1,0,1,1,1.514,1.307l-4,4.633A1,1,0,0,1,139.922,10.312Z"
    transform="translate(144.922 10.312) rotate(180)"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'question-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="Group_21425" data-name="Group 21425" transform="translate(-9477 2574)">
    <g
      id="Rectangle_4968"
      data-name="Rectangle 4968"
      transform="translate(9477 -2574)"
      class="color-icon-fill-primary color-icon-stroke-primary"
      stroke-width="1"
    >
      <rect width="16" height="16" rx="8" stroke="none" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="none" />
    </g>
    <path
      id="Path_8255"
      data-name="Path 8255"
      d="M28.316,0a2.986,2.986,0,0,0-2.652,1.4.37.37,0,0,0,.08.507l.665.5a.37.37,0,0,0,.512-.064A1.5,1.5,0,0,1,28.2,1.587c.474,0,1.06.305,1.06.765,0,.348-.287.526-.755.788-.546.306-1.268.687-1.268,1.64v.151a.37.37,0,0,0,.37.37H28.72a.37.37,0,0,0,.37-.37V4.842c0-.66,1.93-.688,1.93-2.475A2.648,2.648,0,0,0,28.316,0Zm-.154,5.755a1.067,1.067,0,1,0,1.067,1.067A1.069,1.069,0,0,0,28.162,5.755Z"
      transform="translate(9456.691 -2569.944)"
      class="color-icon-fill-secondary"
    />
  </g>
</svg>
<svg *ngIf="name === 'xls'" #icon id="XLS" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.003 18.263">
  <path
    id="Path_6248"
    data-name="Path 6248"
    d="M2.6,5.8,11.974,5.8l1.3.543,3.45,3.745v12.2l-.768.645L2.6,22.712l-1.043-.974.387-15.4Z"
    transform="translate(-1.341 -4.987)"
    class="color-icon-fill-secondary"
  />
  <g class="color-icon-fill-primary" id="Group_448" data-name="Group 448" transform="translate(0 0)">
    <path
      id="Path_1739"
      data-name="Path 1739"
      d="M1738.125,531.2l-2.565-2.565a4.019,4.019,0,0,0-2.443-1.038H1724.5a1.421,1.421,0,0,0-1.4,1.4v15.453a1.421,1.421,0,0,0,1.4,1.4H1737.7a1.421,1.421,0,0,0,1.4-1.4V533.586A3.93,3.93,0,0,0,1738.125,531.2Zm-3.6-1.893.183.183,2.565,2.565.183.183h-2.932Zm3.42,15.209a.289.289,0,0,1-.305.305h-13.193a.288.288,0,0,1-.305-.305V529.066a.288.288,0,0,1,.305-.305h8.918v4.581h4.581v11.177Z"
      transform="translate(-1723.1 -527.6)"
    />
  </g>
  <g class="color-icon-fill-primary" id="Group_449" data-name="Group 449" transform="translate(4.703 7.879)">
    <path
      id="Path_1740"
      data-name="Path 1740"
      d="M1733.122,543.615l-2.138-3.115H1733l1.1,2.016,1.161-2.016h1.954l-2.138,3.115,2.321,3.42h-2.077l-1.222-2.2-1.344,2.2H1730.8Z"
      transform="translate(-1730.801 -540.5)"
    />
  </g>
</svg>
<svg
  *ngIf="name === 'xls-unstyled'"
  #icon
  id="XLS"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16.003 18.263"
>
  <path
    id="Path_6248"
    data-name="Path 6248"
    d="M2.6,5.8,11.974,5.8l1.3.543,3.45,3.745v12.2l-.768.645L2.6,22.712l-1.043-.974.387-15.4Z"
    transform="translate(-1.341 -4.987)"
    class="color-icon-fill-secondary"
  />
  <g class="color-icon-fill-unstyled" id="Group_448" data-name="Group 448" transform="translate(0 0)">
    <path
      id="Path_1739"
      data-name="Path 1739"
      d="M1738.125,531.2l-2.565-2.565a4.019,4.019,0,0,0-2.443-1.038H1724.5a1.421,1.421,0,0,0-1.4,1.4v15.453a1.421,1.421,0,0,0,1.4,1.4H1737.7a1.421,1.421,0,0,0,1.4-1.4V533.586A3.93,3.93,0,0,0,1738.125,531.2Zm-3.6-1.893.183.183,2.565,2.565.183.183h-2.932Zm3.42,15.209a.289.289,0,0,1-.305.305h-13.193a.288.288,0,0,1-.305-.305V529.066a.288.288,0,0,1,.305-.305h8.918v4.581h4.581v11.177Z"
      transform="translate(-1723.1 -527.6)"
    />
  </g>
  <g class="color-icon-fill-unstyled" id="Group_449" data-name="Group 449" transform="translate(4.703 7.879)">
    <path
      id="Path_1740"
      data-name="Path 1740"
      d="M1733.122,543.615l-2.138-3.115H1733l1.1,2.016,1.161-2.016h1.954l-2.138,3.115,2.321,3.42h-2.077l-1.222-2.2-1.344,2.2H1730.8Z"
      transform="translate(-1730.801 -540.5)"
    />
  </g>
</svg>
<svg *ngIf="name === 'csv'" #icon id="Csv" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 18.259">
  <path
    id="Path_8384"
    data-name="Path 8384"
    d="M2.6,5.8l9.37-.006,1.3.543,3.45,3.744v12.2l-.768.645L2.6,22.709l-1.043-.973.387-15.4Z"
    transform="translate(-1.341 -5.357)"
    class="color-icon-fill-secondary"
  />
  <g class="color-icon-fill-primary" id="Group_442" data-name="Group 442" transform="translate(0 0)">
    <path
      id="Path_1736"
      data-name="Path 1736"
      d="M1874.662,531.2l-2.565-2.565a4.015,4.015,0,0,0-2.443-1.038H1861.1a1.421,1.421,0,0,0-1.4,1.4v15.45a1.42,1.42,0,0,0,1.4,1.4h13.19a1.42,1.42,0,0,0,1.4-1.4v-10.87A4.007,4.007,0,0,0,1874.662,531.2Zm-3.542-1.893.183.183,2.565,2.565.183.183h-2.931Zm3.42,15.206a.289.289,0,0,1-.306.305H1861.1a.289.289,0,0,1-.305-.305v-15.45a.289.289,0,0,1,.305-.305h8.916v4.58h4.58v11.175Z"
      transform="translate(-1859.7 -527.6)"
    />
  </g>
  <g class="color-icon-fill-primary" id="Group_443" data-name="Group 443" transform="translate(3.237 8.794)">
    <rect id="Rectangle_197" data-name="Rectangle 197" width="2.504" height="1.343" />
    <rect id="Rectangle_198" data-name="Rectangle 198" width="2.809" height="1.343" transform="translate(3.114)" />
    <rect id="Rectangle_199" data-name="Rectangle 199" width="2.504" height="1.343" transform="translate(0 2.443)" />
    <rect id="Rectangle_200" data-name="Rectangle 200" width="2.809" height="1.343" transform="translate(3.114 2.443)" />
    <rect id="Rectangle_201" data-name="Rectangle 201" width="2.687" height="1.343" transform="translate(6.595 2.443)" />
    <rect id="Rectangle_202" data-name="Rectangle 202" width="2.504" height="1.343" transform="translate(0 4.885)" />
    <rect id="Rectangle_203" data-name="Rectangle 203" width="2.809" height="1.343" transform="translate(3.114 4.885)" />
    <rect id="Rectangle_204" data-name="Rectangle 204" width="2.687" height="1.343" transform="translate(6.595 4.885)" />
  </g>
</svg>
<svg *ngIf="name === 'bin-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="Group_21820" data-name="Group 21820" transform="translate(-751 -5459)">
    <circle id="Ellipse_527" data-name="Ellipse 527" cx="8" cy="8" r="8" transform="translate(751 5459)" class="color-icon-fill-primary" />
    <g id="delete-icon-midnight" transform="translate(756 5463.001)">
      <path
        id="Path_5445"
        data-name="Path 5445"
        d="M309.8,13.218a1.025,1.025,0,0,0,1,1h3.011a1.025,1.025,0,0,0,1-1V8.2H309.8Z"
        transform="translate(-309.226 -6.193)"
        class="color-icon-fill-secondary"
      />
      <path
        id="Path_5446"
        data-name="Path 5446"
        d="M315.094,5.9h-1.506l-.5-.5h-2.007l-.574.5H309v.932h6.094Z"
        transform="translate(-309 -5.4)"
        class="color-icon-fill-secondary"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'bin'"
  #icon
  id="delete-icon-midnight"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 12.143 16"
>
  <path
    id="Path_5445"
    data-name="Path 5445"
    d="M309.8,18.2a2.043,2.043,0,0,0,2,2h6a2.043,2.043,0,0,0,2-2V8.2h-10Z"
    transform="translate(-308.657 -4.2)"
    class="color-icon-fill-primary"
  />
  <path
    id="Path_5446"
    data-name="Path 5446"
    d="M321.143,6.4h-3l-1-1h-4L312,6.4h-3V8.257h12.143Z"
    transform="translate(-309 -5.4)"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'pdf'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 36.937">
  <g id="PDF" transform="translate(-490 -76.948)">
    <path
      class="color-icon-fill-secondary"
      id="Path_8385"
      data-name="Path 8385"
      d="M3.761,5.811,23.539,5.8l2.739,1.145,7.282,7.9V40.6l-1.62,1.361L3.761,41.494l-2.2-2.055.818-32.5Z"
      transform="translate(488.441 71.922)"
    />
    <path
      class="color-icon-fill-primary color-icon-stroke-primary"
      id="Path_5641"
      data-name="Path 5641"
      d="M18.782,11.811a.749.749,0,0,0-.264-.441,4.436,4.436,0,0,0-2.468-.617c-.881,0-1.851.088-3,.176a7.988,7.988,0,0,1-1.41-.97A9.449,9.449,0,0,1,9.174,6.17c0-.176.088-.264.088-.353a22.909,22.909,0,0,0,.441-4.5A.53.53,0,0,0,9.615.97L9.527.793A.959.959,0,0,0,8.557,0H8.293A1.181,1.181,0,0,0,7.147.705c-.353,1.322,0,3.349.705,5.906l-.176.441C7.147,8.2,6.618,9.431,6.089,10.489L6,10.577C5.472,11.635,4.943,12.6,4.5,13.4l-.441.264L3,14.191c-1.675.97-2.732,2.115-2.909,3a.711.711,0,0,0,.264.793l.441.264a1.634,1.634,0,0,0,.617.176c1.146,0,2.556-1.41,4.407-4.672a36.912,36.912,0,0,1,6.7-1.587A12.3,12.3,0,0,0,17.46,13.75a1.307,1.307,0,0,0,.617-.088.809.809,0,0,0,.529-.353A2.829,2.829,0,0,0,18.782,11.811Zm-12.516.881c.264-.441.529-.97.793-1.5a27.527,27.527,0,0,0,1.41-3.085,9.582,9.582,0,0,0,2.38,2.909c.088.088.264.176.353.264A25.047,25.047,0,0,0,6.266,12.693Z"
      transform="translate(496.459 87.771)"
      stroke-width="0.5"
    />
    <path
      class="color-icon-fill-primary color-icon-stroke-primary"
      id="Path_7330"
      data-name="Path 7330"
      d="M1923.623,534.806l-5.13-5.13a8.035,8.035,0,0,0-4.885-2.076h-17.1a2.841,2.841,0,0,0-2.809,2.809v30.9a2.841,2.841,0,0,0,2.809,2.809h26.382a2.841,2.841,0,0,0,2.809-2.809v-21.74A8.011,8.011,0,0,0,1923.623,534.806Zm-7.084-3.786.366.366,5.13,5.13.367.366h-5.863Zm6.839,30.412a.577.577,0,0,1-.611.611h-26.26a.577.577,0,0,1-.611-.611v-30.9a.577.577,0,0,1,.611-.611h17.832v9.16h9.161v22.351Z"
      transform="translate(-1403.7 -450.652)"
    />
  </g>
</svg>
<svg *ngIf="name === 'person-suit'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 18.485">
  <path
    id="Ask_an_analyst"
    data-name="Ask an analyst"
    d="M584,1603.7a4.012,4.012,0,1,0,4-3.7A3.869,3.869,0,0,0,584,1603.7Zm9.333,4.929H592.6l-4.133,7.764,1.533-7.147-2-1.848-2,1.848,1.533,7.147-4.133-7.764h-.733c-2.667,0-2.667,1.664-2.667,3.7v6.162h16v-6.162C596,1610.29,596,1608.626,593.333,1608.626Z"
    transform="translate(-580 -1600)"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'cross'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 10.828 10.828">
  <g id="Group_21454" data-name="Group 21454" transform="translate(16814.414 21446.914)">
    <line
      id="Line_692"
      data-name="Line 692"
      x2="8"
      y2="8"
      transform="translate(-16813 -21445.5)"
      fill="none"
      class="color-icon-stroke-secondary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_693"
      data-name="Line 693"
      x1="8"
      y2="8"
      transform="translate(-16813 -21445.5)"
      fill="none"
      class="color-icon-stroke-secondary"
      stroke-linecap="round"
      stroke-width="2"
    />
  </g>
</svg>
<svg
  class="chevron-circle"
  *ngIf="
    name === 'chevron-circle-up' || name === 'chevron-circle-right' || name === 'chevron-circle-down' || name === 'chevron-circle-left'
  "
  [ngClass]="{
    'chevron--right': name === 'chevron-circle-right',
    'chevron--down': name === 'chevron-circle-down',
    'chevron--left': name === 'chevron-circle-left',
  }"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 16"
>
  <g id="Group_21421" data-name="Group 21421" transform="translate(-9459 2574.081)">
    <g
      class="color-icon-fill-secondary color-icon-stroke-primary"
      id="Ellipse_461"
      data-name="Ellipse 461"
      transform="translate(9459 -2558.081) rotate(-90)"
      stroke-width="1"
    >
      <circle cx="8" cy="8" r="8" stroke="none" />
      <circle cx="8" cy="8" r="7.5" fill="none" />
    </g>
    <g id="Group_21252" data-name="Group 21252" transform="translate(9200.96 -2263.822) rotate(-90)">
      <path
        class="color-icon-stroke-secondary"
        id="Path_8262"
        data-name="Path 8262"
        d="M1348.022,1473.326l3.3,3.3-3.3,3.3"
        transform="translate(1778.842 -1083.17) rotate(90)"
        fill="none"
        stroke-linecap="round"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'grid-view'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="grid_view" data-name="grid view" transform="translate(-1280 -780)">
    <g class="color-icon-fill-primary" id="Group_18742" data-name="Group 18742">
      <rect id="Rectangle_6013" data-name="Rectangle 6013" width="7" height="7" transform="translate(1280 789)" />
      <rect id="Rectangle_6012" data-name="Rectangle 6012" width="7" height="7" transform="translate(1289 789)" />
      <rect id="Rectangle_6014" data-name="Rectangle 6014" width="7" height="7" transform="translate(1280 780)" />
      <rect id="Rectangle_6015" data-name="Rectangle 6015" width="7" height="7" transform="translate(1289 780)" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'list-view'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="list_view" data-name="list view" transform="translate(-270.238 -503.066)">
    <g id="Group_18734" data-name="Group 18734" transform="translate(270.238 503.066)">
      <g class="color-icon-fill-primary" id="Group_14836" data-name="Group 14836">
        <rect id="Rectangle_54" data-name="Rectangle 54" width="10.777" height="3.314" transform="translate(5.224 0)" />
        <rect id="Rectangle_3574" data-name="Rectangle 3574" width="10.777" height="3.314" transform="translate(5.224 12.686)" />
        <rect id="Rectangle_3575" data-name="Rectangle 3575" width="10.777" height="3.314" transform="translate(5.224 6.4)" />
        <rect id="Rectangle_3576" data-name="Rectangle 3576" width="3.314" height="3.314" transform="translate(0 6.4)" />
        <rect id="Rectangle_3578" data-name="Rectangle 3578" width="3.314" height="3.314" transform="translate(0 12.686)" />
        <rect id="Rectangle_3577" data-name="Rectangle 3577" width="3.314" height="3.314" transform="translate(0 0)" />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'bar-chart'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 14">
  <g id="Group_21758" data-name="Group 21758" transform="translate(-1028 -605.721)">
    <rect
      id="Rectangle_8393"
      data-name="Rectangle 8393"
      width="4"
      height="6"
      transform="translate(1028 613.721)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Rectangle_8393_-_Outline"
      data-name="Rectangle 8393 - Outline"
      d="M.98.98V5.39H2.94V.98H.98M0,0H3.92V6.37H0Z"
      transform="translate(1028 613.35)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_8394"
      data-name="Rectangle 8394"
      width="4"
      height="9"
      transform="translate(1034 610.721)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Rectangle_8394_-_Outline"
      data-name="Rectangle 8394 - Outline"
      d="M.98.98V8.33H2.94V.98H.98M0,0H3.92V9.31H0Z"
      transform="translate(1033.88 610.41)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_8395"
      data-name="Rectangle 8395"
      width="4"
      height="14"
      transform="translate(1040 605.721)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Rectangle_8395_-_Outline"
      data-name="Rectangle 8395 - Outline"
      d="M.98.98V12.741H2.94V.98H.98M0,0H3.92V13.721H0Z"
      transform="translate(1039.761 606)"
      class="color-icon-fill-primary"
    />
  </g>
</svg>
<svg *ngIf="name === 'line-chart'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g id="Group_21732" data-name="Group 21732" opacity="0.998">
    <path
      id="Path_7275"
      data-name="Path 7275"
      d="M2,14H16v2H0V0H2Zm2.5-1a1.5,1.5,0,0,1,0-3h.15l1.6-2.7A1.511,1.511,0,0,1,6,6.5a1.5,1.5,0,1,1,3,0,1.383,1.383,0,0,1-.25.8l1.6,2.7h.25l2.65-4.65A1.686,1.686,0,0,1,13,4.5a1.5,1.5,0,1,1,3,0A1.473,1.473,0,0,1,14.5,6h-.1l-2.65,4.65a1.686,1.686,0,0,1,.25.85,1.5,1.5,0,1,1-3,0,1.383,1.383,0,0,1,.25-.8L7.65,8h-.3l-1.6,2.7a1.511,1.511,0,0,1,.25.8A1.473,1.473,0,0,1,4.5,13Z"
      transform="translate(0 0)"
      class="color-icon-fill-primary"
    />
  </g>
</svg>
<svg *ngIf="name === 'user-guide'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 29.505 29.709">
  <g class="color-icon-fill-primary" id="Group_21569" data-name="Group 21569" transform="translate(-2198.65 -1408)">
    <rect
      id="Rectangle_14"
      data-name="Rectangle 14"
      width="25.465"
      height="26.108"
      rx="2"
      transform="translate(2202.689 1409.6)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Rectangle_14_-_Outline"
      data-name="Rectangle 14 - Outline"
      d="M2,1.5a.5.5,0,0,0-.5.5V24.108a.5.5,0,0,0,.5.5H23.465a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H23.465a2,2,0,0,1,2,2V24.108a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
      transform="translate(2202.689 1409.6)"
    />
    <rect
      id="Rectangle_13"
      data-name="Rectangle 13"
      width="25.465"
      height="29.709"
      rx="2"
      transform="translate(2200.037 1408)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Rectangle_13_-_Outline"
      data-name="Rectangle 13 - Outline"
      d="M2,1.5a.5.5,0,0,0-.5.5V27.709a.5.5,0,0,0,.5.5H23.465a.5.5,0,0,0,.5-.5V2a.5.5,0,0,0-.5-.5H2M2,0H23.465a2,2,0,0,1,2,2V27.709a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
      transform="translate(2200.037 1408)"
    />
    <path
      id="Path_6488"
      data-name="Path 6488"
      d="M29.744,0A4.554,4.554,0,0,0,25.7,2.139a.565.565,0,0,0,.122.773l1.014.769a.564.564,0,0,0,.782-.1,2.28,2.28,0,0,1,1.945-1.162c.723,0,1.617.465,1.617,1.167,0,.53-.438.8-1.152,1.2-.833.467-1.934,1.048-1.934,2.5v.23a.564.564,0,0,0,.564.564h1.7a.564.564,0,0,0,.564-.564V7.386c0-1.007,2.944-1.049,2.944-3.775C33.868,1.557,31.739,0,29.744,0Zm-.236,8.778a1.628,1.628,0,1,0,1.628,1.628A1.63,1.63,0,0,0,29.508,8.778Z"
      transform="translate(2183.037 1417.368)"
    />
    <g id="Group_717" data-name="Group 717" transform="translate(2199.4 1412.244)">
      <path
        id="Line_590"
        data-name="Line 590"
        d="M2.653.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H2.653A.75.75,0,0,1,3.4,0,.75.75,0,0,1,2.653.75Z"
        transform="translate(0)"
      />
      <path
        id="Line_591"
        data-name="Line 591"
        d="M2.653.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H2.653A.75.75,0,0,1,3.4,0,.75.75,0,0,1,2.653.75Z"
        transform="translate(0 5.305)"
      />
      <path
        id="Line_592"
        data-name="Line 592"
        d="M2.653.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H2.653A.75.75,0,0,1,3.4,0,.75.75,0,0,1,2.653.75Z"
        transform="translate(0 10.611)"
      />
      <path
        id="Line_593"
        data-name="Line 593"
        d="M2.653.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H2.653A.75.75,0,0,1,3.4,0,.75.75,0,0,1,2.653.75Z"
        transform="translate(0 15.916)"
      />
      <path
        id="Line_594"
        data-name="Line 594"
        d="M2.653.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H2.653A.75.75,0,0,1,3.4,0,.75.75,0,0,1,2.653.75Z"
        transform="translate(0 21.221)"
      />
    </g>
    <path
      id="Path_6489"
      data-name="Path 6489"
      d="M2245.07,1411.886a.75.75,0,0,1-.482-.175l-2.055-1.723-2.055,1.723a.75.75,0,0,1-1.232-.575v-4.143a.75.75,0,0,1,1.5,0v2.536l1.305-1.094a.75.75,0,0,1,.963,0l1.305,1.094v-2.536a.75.75,0,0,1,1.5,0v4.143a.75.75,0,0,1-.75.75Z"
      transform="translate(-24.09 2.78)"
    />
  </g>
</svg>
<svg *ngIf="name === 'compass'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32">
  <g id="Group_21827" data-name="Group 21827" transform="translate(16420 19875)">
    <circle
      id="Ellipse_528"
      data-name="Ellipse 528"
      cx="16"
      cy="16"
      r="16"
      transform="translate(-16420 -19875)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Ellipse_528_-_Outline"
      data-name="Ellipse 528 - Outline"
      d="M16,3A13,13,0,0,0,6.808,25.192,13,13,0,0,0,25.192,6.808,12.915,12.915,0,0,0,16,3m0-3A16,16,0,1,1,0,16,16,16,0,0,1,16,0Z"
      transform="translate(-16420 -19875)"
      class="color-icon-fill-primary"
    />
    <path
      id="Path_8474"
      data-name="Path 8474"
      d="M591,969l-9-5h-2v2l5,9,9,5h2v-2Zm-3,5a2,2,0,1,1,2-2A2.006,2.006,0,0,1,588,974Z"
      transform="translate(-16992 -20831)"
      class="color-icon-fill-primary"
    />
  </g>
</svg>
<svg *ngIf="name === 'extract'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42.06 48">
  <g id="Group_21822" data-name="Group 21822" transform="translate(0.001 -0.004)">
    <path
      id="Path_1742"
      data-name="Path 1742"
      d="M4.3,5.814,28.963,5.8l3.411,1.429,9.078,9.856V49.2l-2.025,1.7L4.295,50.312,1.55,47.753,2.579,7.225Z"
      transform="translate(-0.998 -3.71)"
      class="color-icon-fill-secondary"
    />
    <g id="Group_21692" data-name="Group 21692" transform="translate(-0.001 0.004)">
      <path
        id="Path_1743"
        data-name="Path 1743"
        d="M1762.591,537.076l-6.743-6.743a10.559,10.559,0,0,0-6.421-2.729h-22.635a3.734,3.734,0,0,0-3.693,3.693v40.615a3.734,3.734,0,0,0,3.693,3.693h34.675a3.734,3.734,0,0,0,3.693-3.693V543.332a10.327,10.327,0,0,0-2.568-6.256Zm-9.476-4.977.481.482,6.743,6.742.482.481h-7.705Zm8.989,39.973a.758.758,0,0,1-.8.8h-34.675a.758.758,0,0,1-.8-.8V531.453a.758.758,0,0,1,.8-.8h23.438v12.04h12.04v29.378Z"
        transform="translate(-1723.099 -527.604)"
        class="color-icon-fill-tertiary"
      />
    </g>
    <g id="Group_21693" data-name="Group 21693" transform="translate(13.447 18.539)">
      <path
        id="Path_1744"
        data-name="Path 1744"
        d="M20.295,11.806a1.189,1.189,0,1,0-1.825-1.521l-3.955,4.564V4.2H12.233V14.7L8.282,10.133a1.013,1.013,0,0,0-1.521,0,1.084,1.084,0,0,0-.456.913,1.162,1.162,0,0,0,.3.761l6.845,7.911Z"
        transform="translate(-6.3 -4.2)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6120"
        data-name="Rectangle 6120"
        width="14.3"
        height="2.282"
        transform="translate(0 17.19)"
        class="color-icon-fill-primary"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'all-data'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42.06 48">
  <g id="Group_21823" data-name="Group 21823" transform="translate(0.001 -0.004)">
    <path
      id="Path_1748"
      data-name="Path 1748"
      d="M1.612,3V45.216H4.225l33.993,1.458,3.417-2.856V13.62L28.3,3Z"
      class="color-icon-fill-secondary"
    />
    <g id="Group_21695" data-name="Group 21695" transform="translate(-0.001 0.004)">
      <path
        id="Path_1745"
        data-name="Path 1745"
        d="M1762.591,537.076l-6.743-6.743a10.558,10.558,0,0,0-6.421-2.729h-22.635a3.734,3.734,0,0,0-3.693,3.693v40.615a3.734,3.734,0,0,0,3.692,3.692h34.675a3.734,3.734,0,0,0,3.693-3.692V543.332a10.328,10.328,0,0,0-2.568-6.256Zm-9.476-4.977.481.482,6.743,6.742.482.481h-7.705Zm8.99,39.973a.758.758,0,0,1-.8.8h-34.675a.758.758,0,0,1-.8-.8V531.453a.758.758,0,0,1,.8-.8h23.438v12.04h12.04v29.378Z"
        transform="translate(-1723.099 -527.604)"
        class="color-icon-fill-tertiary"
      />
    </g>
    <rect
      id="Rectangle_6121"
      data-name="Rectangle 6121"
      width="10.647"
      height="4.259"
      transform="translate(5.324 26.618)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6122"
      data-name="Rectangle 6122"
      width="10.647"
      height="4.259"
      transform="translate(5.324 39.395)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6123"
      data-name="Rectangle 6123"
      width="9.583"
      height="4.259"
      transform="translate(18.1 26.618)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6124"
      data-name="Rectangle 6124"
      width="9.583"
      height="4.259"
      transform="translate(18.1 39.395)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6125"
      data-name="Rectangle 6125"
      width="7.453"
      height="4.259"
      transform="translate(29.812 26.618)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6126"
      data-name="Rectangle 6126"
      width="7.453"
      height="4.259"
      transform="translate(29.812 39.395)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6127"
      data-name="Rectangle 6127"
      width="10.647"
      height="4.259"
      transform="translate(5.324 20.23)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6128"
      data-name="Rectangle 6128"
      width="10.647"
      height="4.259"
      transform="translate(5.324 33.006)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6129"
      data-name="Rectangle 6129"
      width="9.583"
      height="4.259"
      transform="translate(18.1 20.23)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6130"
      data-name="Rectangle 6130"
      width="9.583"
      height="4.259"
      transform="translate(18.1 33.006)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6131"
      data-name="Rectangle 6131"
      width="7.453"
      height="4.259"
      transform="translate(29.812 20.23)"
      class="color-icon-fill-primary"
    />
    <rect
      id="Rectangle_6132"
      data-name="Rectangle 6132"
      width="7.453"
      height="4.259"
      transform="translate(29.812 33.006)"
      class="color-icon-fill-primary"
    />
  </g>
</svg>
<svg *ngIf="name === 'selected-data'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42.06 48">
  <g id="Group_21824" data-name="Group 21824" transform="translate(-330 -319)">
    <g id="Group_21700" data-name="Group 21700" transform="translate(-57.999 -185.004)">
      <g id="Group_21698" data-name="Group 21698" transform="translate(387.999 504.004)">
        <path
          id="Path_1746"
          data-name="Path 1746"
          d="M4.3,5.814,28.933,5.8,32.34,7.227l9.068,9.843V49.143l-2.023,1.7L4.292,50.253,1.55,47.7,2.577,7.224Z"
          transform="translate(-0.985 -3.672)"
          class="color-icon-fill-secondary"
        />
        <g id="Group_21697" data-name="Group 21697" transform="translate(0 0)">
          <path
            id="Path_1747"
            data-name="Path 1747"
            d="M1762.591,537.076l-6.743-6.743a10.559,10.559,0,0,0-6.421-2.729h-22.635a3.734,3.734,0,0,0-3.693,3.693v40.615a3.734,3.734,0,0,0,3.693,3.692h34.675a3.734,3.734,0,0,0,3.692-3.692V543.332a10.328,10.328,0,0,0-2.568-6.256Zm-9.476-4.977.481.482,6.743,6.742.482.481h-7.705Zm8.989,39.973a.758.758,0,0,1-.8.8h-34.675a.758.758,0,0,1-.8-.8V531.453a.758.758,0,0,1,.8-.8h23.438v12.04h12.04v29.378Z"
            transform="translate(-1723.099 -527.604)"
            class="color-icon-fill-tertiary"
          />
        </g>
      </g>
    </g>
    <g id="Group_21701" data-name="Group 21701" transform="translate(-3.266)">
      <rect
        id="Rectangle_6145"
        data-name="Rectangle 6145"
        width="10.647"
        height="4.259"
        transform="translate(338.324 345.991)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6146"
        data-name="Rectangle 6146"
        width="10.647"
        height="4.259"
        transform="translate(338.324 358.768)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_6147"
        data-name="Rectangle 6147"
        width="9.583"
        height="4.259"
        transform="translate(351.101 345.991)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6148"
        data-name="Rectangle 6148"
        width="9.583"
        height="4.259"
        transform="translate(351.101 358.768)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6149"
        data-name="Rectangle 6149"
        width="7.453"
        height="4.259"
        transform="translate(362.813 345.991)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6150"
        data-name="Rectangle 6150"
        width="7.453"
        height="4.259"
        transform="translate(362.813 358.768)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6151"
        data-name="Rectangle 6151"
        width="10.647"
        height="4.259"
        transform="translate(338.324 339.603)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_6152"
        data-name="Rectangle 6152"
        width="10.647"
        height="4.259"
        transform="translate(338.324 352.379)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6153"
        data-name="Rectangle 6153"
        width="9.583"
        height="4.259"
        transform="translate(351.101 339.603)"
        class="color-icon-fill-primary"
      />
      <rect
        id="Rectangle_6154"
        data-name="Rectangle 6154"
        width="9.583"
        height="4.259"
        transform="translate(351.101 352.379)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_6155"
        data-name="Rectangle 6155"
        width="7.453"
        height="4.259"
        transform="translate(362.813 339.603)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_6156"
        data-name="Rectangle 6156"
        width="7.453"
        height="4.259"
        transform="translate(362.813 352.379)"
        fill="#ccc"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'arrow-curve-right'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 26.972 32">
  <path
    class="color-icon-fill-tertiary"
    id="Path_333"
    data-name="Path 333"
    d="M786,1287.8V1280l12,12-12,12v-7.9c-14-.3-13.4,9.5-9.8,15.9C767.4,1302.5,769.3,1287.3,786,1287.8Z"
    transform="translate(-771.028 -1280)"
  />
</svg>
<svg *ngIf="name === 'add-folder'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 13.867">
  <path
    class="color-icon-fill-primary"
    id="Path_8399"
    data-name="Path 8399"
    d="M2008.5,105.867h-6.72V103.2h-7.68v12.267a1.64,1.64,0,0,0,1.6,1.6h12.8a1.64,1.64,0,0,0,1.6-1.6v-8A1.512,1.512,0,0,0,2008.5,105.867Zm-.747,6.4h-2.346v2.347h-1.6v-2.347h-2.453v-1.6h2.347v-2.453h1.6v2.347h2.347v1.707h.107Z"
    transform="translate(-1994.1 -103.2)"
  />
</svg>
<svg *ngIf="name === 'weight'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15.335 16">
  <g class="color-icon-fill-primary" id="Weights" transform="translate(-2298.774 -544.2)">
    <path
      id="Path_1563"
      data-name="Path 1563"
      d="M2306.368,559.019H2299.4c-.444,0-.741-.3-.592-.9q.444-2.479.889-4.958c.3-1.352.445-2.7.741-4.207a4.214,4.214,0,0,0,.148-1.352c.148-.451.3-.6.592-.6H2311.7a.546.546,0,0,1,.592.6q.667,3.831,1.333,7.662.223,1.352.444,2.7c.149.6-.148.9-.74.9C2310.961,559.019,2308.59,559.019,2306.368,559.019Z"
      transform="translate(0 1.181)"
    />
    <path
      id="Path_1564"
      data-name="Path 1564"
      d="M2304.87,545.337A2.094,2.094,0,0,1,2307,547.47h1.138a3.217,3.217,0,0,0-3.27-3.27,3.4,3.4,0,0,0-3.27,3.27h1.138A2.093,2.093,0,0,1,2304.87,545.337Z"
      transform="translate(1.496 0)"
    />
  </g>
</svg>
<svg *ngIf="name === 'folder'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17 14.867">
  <path
    id="Path_8373"
    data-name="Path 8373"
    d="M14.4,2.667H7.68V0H0V12.267a1.64,1.64,0,0,0,1.6,1.6H14.4a1.64,1.64,0,0,0,1.6-1.6v-8A1.512,1.512,0,0,0,14.4,2.667Z"
    transform="translate(0.5 0.5)"
    class="color-icon-fill-primary color-icon-stroke-primary"
    stroke-width="1"
  />
</svg>
<svg *ngIf="name === 'subnational-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
  <g id="Subnational" transform="translate(-504.4 -1108.4)">
    <g
      id="Ellipse_461"
      data-name="Ellipse 461"
      transform="translate(504.4 1108.4)"
      class="color-icon-fill-primary color-icon-stroke-primary"
      stroke-width="1"
    >
      <circle cx="8" cy="8" r="8" stroke="none" />
      <circle cx="8" cy="8" r="7.5" fill="none" />
    </g>
    <path
      class="color-icon-fill-secondary"
      id="Path_8392"
      data-name="Path 8392"
      d="M3.742-4.174A.63.63,0,0,0,3.5-4.708,3.219,3.219,0,0,0,2.613-5.1,6.262,6.262,0,0,1,1.6-5.507,1.651,1.651,0,0,1,.588-6.984,1.438,1.438,0,0,1,.86-7.845a1.774,1.774,0,0,1,.781-.591,2.94,2.94,0,0,1,1.143-.213,2.676,2.676,0,0,1,1.137.231,1.81,1.81,0,0,1,.775.652,1.707,1.707,0,0,1,.276.957H3.746a.8.8,0,0,0-.258-.636,1.059,1.059,0,0,0-.724-.227,1.132,1.132,0,0,0-.7.19.6.6,0,0,0-.249.5.575.575,0,0,0,.292.487,3.3,3.3,0,0,0,.861.368,3.72,3.72,0,0,1,1.526.781,1.556,1.556,0,0,1,.479,1.162A1.433,1.433,0,0,1,4.388-2.97a2.56,2.56,0,0,1-1.575.44,3.041,3.041,0,0,1-1.251-.252A1.98,1.98,0,0,1,.7-3.471,1.765,1.765,0,0,1,.4-4.485H1.635q0,.986,1.178.986A1.146,1.146,0,0,0,3.5-3.677.579.579,0,0,0,3.742-4.174Z"
      transform="translate(509.712 1121.99)"
    />
  </g>
</svg>
<svg *ngIf="name === 'save'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <path
    class="color-icon-fill-primary"
    id="Path_2"
    data-name="Path 2"
    d="M2210.821,292.9h-10.632a1.844,1.844,0,0,0-1.789,1.789v12.421a1.844,1.844,0,0,0,1.789,1.79h12.421a1.845,1.845,0,0,0,1.79-1.79V296.479Zm-4.421,14.105a2.632,2.632,0,1,1,2.632-2.632A2.606,2.606,0,0,1,2206.4,307.005Zm2.632-8.842h-8.842v-3.579h8.842Z"
    transform="translate(-2198.4 -292.9)"
  />
</svg>
<svg *ngIf="name === 'save-as'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 17.53 17.523">
  <g class="color-icon-fill-primary" id="Group_1" data-name="Group 1" transform="translate(18238.293 23350.365)">
    <path
      id="Path_8405"
      data-name="Path 8405"
      d="M2210.789,292.9h-10.6a1.84,1.84,0,0,0-1.785,1.785v12.389a1.84,1.84,0,0,0,1.785,1.785h12.389a1.84,1.84,0,0,0,1.785-1.785v-10.6h-3.57Zm-4.41,14.069a2.625,2.625,0,1,1,2.625-2.625A2.6,2.6,0,0,1,2206.379,306.969ZM2209,298.15h-8.819v-3.57H2209Z"
      transform="translate(-20436.693 -23641.701)"
    />
    <g id="Group_21496" data-name="Group 21496" transform="translate(-18227.111 -23349.865)">
      <path
        id="Path_8401"
        data-name="Path 8401"
        d="M2557.583-587h3.8v3.942"
        transform="translate(-2555.531 587)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="Line_710"
        data-name="Line 710"
        y1="5.185"
        x2="5.185"
        transform="translate(0 0.701)"
        fill="none"
        class="color-icon-stroke-primary"
        stroke-linecap="round"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'globe'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <path
    id="Country"
    d="M264,2818a8,8,0,1,0,8,8A7.989,7.989,0,0,0,264,2818Zm4.533,10.667a13.732,13.732,0,0,0,.267-2.133h2.133a5.989,5.989,0,0,1-.533,2.133Zm-9.067-5.334a13.689,13.689,0,0,0-.267,2.133h-2.133a5.974,5.974,0,0,1,.533-2.133Zm7.947,0a9.334,9.334,0,0,1,.267,2.133h-3.2v-2.133Zm-2.88-1.067v-3.147a2.447,2.447,0,0,1,.747.375,5.268,5.268,0,0,1,1.227,1.385,5.524,5.524,0,0,1,.64,1.387Zm-2.987-1.387a5.267,5.267,0,0,1,1.227-1.385,3.219,3.219,0,0,1,.747-.375v3.147h-2.613A6.539,6.539,0,0,1,261.547,2820.88Zm1.92,2.454v2.133h-3.2a13.693,13.693,0,0,1,.267-2.133Zm-5.867,5.334a7.376,7.376,0,0,1-.533-2.133H259.2a13.729,13.729,0,0,0,.267,2.133Zm2.667-2.133h3.2v2.133h-2.88A13.707,13.707,0,0,1,260.267,2826.534Zm3.2,3.2v3.146a2.479,2.479,0,0,1-.747-.373,5.322,5.322,0,0,1-1.227-1.387,5.5,5.5,0,0,1-.64-1.386Zm2.987,1.386a5.327,5.327,0,0,1-1.227,1.387,3.311,3.311,0,0,1-.747.373v-3.146h2.613A6.524,6.524,0,0,1,266.453,2831.12Zm-1.92-2.452v-2.133h3.2a13.736,13.736,0,0,1-.267,2.133Zm4.267-3.2a13.693,13.693,0,0,0-.267-2.133H270.4a7.359,7.359,0,0,1,.533,2.133Zm1.067-3.2h-1.6a8.526,8.526,0,0,0-1.28-2.507,7.768,7.768,0,0,1,1.92,1.334A6.574,6.574,0,0,1,269.867,2822.267Zm-10.773-1.173a6.561,6.561,0,0,1,1.92-1.334,8.519,8.519,0,0,0-1.28,2.507h-1.6A6.581,6.581,0,0,1,259.093,2821.094Zm-.96,8.64h1.6a8.53,8.53,0,0,0,1.28,2.506,7.779,7.779,0,0,1-1.92-1.333A6.648,6.648,0,0,1,258.133,2829.734Zm10.773,1.173a6.578,6.578,0,0,1-1.92,1.333,8.537,8.537,0,0,0,1.28-2.506h1.6A6.641,6.641,0,0,1,268.907,2830.907Z"
    transform="translate(-256 -2818)"
    class="color-icon-fill-primary"
  />
</svg>
<svg *ngIf="name === 'tick-circle'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
  <g class="color-icon-fill-primary" id="Group_21458" data-name="Group 21458" transform="translate(16568 21579)">
    <g id="Group_21456" data-name="Group 21456" transform="translate(-26069 -19005)">
      <g
        id="Rectangle_5029"
        data-name="Rectangle 5029"
        transform="translate(9501 -2574)"
        class="color-icon-stroke-primary"
        stroke-width="1"
      >
        <rect width="16" height="16" rx="8" stroke="none" />
        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="none" />
      </g>
    </g>
    <path
      class="color-icon-stroke-secondary"
      id="Tick"
      d="M-145.433,1454.949l2.025,2.025,2.147-2.147,3.157-3.157"
      transform="translate(-16621.816 -23030.898) rotate(-8)"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </g>
</svg>
<svg *ngIf="name === 'new-window'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13.091 16">
  <g class="color-icon-fill-primary" id="Group_21498" data-name="Group 21498" transform="translate(16569.576 21169.498)">
    <path
      id="Subtraction_19"
      data-name="Subtraction 19"
      d="M13.091,16H5.2V14.545h6.435V1.454H1.454v9.452H0V0H13.091V16Z"
      transform="translate(-16569.576 -21169.498)"
    />
    <path
      id="Path_8264"
      data-name="Path 8264"
      d="M2562.593-582.326h-1.461v-3.713h-3.549V-587.5h5.01Z"
      transform="translate(-19123.412 -20574.801)"
    />
    <path id="Line_630" data-name="Line 630" d="M.68,6.524-.354,5.491,5.491-.354,6.524.68Z" transform="translate(-16568.336 -21160.895)" />
  </g>
</svg>
<svg *ngIf="name === 'burger-menu'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 14.838">
  <g id="Group_21478" data-name="Group 21478" transform="translate(16048.5 21233.5)">
    <line
      id="Line_698"
      data-name="Line 698"
      x2="16"
      transform="translate(-16047.5 -21219.662)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_699"
      data-name="Line 699"
      x2="16"
      transform="translate(-16047.5 -21226.082)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_700"
      data-name="Line 700"
      x2="16"
      transform="translate(-16047.5 -21232.5)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
  </g>
</svg>
<svg
  *ngIf="name === 'sovereign'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  height="100%"
  viewBox="0 0 16 14.954"
>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_5221" data-name="Rectangle 5221" width="16" height="14.954" />
    </clipPath>
  </defs>
  <g class="color-icon-fill-primary" id="Group_21428" data-name="Group 21428" transform="translate(0 0)">
    <g id="Group_21211" data-name="Group 21211" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path
        id="Path_8322"
        data-name="Path 8322"
        d="M1.88,12.771a28.075,28.075,0,0,0,12.241,0,27.868,27.868,0,0,0-2.472-5.639A27.927,27.927,0,0,0,8,2.17a27.956,27.956,0,0,0-6.12,10.6M8,14.954a29.311,29.311,0,0,1-7.253-.907L0,13.856l.208-.742A29.463,29.463,0,0,1,7.461.552L8,0l.539.552A29.358,29.358,0,0,1,12.95,6.379a29.35,29.35,0,0,1,2.842,6.736l.208.742-.746.19A29.312,29.312,0,0,1,8,14.954m0-3.113a26.658,26.658,0,0,1-3.989-.3A26.474,26.474,0,0,1,8,4.631a26.309,26.309,0,0,1,2.257,3.3A26.459,26.459,0,0,1,11.99,11.54a26.644,26.644,0,0,1-3.989.3"
        transform="translate(0 0)"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'commodity-risk'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 11.956">
  <path
    class="color-icon-fill-primary"
    id="Commodity_Risk_Module"
    data-name="Commodity Risk Module"
    d="M12.021,2.181a8.058,8.058,0,0,0-1.994,1.24C6.415,3.69,3.935,2.451,0,3.69V3.637C0-.622,9.757-1.107,12.021,2.181ZM7.115,7.194C4.905,7.356.97,6.871.108,4.445c3.45-1.132,5.822-.216,9.056-.216A11.21,11.21,0,0,0,7.115,7.194Zm7.6-4.69c-1.617,3.5-5.229,5.067-7.061,8.84C4.851,8.7,11.374.834,14.716,2.5Zm.647.431C18,5.847,11.8,13.339,8.3,11.776,10.08,8.057,13.692,6.493,15.363,2.936Z"
    transform="translate(0 -0.032)"
  />
</svg>
<svg
  *ngIf="name === 'industry-chart'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="100%"
  height="100%"
  viewBox="0 0 16.465 17.546"
>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_43" data-name="Rectangle 43" width="7.027" height="1.573" />
    </clipPath>
  </defs>
  <g class="color-icon-fill-primary" id="Group_20864" data-name="Group 20864" transform="translate(-725 -337.357)">
    <rect id="Rectangle_32" data-name="Rectangle 32" width="3.454" height="12.951" transform="translate(725 341.952)" />
    <path
      id="Subtraction_1"
      data-name="Subtraction 1"
      d="M2373.573,3380.621h-3.454v-4.756l1.754,1.343,1.7-2.19v5.6Zm-4.317,0H2365.8v-3.236l1.629,1.418,1.824-2.864v4.681Zm-4.317,0h-3.454v-.177l3.454-3.658v3.834Zm-3.454-1.435v-4.177h3.454v.518l-3.454,3.657Zm5.757-1.692h0l-1.439-1.252v-4.253h3.454v2.346l-2.013,3.161Zm4.475-1.493h0l-1.6-1.224v-7.108h3.454v5.939l-1.856,2.392Z"
      transform="translate(-1632.108 -3025.719)"
    />
    <rect id="Rectangle_42" data-name="Rectangle 42" width="3.454" height="1.295" transform="translate(725 339.793)" />
    <g id="Group_5" data-name="Group 5" transform="translate(726.667 337.357)">
      <g id="Group_4" data-name="Group 4" clip-path="url(#clip-path)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M5.819,1.573A1.27,1.27,0,0,1,4.8,1C4.616.781,4.516.674,4.3.674S3.99.781,3.8,1a1.27,1.27,0,0,1-1.016.569A1.27,1.27,0,0,1,1.77,1c-.188-.223-.288-.33-.5-.33S.956.781.768,1a1.98,1.98,0,0,1-.389.378L0,.826A1.436,1.436,0,0,0,.253.569,1.269,1.269,0,0,1,1.268,0,1.27,1.27,0,0,1,2.285.569c.188.222.288.329.5.329s.313-.107.5-.33A1.27,1.27,0,0,1,4.3,0,1.27,1.27,0,0,1,5.319.569c.188.223.288.329.5.329s.313-.107.5-.33A2.6,2.6,0,0,1,6.58.291l.447.5A2.108,2.108,0,0,0,6.835,1a1.27,1.27,0,0,1-1.016.57"
          transform="translate(0 0)"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'industry'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 18.286">
  <path
    class="color-icon-fill-primary"
    id="Industry_Risk_Module"
    data-name="Industry Risk Module"
    d="M14.61,5.39,12,8V5.962a.813.813,0,0,0-.819-.819.826.826,0,0,0-.571.248L8,8V5.962a.813.813,0,0,0-.819-.819.826.826,0,0,0-.571.248L4,8V.857A.854.854,0,0,0,3.143,0H.857A.854.854,0,0,0,0,.857V17.429a.854.854,0,0,0,.857.857H15.143A.854.854,0,0,0,16,17.429V5.962a.813.813,0,0,0-.819-.819A.918.918,0,0,0,14.61,5.39ZM3.429,15.714a1.143,1.143,0,1,1,1.143-1.143A1.146,1.146,0,0,1,3.429,15.714Zm4.571,0a1.143,1.143,0,1,1,1.143-1.143A1.146,1.146,0,0,1,8,15.714Zm4.571,0a1.143,1.143,0,1,1,1.143-1.143A1.146,1.146,0,0,1,12.571,15.714Z"
  />
</svg>
<svg *ngIf="name === 'data-wizard'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16.037">
  <path
    class="color-icon-fill-primary"
    id="Data_Wizard_Module"
    data-name="Data Wizard Module"
    d="M4,3,2,1H1V2L3,4ZM5,0H6V2H5Zm4,5h2V6h-2Zm1-3V1h-1l-2,2,1,1ZM0,5H2V6H0Zm5,4H6v2H5Zm-4,0v1H2l2-2-1-1Zm14.812,4.8L5.855,3.853a.726.726,0,0,0-1.051,0L3.853,4.8a.726.726,0,0,0,0,1.051l9.958,9.958a.726.726,0,0,0,1.051,0l.951-.951A.829.829,0,0,0,15.812,13.811Zm-8.306-5.3-3-3,1-1,3,3Z"
  />
</svg>
<svg
  *ngIf="name === 'document-library'"
  #icon
  id="Document_Library"
  data-name="Document Library"
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16.042 18.927"
>
  <path
    class="color-icon-fill-primary"
    id="Path_5357"
    data-name="Path 5357"
    d="M6.428,0a.946.946,0,0,1,.651,1.064,5,5,0,0,0,0,.642.85.85,0,0,1-.523.963C6.1,2.852,5.8,2.6,5.529,2.237c-.211.128-.413.257-.614.385C4,3.2,3.081,3.768,2.173,4.364a1.161,1.161,0,0,0-.513.541,1.3,1.3,0,0,0,.037.935c.1.165.568.229.816.156a5.73,5.73,0,0,0,1.495-.651C5.823,4.19,7.611,3,9.417,1.825c.064-.046.128-.083.193-.128.541-.358,1.018-.321,1.293.083a.9.9,0,0,1-.367,1.284c-1.128.752-2.246,1.522-3.393,2.246a3.262,3.262,0,0,0-1.6,2.531c-.037.376-.046.761-.055,1.146v7.106c0,.789-.174,1.045-.954,1.2a5.224,5.224,0,0,1-3.356-.348A1.979,1.979,0,0,1-.064,14.991c.009-3.163-.018-6.327,0-9.49a2.74,2.74,0,0,1,1.33-2.457C2.907,2.054,4.53,1.018,6.153,0Z"
    transform="translate(0.07)"
  />
  <path
    class="color-icon-fill-primary"
    id="Path_5358"
    data-name="Path 5358"
    d="M74.975,32.675C73.829,33.4,72.71,34.094,71.6,34.8a1.778,1.778,0,0,0-.486.432c-.376.515-.073,1.071.605,1.1a3.6,3.6,0,0,0,2.173-.656c1.715-1.137,3.4-2.3,5.1-3.453a1.007,1.007,0,0,1,1.036-.241c.44.183.477.531.477.9v8.8a1.04,1.04,0,0,1-.5.954c-1.77,1.179-3.493,2.415-5.291,3.56A4.3,4.3,0,0,1,70,45.83a1.635,1.635,0,0,1-.6-1.253c.009-3,0-6,.009-9a1.974,1.974,0,0,1,.926-1.685c1.632-1.071,3.283-2.108,4.951-3.129a.76.76,0,0,1,1.21.622c.009.307,0,.606,0,.913a.676.676,0,0,1-.477.73.8.8,0,0,1-.935-.249C75.076,32.741,75.039,32.725,74.975,32.675Zm-.009,7.3c.064-.008.1-.008.119-.025q1.829-1.282,3.649-2.565a.451.451,0,0,0,.174-.224c.018-.423.009-.838.009-1.345-1.027.722-1.925,1.461-2.934,2.033A1.777,1.777,0,0,0,74.966,39.97Z"
    transform="translate(-64.462 -27.811)"
  />
</svg>
<svg *ngIf="name === 'email'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 12.8">
  <path
    class="color-icon-fill-primary"
    id="Emai_Alerts"
    data-name="Emai Alerts"
    d="M71.6,4680H84.4a1.492,1.492,0,0,1,.64.161L78,4688.4l-7.04-8.24A1.492,1.492,0,0,1,71.6,4680ZM70,4691.2v-9.679l4.72,5.439-4.64,4.641A.6.6,0,0,1,70,4691.2Zm14.4,1.6H71.6a.6.6,0,0,1-.4-.08l4.56-4.56L78,4690.8l2.24-2.64,4.56,4.56A.6.6,0,0,1,84.4,4692.8Zm1.6-1.6a.723.723,0,0,1-.08.4l-4.64-4.64,4.72-5.44v9.679Z"
    transform="translate(-70 -4680)"
  />
</svg>
<svg *ngIf="name === 'calendar'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.062 17.133">
  <path
    class="color-icon-fill-primary"
    id="Path_8369"
    data-name="Path 8369"
    d="M197.354,1094.425H199.5v2.142h-2.142Zm3.212,0h2.142v2.142h-2.142Zm3.212,0h2.142v2.142h-2.142Zm-9.637,6.425h2.142v2.141h-2.142Zm3.212,0H199.5v2.141h-2.142Zm3.212,0h2.142v2.141h-2.142Zm-3.212-3.213H199.5v2.141h-2.142Zm3.212,0h2.142v2.141h-2.142Zm3.212,0h2.142v2.141h-2.142Zm-9.637,0h2.142v2.141h-2.142ZM205.921,1088v1.071h-2.142V1088h-7.5v1.071h-2.142V1088H192v17.133h16.062V1088Zm1.071,16.063H193.071v-11.779h13.921Z"
    transform="translate(-192 -1088)"
  />
</svg>
<svg *ngIf="name === 'country-risk'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32">
  <path
    class="color-icon-fill-primary"
    id="Country-RIsk-Module"
    d="M27.3,4.7A15.981,15.981,0,1,1,4.7,27.3,15.981,15.981,0,1,1,27.3,4.7ZM25.9,25.9a13.388,13.388,0,0,0,3.8-7.1c-.4.6-.8.9-1.1-.5-.3-2.3-2.3-.8-3.6-1.6-1.4.9-4.5-1.8-3.9,1.3.8,1.4,4.4-1.9,2.6,1.1-1.1,2-4.1,6.6-3.8,8.9,0,3.4-3.5.7-4.7-.4-.8-2.3-.3-6.3-2.4-7.4-2.3-.1-4.3-.3-5.2-2.9-.5-1.9.6-4.7,2.6-5.1,2.9-1.8,4,2.2,6.7,2.2.9-.9,3.2-1.2,3.4-2.2-1.8-.3,2.3-1.5-.2-2.2-1.4.2-2.2,1.4-1.5,2.5-2.6.6-2.7-3.8-5.2-2.4-.1,2.2-4.1.7-1.4.3.9-.4-1.5-1.6-.2-1.4.7,0,2.9-.8,2.3-1.3,1.2-.8,2.3,1.8,3.5-.1.9-1.4-.4-1.7-1.4-1-.6-.7,1.1-2.2,2.6-2.8a3.915,3.915,0,0,1,1.3-.3c.8.9,2.1,1,2.2-.1A15.959,15.959,0,0,0,16,2,13.909,13.909,0,0,0,7.6,4.8c.6.3,1,.7.4,1.1C7.6,7.3,5.6,9.3,3.9,9a12.385,12.385,0,0,0-1.7,5c1.4.5,1.8,1.4,1.5,1.7a4.448,4.448,0,0,0-1.4,2.5,13.933,13.933,0,0,0,3.9,7.6A13.38,13.38,0,0,0,16,30,14.087,14.087,0,0,0,25.9,25.9Z"
  />
</svg>
<svg *ngIf="name === 'corporate-exposure'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
  <path
    class="color-icon-fill-primary"
    id="Corporate_Exposure_Module"
    data-name="Corporate Exposure Module"
    d="M0,24H12V0H0ZM7.5,3h3V6h-3Zm0,6h3v3h-3Zm0,6h3v3h-3ZM1.5,3h3V6h-3Zm0,6h3v3h-3Zm0,6h3v3h-3Zm12-7.5H24V9H13.5Zm0,16.5h3V18H21v6h3V10.5H13.5Z"
  />
</svg>
<svg *ngIf="name === 'play'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 10 12">
  <path class="color-icon-fill-primary" id="Polygon_14" data-name="Polygon 14" d="M6,0l6,10H0Z" transform="translate(10) rotate(90)" />
</svg>
<svg *ngIf="name === 'zip'" #icon xmlns="http://www.w3.org/2000/svg" width="16" height="18.259" viewBox="0 0 16 18.259">
  <g class="color-icon-fill-primary" id="Group_21461" data-name="Group 21461" transform="translate(-1020.001 -940)">
    <path
      id="Path_8381"
      data-name="Path 8381"
      d="M2.6,5.8l9.37-.006,1.3.543,3.45,3.744v12.2l-.768.645L2.6,22.709l-1.043-.973.387-15.4Z"
      transform="translate(1018.659 935.014)"
      fill="#fff"
    />
    <path
      id="Zip"
      d="M1626.99,529.921h1.71v1.16h-1.71Zm-1.71,1.16h1.71v1.16h-1.71Zm1.71,1.16h1.71v1.16h-1.71Zm-1.71,1.1h1.71v1.16h-1.71Zm1.71,1.16h1.71v1.16h-1.71Zm-1.71,1.16h1.71v1.16h-1.71Zm1.71,1.16v1.16h-1.71v4.03a.547.547,0,0,0,.55.55h2.321a.547.547,0,0,0,.55-.55v-5.13h-1.71Zm1.1,4.58h-2.321v-1.16h2.321Zm7.572-10.2-2.565-2.565a4.017,4.017,0,0,0-2.443-1.038H1622.1a1.421,1.421,0,0,0-1.4,1.4v15.45a1.42,1.42,0,0,0,1.4,1.4H1635.3a1.42,1.42,0,0,0,1.4-1.4v-10.87A3.672,3.672,0,0,0,1635.661,531.2Zm-.794.794.183.183h-2.931v-2.931l.183.183Zm.672,12.519a.288.288,0,0,1-.305.305H1622.1a.288.288,0,0,1-.305-.305v-15.45a.288.288,0,0,1,.305-.305h8.916v4.58h4.58v11.175Z"
      transform="translate(-600.699 412.4)"
    />
  </g>
</svg>
<svg *ngIf="name === 'folder-tools'" #icon xmlns="http://www.w3.org/2000/svg" width="69.805" height="73.166" viewBox="0 0 69.805 73.166">
  <g id="Group_21429" data-name="Group 21429" transform="translate(-163.698 -363.834)">
    <g id="Group_18531" data-name="Group 18531" transform="translate(180 408.494)">
      <path
        id="Path_7273"
        data-name="Path 7273"
        d="M7.2,16.1.831,32.267H30.265L36.952,16.1Z"
        transform="translate(-0.24 -4.657)"
        class="color-icon-fill-tertiary"
      />
      <path
        id="Path_7271"
        data-name="Path 7271"
        d="M37.383,46.962a.686.686,0,0,0-.567-.3H30.99V41.746a.686.686,0,0,0-.686-.686H15.561V36.774a.686.686,0,0,0-.686-.686H.686A.686.686,0,0,0,0,36.774V63.908a.693.693,0,0,0,0,.074s0,.009,0,.014c0,.021.006.042.011.063l.005.02c0,.018.01.037.017.055l.007.02q.011.028.024.056l.008.016a.689.689,0,0,0,.04.068h0l0,0a.655.655,0,0,0,.044.056l.017.018.037.037.019.017q.022.019.046.037l.014.01a.685.685,0,0,0,.066.04l.009,0c.02.01.04.019.061.028l.019.007.055.017.022.006.057.011.021,0a.716.716,0,0,0,.079,0H30.3a.686.686,0,0,0,.638-.435L37.455,47.6A.685.685,0,0,0,37.383,46.962ZM1.372,37.46H14.189v4.286a.686.686,0,0,0,.686.686H29.618v4.23H7.2a.686.686,0,0,0-.638.435L1.372,60.29ZM29.836,63.222H1.693L7.666,48.034H35.81Z"
        transform="translate(0 -36.088)"
        class="color-icon-fill-secondary"
      />
    </g>
    <g id="Group_18532" data-name="Group 18532" transform="translate(163.698 370.531)" opacity="0.998">
      <path
        id="Path_7275"
        data-name="Path 7275"
        d="M2.038,14.264H16.3V16.3H0V0H2.038Zm2.547-1.019a1.528,1.528,0,0,1,0-3.057h.153l1.63-2.751a1.539,1.539,0,0,1-.255-.815,1.528,1.528,0,1,1,3.057,0,1.409,1.409,0,0,1-.255.815l1.63,2.751H10.8l2.7-4.738a1.717,1.717,0,0,1-.255-.866,1.528,1.528,0,1,1,3.057,0,1.5,1.5,0,0,1-1.528,1.528h-.1l-2.7,4.738a1.717,1.717,0,0,1,.255.866,1.528,1.528,0,1,1-3.057,0,1.409,1.409,0,0,1,.255-.815L7.795,8.151H7.489L5.859,10.9a1.539,1.539,0,0,1,.255.815A1.5,1.5,0,0,1,4.585,13.246Z"
        transform="translate(0 0)"
        class="color-icon-fill-secondary"
      />
    </g>
    <path
      id="Path_7260"
      data-name="Path 7260"
      d="M264,2818a8,8,0,1,0,8,8A7.989,7.989,0,0,0,264,2818Zm4.533,10.667a13.721,13.721,0,0,0,.267-2.133h2.133a5.989,5.989,0,0,1-.533,2.133Zm-9.067-5.333a13.678,13.678,0,0,0-.267,2.133h-2.133a5.974,5.974,0,0,1,.533-2.133Zm7.947,0a9.334,9.334,0,0,1,.267,2.133h-3.2v-2.133Zm-2.88-1.067v-3.147a2.433,2.433,0,0,1,.747.374,5.278,5.278,0,0,1,1.227,1.386,5.525,5.525,0,0,1,.64,1.387Zm-2.987-1.387a5.274,5.274,0,0,1,1.227-1.386,3.2,3.2,0,0,1,.747-.374v3.147h-2.613A6.553,6.553,0,0,1,261.547,2820.88Zm1.92,2.454v2.133h-3.2a13.689,13.689,0,0,1,.267-2.133Zm-5.867,5.333a7.376,7.376,0,0,1-.533-2.133H259.2a13.714,13.714,0,0,0,.267,2.133Zm2.667-2.133h3.2v2.133h-2.88A13.71,13.71,0,0,1,260.267,2826.534Zm3.2,3.2v3.146a2.473,2.473,0,0,1-.747-.373,5.327,5.327,0,0,1-1.227-1.387,5.486,5.486,0,0,1-.64-1.386Zm2.987,1.386a5.33,5.33,0,0,1-1.227,1.387,3.289,3.289,0,0,1-.747.373v-3.146h2.613A6.516,6.516,0,0,1,266.453,2831.119Zm-1.92-2.452v-2.133h3.2a13.718,13.718,0,0,1-.267,2.133Zm4.267-3.2a13.685,13.685,0,0,0-.267-2.133H270.4a7.362,7.362,0,0,1,.533,2.133Zm1.067-3.2h-1.6a8.53,8.53,0,0,0-1.28-2.507,7.742,7.742,0,0,1,1.92,1.334A6.574,6.574,0,0,1,269.867,2822.267Zm-10.773-1.173a6.557,6.557,0,0,1,1.92-1.334,8.525,8.525,0,0,0-1.28,2.507h-1.6A6.582,6.582,0,0,1,259.093,2821.094Zm-.96,8.639h1.6a8.529,8.529,0,0,0,1.28,2.507,7.791,7.791,0,0,1-1.92-1.333A6.645,6.645,0,0,1,258.133,2829.733Zm10.773,1.173a6.577,6.577,0,0,1-1.92,1.333,8.533,8.533,0,0,0,1.28-2.507h1.6A6.636,6.636,0,0,1,268.907,2830.906Z"
      transform="translate(-38.498 -2447.166)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Path_7261"
      data-name="Path 7261"
      d="M330,960a6.018,6.018,0,0,0-6,6c0,6,6,10,6,10s6-4,6-10A6.018,6.018,0,0,0,330,960Zm0,7a2,2,0,1,1,2-2A2.006,2.006,0,0,1,330,967Z"
      transform="translate(-131.249 -596.166)"
      class="color-icon-fill-secondary"
    />
    <g id="Group_21691" data-name="Group 21691" transform="translate(-350.249 139.386)">
      <path
        id="Line_588"
        data-name="Line 588"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(547.5 250.741)"
        class="color-icon-fill-primary"
      />
      <path
        id="Line_589"
        data-name="Line 589"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(550.5 247.5)"
        class="color-icon-fill-primary"
      />
    </g>
    <g id="Group_21692" data-name="Group 21692" transform="translate(-129.043 -96.177) rotate(30)">
      <path
        id="Line_588-2"
        data-name="Line 588"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(547.5 250.741)"
        class="color-icon-fill-primary"
      />
      <path
        id="Line_589-2"
        data-name="Line 589"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(550.5 247.5)"
        class="color-icon-fill-primary"
      />
    </g>
    <g id="Group_21693" data-name="Group 21693" transform="translate(181.954 401.972) rotate(150)">
      <path
        id="Line_588-3"
        data-name="Line 588"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(0 0)"
        class="color-icon-fill-primary"
      />
      <path
        id="Line_589-3"
        data-name="Line 589"
        d="M0,8.872a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V8.372A.5.5,0,0,1,0,8.872Z"
        transform="translate(3 3.241)"
        class="color-icon-fill-primary"
      />
    </g>
    <path
      id="Path_8323"
      data-name="Path 8323"
      d="M581.738,1929.453l1.714,1.714-1.341,1.751a.616.616,0,0,1-.969.075l-1.192-1.192a.613.613,0,0,1,.075-.969Zm9.092-5.142-1.789,1.789-2.236-2.236,1.789-1.789a4.1,4.1,0,0,0-.671-.075,2.99,2.99,0,0,0-2.981,2.981,2.9,2.9,0,0,0,.484,1.6l-1.677,1.3-2.534-2.534L584.57,1922h-3.726l-1.64,1.64-.149-.149h-.82v.782l.149.149L576,1926.807l1.863,1.863,2.236-2.236,3.95,3.95,2.757,2.757,1.118-1.118-2.906-2.907,1.3-1.677a2.9,2.9,0,0,0,1.6.484,2.99,2.99,0,0,0,2.981-2.981A4.14,4.14,0,0,0,590.83,1924.31Z"
      transform="translate(-384.482 -1499.469)"
      class="color-icon-fill-secondary"
    />
  </g>
</svg>
<svg *ngIf="name === 'txt'" #icon xmlns="http://www.w3.org/2000/svg" width="32.001" height="36.938" viewBox="0 0 32.001 36.938">
  <g class="color-icon-fill-primary color-icon-stroke-primary" id="Txt" transform="translate(0.001)">
    <path
      id="Path_8379"
      data-name="Path 8379"
      d="M3.761,5.811,23.539,5.8l2.739,1.145,7.282,7.9V40.6l-1.62,1.361L3.761,41.494l-2.2-2.055.818-32.5Z"
      transform="translate(-1.561 -5.025)"
      class="color-icon-fill-secondary"
    />
    <path
      id="Path_1734"
      data-name="Path 1734"
      d="M1855.546,534.806l-5.13-5.13a8.032,8.032,0,0,0-4.885-2.076h-17.221a2.841,2.841,0,0,0-2.809,2.809v30.9a2.841,2.841,0,0,0,2.809,2.809h26.382a2.841,2.841,0,0,0,2.809-2.809V539.57A7.861,7.861,0,0,0,1855.546,534.806Zm-7.206-3.786.367.366,5.129,5.13.366.366h-5.862Zm6.962,30.412a.577.577,0,0,1-.611.611h-26.382a.577.577,0,0,1-.61-.611v-30.9a.577.577,0,0,1,.61-.611h17.832v9.16h9.16v22.351Z"
      transform="translate(-1825.5 -527.6)"
    />
    <g id="Group_439" data-name="Group 439" transform="translate(6.595 17.588)">
      <rect id="Rectangle_194" data-name="Rectangle 194" width="14.046" height="2.687" />
      <rect id="Rectangle_195" data-name="Rectangle 195" width="18.809" height="2.687" transform="translate(0 4.886)" />
      <rect id="Rectangle_196" data-name="Rectangle 196" width="18.809" height="2.687" transform="translate(0 9.893)" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'share-link'" #icon id="share-link" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
  <path
    class="color-icon-fill-primary"
    id="Path_30123"
    data-name="Path 30123"
    d="M16.333,3a2.652,2.652,0,0,0-2.6,3.224L7.674,9.251a2.665,2.665,0,1,0,0,3.5l6.051,3.026a2.672,2.672,0,1,0,.6-1.193L8.273,11.557a2.562,2.562,0,0,0,0-1.113l6.055-3.027A2.665,2.665,0,1,0,16.333,3Z"
    transform="translate(-3 -3)"
  />
</svg>
<svg
  *ngIf="name === 'link-chain'"
  #icon
  id="link-chain"
  xmlns="http://www.w3.org/2000/svg"
  width="20.541"
  height="20.469"
  viewBox="0 0 20.541 20.469"
>
  <path
    class="color-icon-fill-primary"
    id="Path_8265"
    data-name="Path 8265"
    d="M851.065,2817.482l-.064-.064a4.912,4.912,0,0,0-6.855,0l-4.357,4.356a4.912,4.912,0,0,0,0,6.855l.064.064a3.727,3.727,0,0,0,.513.448l1.6-1.6a3.545,3.545,0,0,1-.512-.385l-.064-.064a2.63,2.63,0,0,1,0-3.716l4.357-4.356a2.631,2.631,0,0,1,3.716,0l.064.064a2.629,2.629,0,0,1,0,3.716l-1.986,1.986a7.371,7.371,0,0,1,.513,2.627l3.075-3.075A4.975,4.975,0,0,0,851.065,2817.482Zm-6.471,6.342a3.667,3.667,0,0,0-.512-.448l-1.6,1.6a3.56,3.56,0,0,1,.513.385l.064.064a2.629,2.629,0,0,1,0,3.716L838.7,2833.5a2.629,2.629,0,0,1-3.716,0l-.064-.064a2.629,2.629,0,0,1,0-3.716l1.986-1.986a7.376,7.376,0,0,1-.512-2.627l-3.075,3.075a4.912,4.912,0,0,0,0,6.855l.064.064a4.912,4.912,0,0,0,6.855,0l4.356-4.356a4.912,4.912,0,0,0,0-6.855Z"
    transform="translate(-831.925 -2816.025)"
  />
</svg>
<svg *ngIf="name === 'sliders'" #icon xmlns="http://www.w3.org/2000/svg" width="18" height="17.652" viewBox="0 0 18 17.652">
  <g id="Group_21078" data-name="Group 21078" transform="translate(-93.202 -71.31)">
    <line
      id="Line_619"
      data-name="Line 619"
      y2="5.565"
      transform="translate(95.941 72.31)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_620"
      data-name="Line 620"
      y2="7.652"
      transform="translate(108.463 72.31)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_621"
      data-name="Line 621"
      y2="7.652"
      transform="translate(102.202 79.962)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_622"
      data-name="Line 622"
      y2="5.913"
      transform="translate(95.941 81.701)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_623"
      data-name="Line 623"
      y2="2.783"
      transform="translate(102.202 72.31)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_624"
      data-name="Line 624"
      y2="2.783"
      transform="translate(108.463 87.962) rotate(180)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_625"
      data-name="Line 625"
      x1="3.478"
      transform="translate(100.463 75.788)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_626"
      data-name="Line 626"
      x1="3.478"
      transform="translate(110.202 84.483) rotate(180)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_627"
      data-name="Line 627"
      x1="3.539"
      transform="translate(94.202 81.6)"
      fill="none"
      class="color-icon-stroke-primary"
      stroke-linecap="round"
      stroke-width="2"
    />
  </g>
</svg>
<svg *ngIf="name === 'people'" #icon xmlns="http://www.w3.org/2000/svg" width="17.772" height="16" viewBox="0 0 17.772 16">
  <path
    class="color-icon-fill-primary"
    id="Path_8375"
    data-name="Path 8375"
    d="M396.689,1551.335c-.332-.055-.332-.941-.332-.941a4.648,4.648,0,0,0,1.163-2.214c.554,0,.886-1.329.332-1.827,0-.5.72-3.931-2.824-3.931s-2.879,3.432-2.824,3.931c-.554.5-.221,1.827.332,1.827a4.648,4.648,0,0,0,1.163,2.214s0,.886-.332.941c-1.052.166-4.983,1.882-4.983,3.765h13.287C401.672,1553.218,397.741,1551.5,396.689,1551.335Zm-6.754.277a19.26,19.26,0,0,1,2.436-1.162,6.732,6.732,0,0,1-.775-1.55,1.967,1.967,0,0,1-.609-.886,3.091,3.091,0,0,1-.166-1.218,3.173,3.173,0,0,1,.221-.775,4.845,4.845,0,0,1,.886-3.543,3.017,3.017,0,0,1,1.495-.941c-.111-1.107-.664-2.436-2.879-2.436-3.543,0-2.879,3.432-2.824,3.931-.554.5-.221,1.827.332,1.827a4.649,4.649,0,0,0,1.163,2.215s0,.886-.332.941c-1.052.166-4.983,1.882-4.983,3.765h5.7Z"
    transform="translate(-383.9 -1539.1)"
  />
</svg>
<svg *ngIf="name === 'people-plus'" #icon xmlns="http://www.w3.org/2000/svg" width="17.772" height="16" viewBox="0 0 19.5 19.702">
  <g class="color-icon-fill-primary" id="Group_21888" data-name="Group 21888" transform="translate(9110.001 -1239.5)">
    <path
      id="Subtraction_2"
      data-name="Subtraction 2"
      d="M16,20.2H4.037c0-.92,1.027-1.744,1.888-2.274a9.05,9.05,0,0,1,2.6-1.116c.3-.049.3-.839.3-.847a4.18,4.18,0,0,1-1.047-1.994c-.182,0-.355-.166-.461-.443a1.156,1.156,0,0,1,.163-1.2c0-.024-.007-.056-.012-.1a4.638,4.638,0,0,1,.048-1.771A2.094,2.094,0,0,1,9.295,8.85a5.474,5.474,0,0,0,3.319,2.474,7.02,7.02,0,0,1-.038.789c-.009.094-.016.168-.016.214a1.156,1.156,0,0,1,.162,1.2c-.106.277-.279.443-.461.443a4.22,4.22,0,0,1-1.047,1.994c0,.007,0,.8.3.847a9.05,9.05,0,0,1,2.6,1.116C14.972,18.458,16,19.282,16,20.2ZM5.134,17.212H0c0-.92,1.027-1.745,1.888-2.274a9.033,9.033,0,0,1,2.6-1.115c.3-.049.3-.839.3-.847a4.18,4.18,0,0,1-1.047-1.994c-.182,0-.355-.166-.461-.443a1.157,1.157,0,0,1,.163-1.2c0-.025-.007-.057-.012-.1a3.491,3.491,0,0,1,.517-2.685A2.576,2.576,0,0,1,5.98,5.8,2.268,2.268,0,0,1,8.572,7.991a2.709,2.709,0,0,0-1.345.847,4.288,4.288,0,0,0-.8,3.191,2.832,2.832,0,0,0-.2.687l0,.011a2.778,2.778,0,0,0,.149,1.1,1.775,1.775,0,0,0,.549.8,6.013,6.013,0,0,0,.7,1.4,17.339,17.339,0,0,0-2.193,1.047l-.3.149Z"
      transform="translate(-9110 1239)"
    />
    <path
      id="Path_8464"
      data-name="Path 8464"
      d="M823.737,6798.622l1.543,1.536,2.867-3.733"
      transform="translate(-9921.942 -5553.292)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
    <g id="Group_21889" data-name="Group 21889" transform="translate(-0.501 0.5)">
      <circle id="Ellipse_529" data-name="Ellipse 529" cx="5" cy="5" r="5" transform="translate(-9100 1239)" fill="#6abf4b" />
      <path
        id="Path_8489"
        data-name="Path 8489"
        d="M823.737,6798.622l1.543,1.536,2.867-3.733"
        transform="translate(-9920.942 -5554.292)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'shape-exclamation'" id="shape-exclamation" #icon xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 52 52">
  <path
    d="M16 32c-2.133 0-3.947-1.813-3.947-3.947s1.813-3.947 3.947-3.947 3.947 1.813 3.947 3.947c0.213 2.133-1.6 3.947-3.947 3.947zM18.88 19.947h-5.547l-1.28-16c0-2.133 1.813-3.947 3.947-3.947s3.947 1.813 3.947 3.947l-1.067 16z"
  ></path>
</svg>
<svg *ngIf="name === 'folder-empty'" #icon xmlns="http://www.w3.org/2000/svg" width="37.502" height="28.506" viewBox="0 0 37.502 28.506">
  <g id="Group_21856" data-name="Group 21856" transform="translate(-180 -408.494)">
    <g id="Group_18531" data-name="Group 18531" transform="translate(180 408.494)">
      <path
        class="color-icon-fill-secondary"
        id="Path_7273"
        data-name="Path 7273"
        d="M7.2,16.1.831,32.267H30.265L36.952,16.1Z"
        transform="translate(-0.24 -4.657)"
      />
      <path
        class="color-icon-fill-primary"
        id="Path_7271"
        data-name="Path 7271"
        d="M37.383,46.962a.686.686,0,0,0-.567-.3H30.99V41.746a.686.686,0,0,0-.686-.686H15.561V36.774a.686.686,0,0,0-.686-.686H.686A.686.686,0,0,0,0,36.774V63.908a.693.693,0,0,0,0,.074s0,.009,0,.014c0,.021.006.042.011.063l.005.02c0,.018.01.037.017.055l.007.02q.011.028.024.056l.008.016a.689.689,0,0,0,.04.068h0l0,0a.655.655,0,0,0,.044.056l.017.018.037.037.019.017q.022.019.046.037l.014.01a.685.685,0,0,0,.066.04l.009,0c.02.01.04.019.061.028l.019.007.055.017.022.006.057.011.021,0a.716.716,0,0,0,.079,0H30.3a.686.686,0,0,0,.638-.435L37.455,47.6A.685.685,0,0,0,37.383,46.962ZM1.372,37.46H14.189v4.286a.686.686,0,0,0,.686.686H29.618v4.23H7.2a.686.686,0,0,0-.638.435L1.372,60.29ZM29.836,63.222H1.693L7.666,48.034H35.81Z"
        transform="translate(0 -36.088)"
      />
    </g>
  </g>
</svg>
<svg
  *ngIf="name === 'map-marker'"
  #icon
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 30 30"
  style="enable-background: new 0 0 30 30"
  xml:space="preserve"
>
  <image
    style="overflow: visible; opacity: 0.35"
    width="27"
    height="35"
    xlink:href="19B51434A51B46F.png"
    transform="matrix(1 0 0 1 2.6534 -1.3466)"
  ></image>
  <g>
    <path
      class="color-icon-fill-primary"
      d="M14.9,30.2c-0.1-0.1-11.4-7.7-11.4-19C3.5,4.9,8.7-0.3,15-0.3s11.5,5.2,11.5,11.5c0,11.3-11.3,18.9-11.4,19
      L15,30.3L14.9,30.2z M15,6.2c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S17.2,6.2,15,6.2z"
    />
    <path
      class="color-icon-fill-primary"
      d="M15,0c6.2,0,11.2,5,11.2,11.2C26.2,22.5,15,30,15,30S3.8,22.5,3.8,11.2C3.8,5,8.8,0,15,0 M15,14.4
      c2.3,0,4.2-1.9,4.2-4.2c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2C10.8,12.5,12.7,14.4,15,14.4 M15-0.5C8.5-0.5,3.2,4.8,3.2,11.2
      c0,11.4,11,18.9,11.5,19.2l0.3,0.2l0.3-0.2c0.5-0.3,11.5-7.8,11.5-19.2C26.8,4.8,21.5-0.5,15-0.5L15-0.5z M15,13.8
      c-2,0-3.7-1.7-3.7-3.7S13,6.4,15,6.4s3.7,1.7,3.7,3.7S17,13.8,15,13.8L15,13.8z"
    />
  </g>
</svg>
<svg
  *ngIf="name === 'temporal'"
  #icon
  version="1.1"
  id="Temporal_index_icon"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 480 480"
  style="enable-background: new 0 0 480 480"
  xml:space="preserve"
>
  <g>
    <path
      class="color-icon-fill-primary"
      d="M240,420c-25,0-48.4-4.8-70.2-14.2s-40.8-22.3-57-38.5c-16.2-16.2-29-35.2-38.5-57S60,265,60,240
      s4.8-48.4,14.2-70.2s22.3-40.8,38.5-57s35.2-29,57-38.5S215,60,240,60c27.3,0,53.2,5.8,77.8,17.5S363,105.7,380,127V80h40v120H300
      v-40h55c-13.7-18.7-30.5-33.3-50.5-44S263,100,240,100c-39,0-72.1,13.6-99.2,40.8S100,201,100,240s13.6,72.1,40.8,99.2
      C167.9,366.4,201,380,240,380c35,0,65.6-11.3,91.8-34c26.2-22.7,41.6-51.3,46.2-86h41c-5,45.7-24.6,83.8-58.8,114.2
      S286,420,240,420z M296,324l-76-76V140h40v92l64,64L296,324z"
    />
  </g>
</svg>
<svg
  *ngIf="name === 'atemporal'"
  #icon
  version="1.1"
  id="Temporal_index_icon"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 576 576"
  style="enable-background: new 0 0 576 576"
  xml:space="preserve"
>
  <path
    class="color-icon-fill-primary"
    d="M355.2,388.8L264,297.6V168h48v110.4l76.8,76.8L355.2,388.8z M456,96v56.4c-20.4-25.6-45.3-45.4-74.7-59.4
	c-29.4-14-60.5-21-93.3-21c-30,0-58.1,5.7-84.3,17.1c-26.2,11.4-49,26.8-68.4,46.2c-19.4,19.4-34.8,42.2-46.2,68.4
	C77.7,229.9,72,258,72,288s5.7,58.1,17.1,84.3c11.4,26.2,26.8,49,46.2,68.4c19.4,19.4,42.2,34.8,68.4,46.2
	C229.9,498.3,258,504,288,504c25.5,0,49.5-3.9,72-11.7v-51.9c-22,10.5-46,15.7-72,15.7c-46.8,0-86.5-16.3-119.1-48.9
	C136.3,374.5,120,334.8,120,288s16.3-86.5,48.9-119.1C201.5,136.3,241.2,120,288,120c27.6,0,53.4,6.4,77.4,19.2s44.2,30.4,60.6,52.8
	h-66v48h144V96H456z M432,480c-6.8,0-12.5-2.3-17.1-6.9S408,462.8,408,456v-72c0-6.8,2.3-12.5,6.9-17.1s10.3-6.9,17.1-6.9v-24
	c0-13.2,4.7-24.5,14.1-33.9c9.4-9.4,20.7-14.1,33.9-14.1s24.5,4.7,33.9,14.1c9.4,9.4,14.1,20.7,14.1,33.9v24
	c6.8,0,12.5,2.3,17.1,6.9c4.6,4.6,6.9,10.3,6.9,17.1v72c0,6.8-2.3,12.5-6.9,17.1c-4.6,4.6-10.3,6.9-17.1,6.9H432z M456,360h48v-24
	c0-6.8-2.3-12.5-6.9-17.1S486.8,312,480,312s-12.5,2.3-17.1,6.9S456,329.2,456,336V360z"
  />
</svg>

<svg *ngIf="name === 'cross-black'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 10.828 10.828">
  <g id="Group_21454" data-name="Group 21454" transform="translate(16814.414 21446.914)">
    <line
      id="Line_692"
      data-name="Line 692"
      x2="8"
      y2="8"
      transform="translate(-16813 -21445.5)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-width="2"
    />
    <line
      id="Line_693"
      data-name="Line 693"
      x1="8"
      y2="8"
      transform="translate(-16813 -21445.5)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-width="2"
    />
  </g>
</svg>

<!-- Error Feedback Icon -->
<svg *ngIf="name === 'error-feedback'" #icon xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 23.932 23.907">
  <path
    id="Path_855"
    data-name="Path 855"
    d="M407.6,3413.367l-10.235-20.321a1.486,1.486,0,0,0-2.839,0l-10.235,20.321c-.747,1.419-.149,2.54,1.494,2.54h20.321C407.676,3415.907,408.349,3414.786,407.6,3413.367Zm-11.655-.449a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,395.947,3412.918Zm1.046-4.482H394.9l-.448-5.977a1.494,1.494,0,0,1,2.988,0Z"
    transform="translate(-383.974 -3392)"
    fill="#002d61"
  />
</svg>

<!-- Data Accuracy Icon -->
<svg *ngIf="name === 'data-accuracy'" #icon xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 21 24">
  <path
    id="Path_894"
    data-name="Path 894"
    d="M340,2627h0v18H325v-18h-3v18a3.009,3.009,0,0,0,3,3h15a3.009,3.009,0,0,0,3-3v-18Zm-13.5,4.5h3v3h-3Zm4.5,0h7.5v3H331Zm-4.5,4.5h3v3h-3Zm4.5,0h7.5v3H331Zm-4.5,4.5h3v3h-3Zm4.5,0h7.5v3H331Zm4.5-13.5v-1.5a1.5,1.5,0,0,0-1.5-1.5h-3a1.5,1.5,0,0,0-1.5,1.5v1.5h-3v3h12v-3Zm-1.5,0h-3v-1.5h3Z"
    transform="translate(-322 -2624)"
    fill="#002d61"
  />
</svg>

<!-- Location Icon -->
<svg *ngIf="name === 'location'" #icon xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 14.942 23.907">
  <path
    id="Path_244"
    data-name="Path 244"
    d="M205.471,960A7.493,7.493,0,0,0,198,967.471c0,7.471,7.471,16.436,7.471,16.436s7.471-8.965,7.471-16.436A7.493,7.493,0,0,0,205.471,960Zm0,12.028a4.557,4.557,0,1,1,4.557-4.557A4.528,4.528,0,0,1,205.471,972.028Zm-2.914-4.557a2.914,2.914,0,1,0,2.914-2.914,2.967,2.967,0,0,0-2.914,2.914Z"
    transform="translate(-198 -960)"
    fill="#002d61"
  />
</svg>

<!-- Site IDs Need to be Unique Icon -->
<svg
  *ngIf="name === 'site-ids-need-to-be-unique'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="40px"
  height="40px"
  viewBox="0 0 23.905 23.907"
>
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_4359" data-name="Rectangle 4359" width="100%" height="100%" fill="none" />
    </clipPath>
  </defs>
  <g id="Group_18430" data-name="Group 18430" clip-path="url(#clip-path)">
    <path
      id="Path_889"
      data-name="Path 889"
      d="M0,206.827a1.086,1.086,0,0,1,1.176-.741c2.916.013,5.832.006,8.748.006a.978.978,0,0,1,1.126,1.125c0,2.916-.007,5.832.006,8.748a1.089,1.089,0,0,1-.741,1.177H.747A1.092,1.092,0,0,1,0,216.4Zm1.875,8.433H9.165v-7.285H1.875Z"
      transform="translate(0 -193.235)"
      fill="#002d61"
    />
    <path
      id="Path_890"
      data-name="Path 890"
      d="M211.227,217.373c-.459-.11-.931-.181-1.374-.335a5.517,5.517,0,1,1,2.833.247c-.16.027-.319.058-.479.088Zm4.154-5.517a3.657,3.657,0,1,0-3.657,3.65,3.668,3.668,0,0,0,3.657-3.65"
      transform="translate(-193.351 -193.467)"
      fill="#002d61"
    />
    <path
      id="Path_891"
      data-name="Path 891"
      d="M94.766,11.06q-2.695,0-5.389,0a.954.954,0,0,1-1.028-.731,1.014,1.014,0,0,1,.172-.8Q90.064,6.966,91.6,4.4,92.766,2.458,93.93.52A.892.892,0,0,1,95.12.095a1.4,1.4,0,0,1,.52.506q2.12,3.5,4.215,7.011c.388.646.778,1.291,1.163,1.939a.942.942,0,0,1-.862,1.508q-2.695.005-5.389,0m0-8.3L90.914,9.177h7.7L94.765,2.757"
      transform="translate(-82.816 0)"
      fill="#002d61"
    />
    <rect
      id="Rectangle_4358"
      data-name="Rectangle 4358"
      width="7.291"
      height="7.285"
      transform="translate(1.875 14.74)"
      fill="#fff"
      stroke="#002d61"
      stroke-width="3"
    />
    <path
      id="Path_892"
      data-name="Path 892"
      d="M243.312,239.8a3.657,3.657,0,1,1-3.643-3.666,3.663,3.663,0,0,1,3.643,3.666"
      transform="translate(-221.281 -221.408)"
      fill="#fff"
      stroke="#002d61"
      stroke-width="3"
    />
    <path
      id="Path_893"
      data-name="Path 893"
      d="M133.725,44.219l3.851,6.419h-7.7l3.851-6.419"
      transform="translate(-121.776 -41.462)"
      fill="#fff"
      stroke="#002d61"
      stroke-width="2"
    />
  </g>
</svg>

<!-- Download Template Icon -->
<svg *ngIf="name === 'download-template'" #icon xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 19.424 23.907">
  <path
    id="Path_130"
    data-name="Path 130"
    d="M84.079,516.632a4.955,4.955,0,0,1,1.27,2.839h-7.4V512a6.167,6.167,0,0,1,2.839,1.27Zm-7.62,4.333V512H67.868A1.85,1.85,0,0,0,66,513.868v20.171a1.85,1.85,0,0,0,1.868,1.868H83.557a1.85,1.85,0,0,0,1.868-1.868V520.965Zm-.747,13.448-5.977-5.977h3.735v-4.483h4.483v4.483h3.735Z"
    transform="translate(-66 -512)"
    fill="#002d61"
  />
</svg>

<!-- Drop File Here Icon -->
<svg *ngIf="name === 'drop-file-here'" #icon xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 36.74 45.218">
  <path
    id="Path_7"
    data-name="Path 7"
    d="M164.2,520.761a9.371,9.371,0,0,1,2.4,5.37H152.468V512a11.664,11.664,0,0,1,5.37,2.4Zm-14.413,8.2V512h-16.25A3.5,3.5,0,0,0,130,515.533v38.153a3.5,3.5,0,0,0,3.533,3.533h29.674a3.5,3.5,0,0,0,3.533-3.533V528.957Zm2.826,16.957v8.478h-8.478v-8.478h-7.065l11.3-11.3,11.3,11.3Z"
    transform="translate(-130 -512)"
    fill="#002d61"
  />
</svg>

<!-- Site Upload Wizard-->
<svg
  *ngIf="name === 'site-upload-notification-wizard-icon'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="26.463"
  height="26.525"
  viewBox="0 0 26.463 26.525"
>
  <path
    id="Path_8022"
    data-name="Path 8022"
    d="M774.621,1924.966l-3.31-3.31h-1.655v1.655l3.31,3.311Zm1.655-4.966h1.655v3.31h-1.655Zm6.621,8.276h3.31v1.655H782.9Zm1.655-4.966v-1.655H782.9l-3.31,3.31,1.655,1.655ZM768,1928.276h3.31v1.655H768Zm8.276,6.621h1.655v3.311h-1.655Zm-6.621,0v1.655h1.655l3.31-3.311-1.655-1.655Zm24.5,7.945-16.469-16.469a1.2,1.2,0,0,0-1.738,0l-1.573,1.573a1.2,1.2,0,0,0,0,1.738l16.469,16.469a1.2,1.2,0,0,0,1.738,0l1.573-1.572A1.371,1.371,0,0,0,794.153,1942.842Zm-13.738-8.773-4.966-4.966,1.655-1.655,4.966,4.966Z"
    transform="translate(-768 -1920)"
    fill="#fff"
  />
</svg>

<!--Site Upload Delete icon-->
<svg
  *ngIf="name === 'site-upload-delete-icon'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="18.417"
  height="23.021"
  viewBox="0 0 18.417 23.021"
>
  <path
    id="Path_594"
    data-name="Path 594"
    d="M81.813,2377.674h3.069v13.045a2.309,2.309,0,0,1-2.3,2.3H71.837a2.309,2.309,0,0,1-2.3-2.3v-13.045H72.6v12.278h3.069v-12.278h3.069v12.278h3.069ZM68,2373.07H86.417v3.069H68Zm6.139-3.07h6.139v2.3H74.139Z"
    transform="translate(-68 -2370)"
    fill="#707070"
  />
</svg>

<!--Site Upload Blue Arrow icon-->
<svg
  *ngIf="name === 'site-upload-blue-arrow'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="33.941"
  height="33.941"
  viewBox="0 0 33.941 33.941"
>
  <path
    id="Path_7308"
    data-name="Path 7308"
    d="M154,3844H142a2,2,0,0,0,0,4h7.2l-16.6,16.6a1.933,1.933,0,0,0,0,2.8,1.933,1.933,0,0,0,2.8,0l16.6-16.6v7.2a2,2,0,0,0,4,0v-14Z"
    transform="translate(2641.751 -2811.457) rotate(45)"
    fill="#2671cb"
  />
</svg>

<!--Site Upload Grey Arrow icon-->
<svg
  *ngIf="name === 'site-upload-grey-arrow'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="33.941"
  height="33.941"
  viewBox="0 0 33.941 33.941"
>
  <path
    id="Path_963"
    data-name="Path 963"
    d="M154,3844H142a2,2,0,0,0,0,4h7.2l-16.6,16.6a1.933,1.933,0,0,0,0,2.8,1.933,1.933,0,0,0,2.8,0l16.6-16.6v7.2a2,2,0,0,0,4,0v-14Z"
    transform="translate(2641.751 -2811.457) rotate(45)"
    fill="#707070"
  />
</svg>

<!--Site Upload Warning Icon-->
<svg
  *ngIf="name === 'site-upload-warning-error-msg-icon'"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
>
  <path
    id="Path_857"
    data-name="Path 857"
    d="M523,3392a11,11,0,1,0,11,11A11.032,11.032,0,0,0,523,3392Zm1.375,17.875h-2.75v-2.75h2.75Zm0-5.5h-2.75v-8.25h2.75Z"
    transform="translate(-512 -3392)"
    fill="#f47721"
  />
</svg>

<svg *ngIf="name === 'left-arrow-caraousel'" #icon xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
  <defs>
    <filter id="Ellipse_8" x="0" y="0" width="57" height="57" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha" />
      <feGaussianBlur stdDeviation="3" result="blur" />
      <feFlood flood-opacity="0.161" />
      <feComposite operator="in" in2="blur" />
      <feComposite in="SourceGraphic" />
    </filter>
  </defs>
  <g id="Group_20347" data-name="Group 20347" transform="translate(9 6)">
    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Ellipse_8)">
      <circle id="Ellipse_8-2" data-name="Ellipse 8" cx="19.5" cy="19.5" r="19.5" transform="translate(9 6)" fill="#fff" />
    </g>
    <path
      id="Path_964"
      data-name="Path 964"
      d="M200.057,3857.372l-5.714-5.714a1.1,1.1,0,0,1,0-1.6l5.714-5.715a1.131,1.131,0,0,1,1.6,1.6l-3.771,3.771h10.971a1.143,1.143,0,0,1,0,2.286H197.886l3.771,3.772a1.1,1.1,0,0,1,0,1.6A1.1,1.1,0,0,1,200.057,3857.372Z"
      transform="translate(-183 -3831)"
      fill="#404040"
    />
  </g>
</svg>

<svg *ngIf="name === 'right-arrow-caraousel'" #icon xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
  <defs>
    <filter id="Ellipse_8" x="0" y="0" width="57" height="57" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha" />
      <feGaussianBlur stdDeviation="3" result="blur" />
      <feFlood flood-opacity="0.161" />
      <feComposite operator="in" in2="blur" />
      <feComposite in="SourceGraphic" />
    </filter>
  </defs>
  <g id="Group_20347" data-name="Group 20347" transform="translate(-1420 -453)">
    <g transform="matrix(1, 0, 0, 1, 1420, 453)" filter="url(#Ellipse_8)">
      <circle id="Ellipse_8-2" data-name="Ellipse 8" cx="19.5" cy="19.5" r="19.5" transform="translate(9 6)" fill="#fff" />
    </g>
    <path
      id="Path_964"
      data-name="Path 964"
      d="M203.943,3857.372l5.714-5.714a1.1,1.1,0,0,0,0-1.6l-5.714-5.715a1.131,1.131,0,0,0-1.6,1.6l3.771,3.771H195.143a1.143,1.143,0,0,0,0,2.286h10.971l-3.771,3.772a1.1,1.1,0,0,0,0,1.6A1.1,1.1,0,0,0,203.943,3857.372Z"
      transform="translate(1247 -3372)"
      fill="#404040"
    />
  </g>
</svg>

<!--Site Uploads Arrow Icon Left-->
<svg *ngIf="name === 'arrow-icon-left'" #icon xmlns="http://www.w3.org/2000/svg" width="6.633" height="10" viewBox="0 0 6.633 10">
  <path
    id="Path_8364"
    data-name="Path 8364"
    d="M5,0a1,1,0,0,0-.757.346l-4,4.633A1,1,0,0,0,1.757,6.286L5,2.53,8.243,6.286A1,1,0,1,0,9.757,4.979l-4-4.633A1,1,0,0,0,5,0Z"
    transform="translate(0 10) rotate(-90)"
    fill="#fff"
  />
</svg>

<!--Site Uploads Arrow Icon Right-->
<svg *ngIf="name === 'arrow-icon-right'" #icon xmlns="http://www.w3.org/2000/svg" width="6.633" height="10" viewBox="0 0 6.633 10">
  <path
    id="Path_8364"
    data-name="Path 8364"
    d="M5,6.633a1,1,0,0,1-.757-.346l-4-4.633A1,1,0,0,1,1.757.346L5,4.1,8.243.346A1,1,0,1,1,9.757,1.654l-4,4.633A1,1,0,0,1,5,6.633Z"
    transform="translate(0 10) rotate(-90)"
    fill="#fff"
  />
</svg>

<svg
  *ngIf="name === 'exclamation-circle-border'"
  class="circle-border"
  #icon
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 16 16"
>
  <g id="Group_21774" data-name="Group 21774" transform="translate(9517 -2558) rotate(180)">
    <g id="Path_8462" data-name="Path 8462" transform="translate(9501 -2574)" class="color-icon-fill-primary">
      <path
        d="M 8 15.5 C 3.864490032196045 15.5 0.5 12.13551044464111 0.5 8 C 0.5 3.864490032196045 3.864490032196045 0.5 8 0.5 C 12.13551044464111 0.5 15.5 3.864490032196045 15.5 8 C 15.5 12.13551044464111 12.13551044464111 15.5 8 15.5 Z"
        stroke="none"
      />
      <path
        d="M 8 1 C 4.140190124511719 1 1 4.140190124511719 1 8 C 1 11.85980987548828 4.140190124511719 15 8 15 C 11.85980987548828 15 15 11.85980987548828 15 8 C 15 4.140190124511719 11.85980987548828 1 8 1 M 8 0 C 12.41827964782715 0 16 3.581720352172852 16 8 C 16 12.41827964782715 12.41827964782715 16 8 16 C 3.581720352172852 16 0 12.41827964782715 0 8 C 0 3.581720352172852 3.581720352172852 0 8 0 Z"
        stroke="none"
        class="color-icon-fill-primary"
      />
    </g>
    <path
      id="Line_640"
      data-name="Line 640"
      d="M0,4A1,1,0,0,1-1,3V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V3A1,1,0,0,1,0,4Z"
      transform="translate(9509 -2566)"
      class="color-icon-fill-secondary"
    />
    <path id="Line_641" data-name="Line 641" transform="translate(9509 -2569)" class="color-icon-fill-secondary" />
    <circle
      id="Ellipse_523"
      data-name="Ellipse 523"
      cx="1.022"
      cy="1.022"
      r="1.022"
      transform="translate(9507.956 -2570.263)"
      class="color-icon-fill-secondary"
    />
  </g>
</svg>

<svg *ngIf="name === 'active-bell'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.342 16.7">
  <g id="Group_18608" data-name="Group 18608" transform="translate(-1678.315 -233.3)">
    <path
      id="Path_6237"
      data-name="Path 6237"
      d="M848.108,1037.087a3.029,3.029,0,0,1-3.02-3.02v-4.48a4.671,4.671,0,0,0-4.027-4.48V1024h-2.013v1.107a4.671,4.671,0,0,0-4.027,4.48v4.48a3.029,3.029,0,0,1-3.02,3.02v1.007h6.745a1.34,1.34,0,0,0-.151.6,1.407,1.407,0,0,0,1.409,1.409,1.44,1.44,0,0,0,1.409-1.409,1.341,1.341,0,0,0-.151-.6h6.846Z"
      transform="translate(846.315 -790.107)"
      class="color-icon-fill-primary"
    />
    <path
      id="notifications_active_FILL0_wght400_GRAD0_opsz24_1_"
      data-name="notifications_active_FILL0_wght400_GRAD0_opsz24 (1)"
      d="M80-875.422a7.844,7.844,0,0,1,.909-3.748A8.3,8.3,0,0,1,83.35-882l.96,1.307a6.609,6.609,0,0,0-1.951,2.267,6.314,6.314,0,0,0-.725,3Zm14.708,0a6.314,6.314,0,0,0-.725-3,6.609,6.609,0,0,0-1.951-2.267l.96-1.307a8.3,8.3,0,0,1,2.441,2.829,7.844,7.844,0,0,1,.909,3.748Z"
      transform="translate(1598.315 1115.3)"
      class="color-icon-fill-primary"
    />
  </g>
</svg>

<svg *ngIf="name === 'bell-cog'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 22.134 15.955">
  <g id="Group_18467" data-name="Group 18467" transform="translate(-1676 -176)">
    <g id="Group_18457" data-name="Group 18457" transform="translate(1676 176)">
      <path
        id="Path_269"
        data-name="Path 269"
        d="M783.725,1036.776a2.957,2.957,0,0,1-2.948-2.948v-4.374a4.56,4.56,0,0,0-3.931-4.373V1024H774.88v1.081a4.56,4.56,0,0,0-3.931,4.373v4.374a2.957,2.957,0,0,1-2.948,2.948v.983h6.585a1.31,1.31,0,0,0-.147.59,1.374,1.374,0,0,0,1.376,1.376,1.406,1.406,0,0,0,1.376-1.376,1.308,1.308,0,0,0-.147-.59h6.683Z"
        transform="translate(-768 -1024)"
        class="color-icon-fill-primary"
      />
      <path
        id="Path_482"
        data-name="Path 482"
        d="M75.817,1927.016V1924.8l-1.7-.3a2.86,2.86,0,0,0-.258-.591l1-1.4-1.551-1.551-1.4,1a4.11,4.11,0,0,0-.591-.258l-.3-1.7H68.8l-.3,1.7a2.862,2.862,0,0,0-.591.258l-1.4-1-1.551,1.551,1,1.4a4.11,4.11,0,0,0-.258.591l-1.7.3v2.216l1.7.3a2.859,2.859,0,0,0,.258.591l-1,1.4,1.551,1.551,1.4-1a4.1,4.1,0,0,0,.591.258l.3,1.7h2.216l.3-1.7a2.855,2.855,0,0,0,.591-.258l1.4,1,1.551-1.551-1-1.4a4.1,4.1,0,0,0,.258-.591Zm-5.908.369a1.477,1.477,0,1,1,1.477-1.477A1.481,1.481,0,0,1,69.908,1927.386Z"
        transform="translate(-54.184 -1916.363)"
        class="color-icon-fill-primary color-icon-stroke-secondary"
        stroke-width="1"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'add-bell'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.5 16.5">
  <path
    id="Path_5362"
    data-name="Path 5362"
    d="M848.5,1037.406a3.1,3.1,0,0,1-3.094-3.094v-4.589a4.785,4.785,0,0,0-4.125-4.589V1024h-2.062v1.134a4.785,4.785,0,0,0-4.125,4.589v4.589a3.1,3.1,0,0,1-3.094,3.094v1.031h6.909a1.373,1.373,0,0,0-.155.619,1.441,1.441,0,0,0,1.444,1.444,1.476,1.476,0,0,0,1.444-1.444,1.374,1.374,0,0,0-.155-.619H848.5Zm-5.156-4.125h-2.062v2.062h-2.062v-2.062h-2.062v-2.063h2.063v-2.062h2.063v2.063h2.063Z"
    transform="translate(-832 -1024)"
    class="color-icon-fill-primary"
  />
</svg>

<svg *ngIf="name === 'bell'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.92 28.92">
  <g id="Group_18613" data-name="Group 18613" transform="translate(0 2.999)">
    <g id="Group_18457" data-name="Group 18457">
      <path
        id="Path_269"
        data-name="Path 269"
        d="M793.92,1045.06a4.874,4.874,0,0,1-4.86-4.86v-7.209a7.517,7.517,0,0,0-6.48-7.209V1024h-3.24v1.782a7.517,7.517,0,0,0-6.48,7.209v7.209a4.874,4.874,0,0,1-4.86,4.86v1.62h10.854a2.16,2.16,0,0,0-.243.972,2.264,2.264,0,0,0,2.268,2.268,2.318,2.318,0,0,0,2.268-2.268,2.157,2.157,0,0,0-.243-.972H793.92Z"
        transform="translate(-768 -1024)"
        class="color-icon-fill-primary"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'bell-busy'" #icon xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 25.92 28.92">
  <g id="Group_18613" data-name="Group 18613" transform="translate(0 2.999)">
    <g id="Group_18457" data-name="Group 18457">
      <path
        id="Path_269"
        data-name="Path 269"
        d="M793.92,1045.06a4.874,4.874,0,0,1-4.86-4.86v-7.209a7.517,7.517,0,0,0-6.48-7.209V1024h-3.24v1.782a7.517,7.517,0,0,0-6.48,7.209v7.209a4.874,4.874,0,0,1-4.86,4.86v1.62h10.854a2.16,2.16,0,0,0-.243.972,2.264,2.264,0,0,0,2.268,2.268,2.318,2.318,0,0,0,2.268-2.268,2.157,2.157,0,0,0-.243-.972H793.92Z"
        transform="translate(-768 -1024)"
        class="color-icon-fill-primary"
      />
    </g>
  </g>
  <g
    id="Ellipse_220"
    data-name="Ellipse 220"
    transform="translate(13.96)"
    fill="#d92a1c"
    class="color-icon-stroke-secondary"
    stroke-width="1"
  >
    <circle cx="5" cy="5" r="5" stroke="none" />
    <circle cx="5" cy="5" r="4.5" fill="none" />
  </g>
</svg>
