export enum WrittenContentLinkActionsType {
  NAVIGATE_TO_PORTAL_ROUTE = '[Written Content] Navigate to a GRiD route in the same tab',
  OPEN_PORTAL_ROUTE_IN_NEW_TAB = '[Written Content] Open a GRiD route in a new tab',
  OPEN_EXTERNAL_LINK = '[Written Content] Open an external link in a new tab',
  SHOW_INDEX_INFORMATION = '[Written Content] Show Risk Index Information',
}

export interface WrittenContentLinkPortalNavigationPayload {
  pathSegments: string;
  optionalRouteSpecificParams: Record<string, string>;
}

export interface WrittenContentLinkNewTabLinkPayload {
  url: string;
}

export interface WrittenContentLinkRiskIndexInformationPayload {
  /** The unique ID of the risk index for which information should be shown. */
  id: string;
}

/** Action to request navigation to a GRiD route using the same browser tab */
export class WrittenContentLinkNavigateToPortalRouteAction {
  public readonly type = WrittenContentLinkActionsType.NAVIGATE_TO_PORTAL_ROUTE;
  public constructor(public payload: WrittenContentLinkPortalNavigationPayload) {}
}

/** Action to request opening the specified GRiD route in a new browser tab */
export class WrittenContentLinkOpenPortalRouteAction {
  public readonly type = WrittenContentLinkActionsType.OPEN_PORTAL_ROUTE_IN_NEW_TAB;
  public constructor(public payload: WrittenContentLinkNewTabLinkPayload) {}
}

/** Action to request opening an external link in a new browser tab */
export class WrittenContentLinkOpenExternalLinkAction {
  public readonly type = WrittenContentLinkActionsType.OPEN_EXTERNAL_LINK;
  public constructor(public payload: WrittenContentLinkNewTabLinkPayload) {}
}

/** Action to request showing of the Risk Index Methodology Modal */
export class WrittenContentLinkRiskIndexInformationAction {
  public readonly type = WrittenContentLinkActionsType.SHOW_INDEX_INFORMATION;
  public constructor(public payload: WrittenContentLinkRiskIndexInformationPayload) {}
}

/** Union type of written content link actions */
export type WrittenContentLinkActionsUnion =
  | WrittenContentLinkNavigateToPortalRouteAction
  | WrittenContentLinkOpenPortalRouteAction
  | WrittenContentLinkOpenExternalLinkAction
  | WrittenContentLinkRiskIndexInformationAction;
