import { Component, ViewEncapsulation } from '@angular/core';
import { BrowserStorageService } from '@grid-ui/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { GlobalModuleAnnouncementFacade } from '../../../data-access/+state/global-module-announcement.facade';
import { AnnouncementVM } from '../../../data-access/+state/global-module-announcement.reducer';

@Component({
  selector: 'grid-ui-announcements-dialog',
  templateUrl: './announcements-dialog.component.html',
  styleUrls: ['./announcements-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnnouncementsDialogComponent {
  announcements: AnnouncementVM[] = [];
  currentAnnouncementIndex = 0;
  isChecked = false;

  public constructor(
    private readonly activeModal: NgbActiveModal,
    private browserStorageService: BrowserStorageService,
    private facade: GlobalModuleAnnouncementFacade,
  ) {
    this.facade.undissmissedAnnouncements$.pipe(take(1)).subscribe((announcements: AnnouncementVM[]) => {
      this.announcements = [...announcements];
    });
  }

  get announcement(): AnnouncementVM {
    return this.announcements[this.currentAnnouncementIndex];
  }

  public toggle(announcement: AnnouncementVM): void {
    this.announcements = this.announcements.map((a) => (a.id === announcement.id ? { ...a, isDismissed: !a.isDismissed } : a));
  }

  public cancel() {
    this.activeModal.close();
  }

  public dismiss(): void {
    if (this.announcement.isDismissed) {
      this.browserStorageService.addToArrayLocalStorage('dismissedItems', this.announcement.id);
      const ids = this.browserStorageService.getLocal('dismissedItems');
      if (ids) {
        this.facade.dismissAnnoucments(ids as any);
      }
    } else {
      this.browserStorageService.addToArray('dismissedSessionItems', this.announcement.id);
    }

    this.announcements = this.announcements.filter((a) => a.id !== this.announcement.id);

    if (this.announcements.length === 0) {
      this.activeModal.close();
    } else {
      this.nextAnnouncement();
    }
  }

  previousAnnouncement() {
    this.currentAnnouncementIndex = this.currentAnnouncementIndex > 0 ? this.currentAnnouncementIndex - 1 : this.announcements.length - 1;
  }

  nextAnnouncement() {
    this.currentAnnouncementIndex = this.currentAnnouncementIndex < this.announcements.length - 1 ? this.currentAnnouncementIndex + 1 : 0;
  }

  hasPreviousAnnouncement(): boolean {
    return this.announcements.length > 1;
  }

  hasNextAnnouncement(): boolean {
    return this.announcements.length > 1;
  }
}
