import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';

/**
 * Basic wrapper to allow html2canvas to be spied/mocked in testing
 */
@Injectable({
  providedIn: 'root',
})
export class Html2canvasProviderService {
  public html2canvasInstance() {
    return html2canvas;
  }
}
