<div class="modal-container" *ngIf="options && options.errorType">
  <div class="modal-header">
    <input ngbAutofocus class="no-size" />
    <ng-container [ngSwitch]="options.errorType">
      <span *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED" class="modal-title"> Extract failed </span>
      <span *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED_SCORING_IN_PROGRESS" class="modal-title">
        The view is being scored
      </span>
      <span *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_TIMEOUT" class="modal-title"> Extract timeout </span>
      <span *ngSwitchCase="DataWizardExtractErrorType.CANCEL_EXTRACT_FAILED" class="modal-title"> Cancel extract request failed </span>
      <span *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED_DUPLICATE" class="modal-title"> An extract is already running </span>
      <span *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_PARAMETERS_REQUIRED" class="modal-title">
        Please specify at least one valid edition
      </span>
    </ng-container>
    <grid-ui-icon type="button" class="close-icon" aria-label="Close" (click)="close()" ngbTooltip="Close window" name="cross">
    </grid-ui-icon>
  </div>
  <div class="modal-body">
    <div class="alert-circle-icon-container">
      <svg class="icon alert-circle-icon">
        <use xlink:href="#shape-alert-circle" />
      </svg>
    </div>
    <ng-container [ngSwitch]="options.errorType">
      <div *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED">
        <h1 class="error-title">Extract failed</h1>
        <h4 class="error-message">
          Your extract has failed. Please try again later or contact <a href="mailto:support@maplecroft.com">support&#64;maplecroft.com</a>.
        </h4>
      </div>
      <div *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_TIMEOUT">
        <h1 class="error-title">Extract timeout</h1>
        <h4 class="error-message">
          Your extract has timeout. Please try again later or contact
          <a href="mailto:support@maplecroft.com">support&#64;maplecroft.com</a>.
        </h4>
      </div>
      <div *ngSwitchCase="DataWizardExtractErrorType.CANCEL_EXTRACT_FAILED">
        <h1 class="error-title">Cancel extract request failed</h1>
        <h4 class="error-message">
          Your request to cancel the extract has failed. Please try again later or contact
          <a href="mailto:support@maplecroft.com">support&#64;maplecroft.com</a>.
        </h4>
      </div>
      <div *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED_DUPLICATE">
        <h1 class="error-title">An extract is already running</h1>
        <h4 class="error-message">
          Only one extract can be processed at a time. Please wait for it to complete, try again later or contact
          <a href="mailto:support@maplecroft.com">support&#64;maplecroft.com</a>.
        </h4>
      </div>
      <div *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_FAILED_SCORING_IN_PROGRESS">
        <h1 class="error-title">The view is being scored</h1>
        <h4 class="error-message">This View is still being scored, please try again later or try extracting a different View.</h4>
      </div>
      <div *ngSwitchCase="DataWizardExtractErrorType.EXTRACT_PARAMETERS_REQUIRED">
        <h1 class="error-title">Please specify at least one valid edition</h1>
        <h4 class="error-message">Please try again or contact <a href="mailto:support@maplecroft.com">support&#64;maplecroft.com</a>.</h4>
      </div>
    </ng-container>
    <div class="close-button-container">
      <button class="btn mc-btn-primary close-button" (click)="close()">Close</button>
    </div>
  </div>
</div>
