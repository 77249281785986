import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionDirective } from './directives/permission.directive';
import { CanLoadPermissionGuard, PermissionGuard } from './permission-guard';
import { PermissionsPollingService, PermissionsService, REFRESH_PERMISSIONS } from './services';
import { SitePermissionGuard } from './site-permission-guard';

@NgModule({
  imports: [CommonModule],
  declarations: [PermissionDirective],
  providers: [
    { provide: REFRESH_PERMISSIONS, useValue: 5 * 60 * 1000 },
    PermissionsService,
    PermissionsPollingService,
    CanLoadPermissionGuard,
    SitePermissionGuard,
    PermissionGuard,
  ],
  exports: [PermissionDirective],
})
export class PermissionsModule {}
