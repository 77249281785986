import { AnalyseSidePanelTabType } from './analyse-side-panel-tab.type';

export enum AnalyseSidePanelActionTypes {
  CHANGE_TAB = '[Analyse Sidepanel] Change Tab',
  TOGGLE_SIDE_PANEL_STATUS = '[Analyse Sidepanel] Toggle Sidepanel Open/Close',
}

export class AnalyseSidePanelChangeTab {
  public readonly type = AnalyseSidePanelActionTypes.CHANGE_TAB;
  constructor(public payload: { tab: AnalyseSidePanelTabType }) {}
}

export class AnalyseSidePanelToggleSidePanel {
  public readonly type = AnalyseSidePanelActionTypes.TOGGLE_SIDE_PANEL_STATUS;
}

export type AnalyseSidePanelActionsUnion = AnalyseSidePanelChangeTab | AnalyseSidePanelToggleSidePanel;
