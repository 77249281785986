export interface DataWizardUploadUpload {
  [key: string]: string | number | null;
}

export type DataWizardUploadAttributeTypes =
  | 'string'
  | 'integer'
  | 'decimal'
  | 'date'
  | 'datetime'
  | 'country'
  | 'postcode'
  | 'phone'
  | 'email'
  | 'website';

export enum DataWizardUploadAttributeTypesEnum {
  string = 'string',
  integer = 'integer',
  decimal = 'decimal',
  date = 'date',
  dateTime = 'datetime',
  country = 'country',
  postcode = 'postcode',
  phone = 'phone',
  email = 'email',
  website = 'website',
}
