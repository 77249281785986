import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PollStatusEnum } from '../../../shared-models';
import { CountryRiskDataTableExtractProcessHandlerService } from './country-risk-data-table-extract-process-handler.service';

@Injectable()
export class CountryRiskDataTableExtractNotificationService {
  private downloaded = new Subject<boolean>();
  public downloaded$: Observable<boolean>;

  constructor(private readonly handlerService: CountryRiskDataTableExtractProcessHandlerService) {
    this.downloaded$ = this.downloaded.asObservable();
  }

  public cancel(): void {
    this.handlerService.cancel();
  }

  public closeNotification(): void {
    this.handlerService.closeNotifications();
  }

  public getDownloadExtractUrl(): Observable<string> {
    return this.handlerService.getExtractDownloadUrl();
  }

  public downloadExtract(): void {
    this.handlerService.setSideNavNotificationOpenState(false);
    this.downloaded.next(true);
  }

  public extractActive(): Observable<boolean> {
    return this.handlerService.pollingProgress$.pipe(
      map((x) => x.status === PollStatusEnum.Active || x.status === PollStatusEnum.NotStarted),
    );
  }

  public extractComplete(): Observable<boolean> {
    return this.handlerService.pollingProgress$.pipe(map((x) => x.status === PollStatusEnum.Completed));
  }

  public extractStatus(): Observable<PollStatusEnum> {
    return this.handlerService.pollingProgress$.pipe(map((x) => x.status));
  }
}
