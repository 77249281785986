import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HorizontalButtonTabComponent } from './horizontal-button-tab/horizontal-button-tab.component';
import { HorizontalButtonTabsComponent } from './horizontal-button-tabs/horizontal-button-tabs.component';

@NgModule({
  imports: [CommonModule],
  declarations: [HorizontalButtonTabComponent, HorizontalButtonTabsComponent],
  exports: [HorizontalButtonTabComponent, HorizontalButtonTabsComponent],
})
export class HorizontalButtonTabsModule {}
