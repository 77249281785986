import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoMatchingResultsComponent } from './no-matching-results.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NoMatchingResultsComponent],
  exports: [NoMatchingResultsComponent],
})
export class NoMatchingResultsModule {}
