import { ViewTreesSearchGroupNode } from '../../../shared-models';
import { ApiViewTreesSearchGroupNode } from '../models';

export function mapSearchTreeGroupNodesFromApiToApp(apiNode: ApiViewTreesSearchGroupNode): ViewTreesSearchGroupNode {
  const mappedNode: ViewTreesSearchGroupNode = {
    ...apiNode,
    id: `${apiNode.id}`,
    children: apiNode.children.map(mapSearchTreeGroupNodesFromApiToApp),
  };
  return mappedNode;
}
