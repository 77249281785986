import {
  AnalyseNodeSelection,
  AnalyseReferenceNodeSelection,
  AnalyseIndicatorGroupNodeSelection,
  AnalyseIndicatorNodeSelection,
} from './analyse-node-selection.type';

export function isSelectionWithParentGroupId(
  selection: AnalyseNodeSelection,
): selection is AnalyseReferenceNodeSelection | AnalyseIndicatorGroupNodeSelection | AnalyseIndicatorNodeSelection {
  return Object.prototype.hasOwnProperty.call(selection, 'parentGroupId');
}
