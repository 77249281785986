<div class="modal-container">
  <div class="modal-header">
    <input ngbAutofocus class="no-size" />
    <span class="modal-title">{{ modalTitle }}</span>
    <grid-ui-icon
      type="button"
      id="dismissButton"
      class="close-icon"
      aria-label="Dismiss"
      (click)="dismiss()"
      data-hook="ask-an-analyst-dismiss-btn"
      ngbTooltip="Close window"
      name="cross"
    ></grid-ui-icon>
  </div>

  <div class="modal-body">
    <div *ngIf="saveStatus === SavingRequestStatus.saving">
      <grid-ui-loading message="Sending your question, please wait."></grid-ui-loading>
    </div>

    <div *ngIf="analystInfo !== null" class="analyst">
      <div class="photo">
        <img
          *ngIf="analystInfo!.photo_thumbnail"
          alt="{{ analystInfo!.name }}"
          src="{{ analystInfo!.photo_thumbnail!.path }}"
          data-hook="ask-an-analyst-photo"
        />
      </div>
      <div class="details">
        <h6 class="name" title="{{ analystInfo!.name }}" data-hook="ask-an-analyst-name">
          {{ analystInfo!.name }}
        </h6>
        <span class="title small" *ngIf="analystInfo!.title" title="{{ analystInfo!.title }}" data-hook="ask-an-analyst-title">
          {{ analystInfo!.title }}
        </span>
        <div class="d-flex contact-links-container" *ngIf="analystInfo !== null" data-hook="ask-an-analyst-contact-links-container">
          <a href="tel:{{ analystInfo!.phone }}" *ngIf="analystInfo!.phone" data-hook="ask-an-analyst-contact-phone-anchor">
            <span
              class="contact-link phone"
              ngbTooltip="{{ analystInfo!.phone || '' }}"
              placement="bottom"
              data-hook="ask-an-analyst-contact-phone"
            >
              <svg>
                <use xlink:href="#shape-phone" />
              </svg>
            </span>
          </a>
          <a
            *ngIf="analystInfo!.twitter"
            href="{{ analystInfo!.twitter }}"
            target="_blank"
            rel="noopener noreferrer"
            data-hook="ask-an-analyst-contact-twitter-anchor"
          >
            <span
              class="contact-link twitter"
              ngbTooltip="{{ analystInfo!.twitter || '' }}"
              placement="bottom"
              data-hook="ask-an-analyst-contact-twitter"
            >
              <svg>
                <use xlink:href="#shape-twitter" />
              </svg>
            </span>
          </a>
          <a
            *ngIf="analystInfo!.linked_in && analystInfo.linked_in !== MAPLECROFT_CORPORATE_LINKED_IN"
            href="{{ analystInfo!.linked_in }}"
            target="_blank"
            rel="noopener noreferrer"
            data-hook="ask-an-analyst-contact-linked-in-anchor"
          >
            <span
              class="contact-link linkedin"
              ngbTooltip="{{ analystInfo!.linked_in || '' }}"
              placement="bottom"
              data-hook="ask-an-analyst-contact-linked-in"
            >
              <svg>
                <use xlink:href="#shape-linkedin" />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="saveStatus === SavingRequestStatus.initial">
      <div class="form-group">
        <label for="enter-a-subject">Enter A Subject</label>
        <input
          *ngIf="!fixedSubject"
          [(ngModel)]="subject"
          class="form-control"
          id="enter-a-subject"
          maxlength="80"
          placeholder="Enter A Subject"
          data-hook="ask-an-analyst-subject-input"
        />
        <div class="ms-2" *ngIf="fixedSubject" data-hook="ask-an-analyst-subject-readonly">
          <strong>Subject:</strong> {{ fixedSubject }}
        </div>
      </div>
      <div class="form-group">
        <label for="question">{{ questionPlaceholder }}</label>
        <textarea
          [(ngModel)]="question"
          id="question"
          class="form-control"
          rows="5"
          [placeholder]="questionPlaceholder"
          data-hook="ask-an-analyst-message-textarea"
        ></textarea>
      </div>
    </div>
    <div class="thanks" [class.analyst-shown]="analystInfo" *ngIf="saveStatus === SavingRequestStatus.saved">
      <h3>Thank You!</h3>
      <span class="small">Your message has been sent.</span>
      <span class="small" *ngIf="analystInfo && analystInfo!.name" data-hook="ask-an-analyst-named-thank-you">
        {{ analystInfo!.name.split(' ')[0] }} will be in touch soon.
      </span>
      <span class="small" *ngIf="analystInfo === null || !analystInfo!.name" data-hook="ask-an-analyst-unnamed-thank-you">
        An analyst will be in touch soon.
      </span>
    </div>
    <div class="error" *ngIf="saveStatus === SavingRequestStatus.error && apiError">
      <h3 data-hook="ask-an-analyst-error-heading">{{ apiError!.title }}. We could not send the message.</h3>
      <span class="small" data-hook="ask-an-analyst-error-message"> {{ apiError!.message }} Please try again. </span>
    </div>
  </div>

  <div class="modal-footer" [class.centered]="saveStatus === SavingRequestStatus.saved || saveStatus === SavingRequestStatus.error">
    <button
      *ngIf="saveStatus === SavingRequestStatus.initial"
      type="button"
      class="btn mc-btn-secondary"
      (click)="dismiss()"
      data-hook="ask-an-analyst-cancel-btn"
    >
      Cancel
    </button>
    <button
      *ngIf="saveStatus === SavingRequestStatus.initial"
      type="submit"
      class="btn mc-btn-primary"
      [disabled]="!(subject.length && question.length)"
      (click)="sendQuestion()"
      data-hook="ask-an-analyst-send-btn"
    >
      Send
    </button>
    <button
      *ngIf="saveStatus === SavingRequestStatus.saved"
      type="button"
      class="btn mc-btn-primary"
      (click)="close()"
      data-hook="ask-an-analyst-done-btn"
    >
      Done
    </button>
    <button
      *ngIf="saveStatus === SavingRequestStatus.error"
      type="button"
      class="btn mc-btn-primary"
      (click)="clearError()"
      data-hook="ask-an-analyst-accept-btn"
    >
      Accept
    </button>
  </div>
</div>
