import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  NonPaginatedResourceConfig,
  PaginatedCollection,
  PaginatedResourceConfig,
  PaginationContext,
  PortalHttpClient,
} from '@grid-ui/common';
import { isNaNStrict } from '@qntm-code/utils';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { BasePortalHttpClientRequestParameters } from '../../models';
import { DataWizardUploadMetadata, DataWizardUploadMetadataResponse } from '../models';
import { DataWizardUploadMetadataQueryParams } from '../models/data-wizard-upload-metadata-query-params.model';

@Injectable({
  providedIn: 'root',
})
export class DataWizardUploadMetadataService {
  private uploadResourceConfig: NonPaginatedResourceConfig;
  private uploadsResourceConfig: PaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.uploadResourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.upload._configuration;
    this.uploadsResourceConfig = API_SERVICES_CONFIG.v3.locations.uploads._configuration;
  }

  /**
   * Fetch the status of bulk Location upload
   *
   * @param id - of the Location upload session
   * @param partial_response - whether to exclude the 'results' attribute from the
   * response or not. This includes all location upload rows, so excluding it improves
   * the endpoint performance.
   */
  public getLocationUploadMetadata(id: string, partial_response = false): Observable<DataWizardUploadMetadata> {
    const options: BasePortalHttpClientRequestParameters = {
      pathParams: { id },
      queryParams: { acknowledge: partial_response ? 0 : 1 },
    };
    return this.http.get<DataWizardUploadMetadata>(this.uploadResourceConfig, options);
  }

  public getLocationUploadsMetadata(
    queryParams: DataWizardUploadMetadataQueryParams,
  ): Observable<PaginatedCollection<DataWizardUploadMetadata, DataWizardUploadMetadataQueryParams>> {
    return this.http
      .get<DataWizardUploadMetadataResponse>(this.uploadsResourceConfig, {
        queryParams,
      })
      .pipe(
        map(({ results, total, links }) => {
          const paginationContext: PaginationContext<DataWizardUploadMetadataQueryParams> = Object.fromEntries(
            Object.entries(links).map(([linkKey, url]) => {
              if (url) {
                const { searchParams } = new URL(url);

                return [
                  linkKey,
                  Object.fromEntries(
                    Array.from(new Set(searchParams.keys())).map((key) => {
                      const value = searchParams.getAll(key).join(',');

                      const intValue = parseInt(value, 10);

                      if (!isNaNStrict(intValue)) {
                        return [key, intValue];
                      }

                      return [key, value];
                    }),
                  ),
                ];
              }

              return [linkKey, null];
            }),
          ) as unknown as PaginationContext<DataWizardUploadMetadataQueryParams>;

          return { results, total, paginationContext };
        }),
      );
  }

  public getInProgressLocationUploadsMetadata(): Observable<
    PaginatedCollection<DataWizardUploadMetadata, DataWizardUploadMetadataQueryParams>
  > {
    return this.getLocationUploadsMetadata({
      status_type: ['in-progress'],
    }).pipe(share());
  }
}
