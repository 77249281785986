import { HttpErrorResponse } from '@angular/common/http';

export enum StoreDataWrapperStatus {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export interface StoreDataWrapper<T> {
  data: T;
  status: StoreDataWrapperStatus;
  statusString: string;
  error?: HttpErrorResponse;
}
