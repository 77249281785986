import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromNotificationBar from '../store/reducers';
import * as notificationActions from '../store/actions/notifications';
import { NotificationBarNotification, NotificationBarNotificationInternal } from '../models';
import { getCurrentNotification } from '../store/reducers';

@Injectable({
  providedIn: 'root',
})
export class NotificationBarService {
  constructor(private readonly store: Store<fromNotificationBar.State>) {}

  public createNotification(notification: NotificationBarNotification): number {
    const id = Date.now();
    this.store.dispatch(new notificationActions.AddNotification({ ...notification, _id: id }));
    return id;
  }

  public cancelNotification(id: number): void {
    this.store.dispatch(new notificationActions.RemoveNotification(id));
  }

  public closeCurrentNotification(): void {
    this.store.dispatch(new notificationActions.RemoveCurrentNotification());
  }

  public getCurrentNotification(): Observable<NotificationBarNotificationInternal | null> {
    return this.store.select(getCurrentNotification);
  }
}
