import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  PaginatedResourceConfig,
  PathParams,
  PortalHttpClient,
  QueryParams,
} from '@grid-ui/common';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewTreesSearchCollection, ViewTreesSearchGroupNode, ViewTreesSearchQueryParams } from '../../../shared-models';
import { ApiViewTreesSearchCollection } from '../models';
import { mapSearchTreeGroupNodesFromApiToApp } from '../utils';

export class CountryRiskViewsBaseTreesSearchService {
  private resourceConfig: PaginatedResourceConfig;
  private byViewResourceConfig: PaginatedResourceConfig;

  constructor(
    private readonly treeType: 'index' | 'location',
    private readonly http: PortalHttpClient,
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurationTreesSearch._configuration;
    this.byViewResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurationTreesSearch.byView._configuration;
  }

  /**
   * Search Country View Trees (Risk Index/Locations Tree) based on node name.
   * Returns a collection of tree search results in nested tree representation
   * based on the Country Risk Views included in the search as per specified query parameters.
   *
   * @param params A query parameter object
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public searchViewTrees(
    params?: ViewTreesSearchQueryParams | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<ViewTreesSearchCollection> {
    // Maplecroft view will now be tagged with 'index' or location so appending tag attribute in query params.
    if (params && params.reference) {
      params.tag = this.treeType;
    }
    let currentResourceConfig: PaginatedResourceConfig = this.resourceConfig;
    const queryParams: QueryParams = R.omit(['viewId'], params);
    const pathParams: PathParams = { treeType: this.treeType };

    if (params && params.viewId) {
      currentResourceConfig = this.byViewResourceConfig;
      pathParams.viewId = params.viewId;
    }

    return this.http
      .getPaginated<ApiViewTreesSearchCollection>(currentResourceConfig, {
        ...options,
        pathParams,
        queryParams,
      })
      .pipe(
        map((responseContext) => {
          const mappedCollection: ViewTreesSearchCollection = {
            total: responseContext.response.total,
            results: responseContext.response.results.map(mapSearchTreeGroupNodesFromApiToApp).map((groupNode) => {
              const mappedNode: ViewTreesSearchGroupNode = {
                ...groupNode,
                isRoot: true,
              };
              return mappedNode;
            }),
          };
          return mappedCollection;
        }),
      );
  }
}
