import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IconModule, LoadingModule, SearchableDropdownModule, UnexpectedErrorStatusModule } from '@grid-ui/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';

@NgModule({
  declarations: [AppComponent, PersonalDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    IconModule,
    LoadingModule,
    AppRoutingModule,
    ReactiveFormsModule,
    UnexpectedErrorStatusModule,
    SearchableDropdownModule,
    RouterModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AccountPersonalDetailsModule {}
