import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuoteItemValue } from '../../../shared-models';

@Component({
  selector: 'grid-ui-body-item-quote',
  templateUrl: './body-item-quote.component.html',
  styleUrls: ['./body-item-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemQuoteComponent {
  @Input() public value: QuoteItemValue | null = null;
}
