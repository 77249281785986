<ul *ngIf="breadcrumbs && breadcrumbs.length" class="route-breadcrumbs">
  <li *ngFor="let crumb of breadcrumbs; trackBy: trackByIndex; last as last" [ngClass]="{ home: isHome }">
    <span *ngIf="crumb.route !== null && !last" class="navigable-route" (click)="onRouteSelected(crumb.route)">
      {{ crumb.label }}
    </span>
    <span *ngIf="crumb.route === null || last" title="{{ crumb.label }}" class="non-navigable-route" [class.current-route]="last">
      {{ crumb.label }}
    </span>
    <span *ngIf="!last" class="segment-divider">/</span>
  </li>
</ul>
