<div class="quote-item" *ngIf="value">
  <div class="author" *ngIf="value!.analyst && value!.analyst!.length">
    <img src="assets/img/icons/pullquote.svg" alt="quote" width="40px" height="40px" />
    <div class="analysts">
      <div class="analyst" *ngFor="let analyst of value!.analyst">
        <div class="analyst-details">
          <h6 *ngIf="analyst" class="name" [innerHtml]="analyst.name"></h6>
          <span *ngIf="analyst" class="title small" [innerHtml]="analyst.title"> </span>
        </div>
        <div class="photo">
          <img *ngIf="analyst.photo_thumbnail" src="{{ analyst.photo_thumbnail!.url }}" />
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="value!.quote" class="quote-item-text" [innerHtml]="value!.quote | updateLink | safeHtml"></div>
</div>
