<!--Modal version-->
<ng-container *ngIf="isDisplayedWithinModal">
  <div
    ngbDropdown
    #dropdownButton
    dropdownClass="dropdown-menu-container"
    [ngClass]="styleTheme"
    [autoClose]="autoClose"
    [container]="container"
    data-hook="searchable-dropdown"
  >
    <button
      class="form-control d-flex"
      [class.mc-inactive]="disabled"
      [class.mc-invalid]="invalid"
      [class.mc-placeholder]="selectedItem === null"
      ngbDropdownToggle
      [disabled]="disabled"
      [style.z-index]="ngbDropdown?.isOpen() && baseZIndex !== null ? baseZIndex + 1 : null"
      (blur)="emitTouchedOnBlur()"
      (keydown)="onTabKey($event)"
      data-hook="searchable-dropdown-toggle"
    >
      <span class="searchable-dropdown-label" data-hook="searchable-dropdown-label">
        {{ dropdownToggleLabel }}
      </span>
      <grid-ui-icon
        class="dropdown-icon"
        name="chevron-down"
        [ngClass]="{ 'dropdown-icon__expanded': ngbDropdown?.isOpen() }"
      ></grid-ui-icon>
    </button>
    <div *ngIf="ngbDropdown?.isOpen()" [style.width]="menuWidth" [style.z-index]="baseZIndex !== null ? baseZIndex : null">
      <div *ngIf="countHeader !== null || searchable" class="dropdown-menu-header">
        <h3 *ngIf="countHeader !== null" class="mc-dropdown-count-header">
          {{ countHeader }}
        </h3>
        <div *ngIf="searchable">
          <grid-ui-search-input
            [value]="searchTerm"
            [placeholder]="searchInputPlaceholder"
            [debounce]="400"
            [emitOn]="'change'"
            (searchTermChange)="setSearchTerm($event)"
          >
          </grid-ui-search-input>
        </div>
      </div>
      <div class="dropdown-menu-body" [style.max-height]="maxBodyHeight">
        <ng-container *ngIf="filteredList !== null">
          <button *ngIf="showNullItem" ngbDropdownItem (click)="selectItem(null)" data-hook="searchable-dropdown-empty-selection-button">
            <i>--Empty--</i>
          </button>
          <button
            *ngFor="let item of filteredList; index as i"
            ngbDropdownItem
            [class.active]="isSelectedItem(item, selectedItemId)"
            (click)="selectItem(item)"
            [attr.data-hook]="'searchable-dropdown-selection-button-' + i"
          >
            {{ item.label }}
          </button>
        </ng-container>
        <div *ngIf="noDataAvailable" class="no-data-available-container">
          <grid-ui-no-data-available [message]="noDataAvailableMessage"> </grid-ui-no-data-available>
        </div>
        <div *ngIf="noMatchingResults" class="no-matching-results-container">
          <grid-ui-no-matching-results [message]="noMatchingResultsMessage"> </grid-ui-no-matching-results>
        </div>
        <div *ngIf="unfilteredItems.loadingStatus === LoadingRequestStatus.loading" class="mc-item-loading-container">
          <grid-ui-loading [message]="loadingMessage"> </grid-ui-loading>
        </div>
        <div *ngIf="unfilteredItems.loadingStatus === LoadingRequestStatus.error && unfilteredItems.error" class="grid-ui-error-container">
          <grid-ui-error
            [errorCode]="unfilteredItems.error!.errorCode"
            [contactEmail]="unfilteredItems.error!.contactEmail"
            [errorMessage]="unfilteredItems.error!.errorMessage"
            [errorPrimaryAction]="unfilteredItems.error!.errorPrimaryAction"
            [showContact]="unfilteredItems.error!.showContact"
            [size]="unfilteredItems.error!.size"
            (primaryActionClick)="retry()"
          >
          </grid-ui-error>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>

<!-- Standard version not displayed within modal -->
<ng-container *ngIf="!isDisplayedWithinModal">
  <div
    ngbDropdown
    class="d-flex"
    #dropdownButton
    dropdownClass="dropdown-menu-container"
    [ngClass]="styleTheme"
    [autoClose]="autoClose"
    [container]="container"
    data-hook="searchable-dropdown"
  >
    <button
      class="form-control d-flex"
      [class.mc-inactive]="disabled"
      [class.mc-invalid]="invalid"
      [class.mc-placeholder]="selectedItem === null"
      ngbDropdownToggle
      [disabled]="disabled"
      [style.z-index]="ngbDropdown?.isOpen() && baseZIndex !== null ? baseZIndex + 1 : null"
      (blur)="emitTouchedOnBlur()"
      data-hook="searchable-dropdown-toggle"
    >
      <span class="searchable-dropdown-label" data-hook="searchable-dropdown-label">
        {{ dropdownToggleLabel }}
      </span>
      <grid-ui-icon
        class="dropdown-icon"
        name="chevron-down"
        [ngClass]="{ 'dropdown-icon__expanded': ngbDropdown?.isOpen() }"
      ></grid-ui-icon>
    </button>
    <div ngbDropdownMenu [style.width]="menuWidth" [style.z-index]="baseZIndex !== null ? baseZIndex : null">
      <div *ngIf="countHeader !== null || searchable" class="dropdown-menu-header">
        <h3 *ngIf="countHeader !== null" class="mc-dropdown-count-header">
          {{ countHeader }}
        </h3>
        <div *ngIf="searchable">
          <grid-ui-search-input
            [value]="searchTerm"
            [placeholder]="searchInputPlaceholder"
            [debounce]="400"
            [emitOn]="'change'"
            (searchTermChange)="setSearchTerm($event)"
          >
          </grid-ui-search-input>
        </div>
      </div>
      <div class="dropdown-menu-body" [style.max-height]="maxBodyHeight">
        <ng-container *ngIf="filteredList !== null">
          <button *ngIf="showNullItem" ngbDropdownItem (click)="selectItem(null)" data-hook="searchable-dropdown-empty-selection-button">
            <i>--Empty--</i>
          </button>
          <button
            *ngFor="let item of filteredList; index as i"
            ngbDropdownItem
            [class.active]="isSelectedItem(item, selectedItemId)"
            (click)="selectItem(item)"
            [attr.data-hook]="'searchable-dropdown-selection-button-' + i"
          >
            {{ item.label }}
          </button>
        </ng-container>
        <div *ngIf="noDataAvailable" class="no-data-available-container">
          <grid-ui-no-data-available [message]="noDataAvailableMessage"> </grid-ui-no-data-available>
        </div>
        <div *ngIf="noMatchingResults" class="no-matching-results-container">
          <grid-ui-no-matching-results [message]="noMatchingResultsMessage"> </grid-ui-no-matching-results>
        </div>
        <div *ngIf="unfilteredItems.loadingStatus === LoadingRequestStatus.loading" class="mc-item-loading-container">
          <grid-ui-loading [message]="loadingMessage"> </grid-ui-loading>
        </div>
        <div *ngIf="unfilteredItems.loadingStatus === LoadingRequestStatus.error && unfilteredItems.error" class="grid-ui-error-container">
          <grid-ui-error
            [errorCode]="unfilteredItems.error!.errorCode"
            [contactEmail]="unfilteredItems.error!.contactEmail"
            [errorMessage]="unfilteredItems.error!.errorMessage"
            [errorPrimaryAction]="unfilteredItems.error!.errorPrimaryAction"
            [showContact]="unfilteredItems.error!.showContact"
            [size]="unfilteredItems.error!.size"
            (primaryActionClick)="retry()"
          >
          </grid-ui-error>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
