import { Component, Input, OnInit } from '@angular/core';
import { CitationData } from '../models';

const CITATION_HEADERS: { [type: string]: string } = {
  online: 'Online citation',
  offline: 'Offline citation',
  interview: 'Interview citation',
};

const initialCitation: CitationData = {
  placement: 'left',
};

@Component({
  selector: 'grid-ui-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss'],
})
export class CitationComponent implements OnInit {
  @Input() public data: CitationData = initialCitation;

  public ngOnInit(): void {
    this.data = { show: false, placement: 'right' };
  }

  public get_header(key: string | undefined): string {
    return key !== undefined ? CITATION_HEADERS[key] : '';
  }
}
