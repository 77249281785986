import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { format } from '../../../api';
import {
  CountryRiskIndexNarrative,
  GeneralLoadedContent,
  INITIAL_GENERAL_LOADING_CONTENT,
  LoadingRequestStatus,
} from '../../../shared-models';
import { DownloadResourceService } from '../../../shared-utilities/download-resource';
import { WindowUtilitiesService } from '../../../shared-utilities/window-services';
import { WrittenContentLinkActionsType, WrittenContentLinkActionsUnion } from '../../listeners/written-content-anchor-click-listener';

@Component({
  selector: 'grid-ui-index-info-popover',
  templateUrl: './index-info-popover.component.html',
  styleUrls: ['./index-info-popover.component.scss'],
})
export class IndexInfoPopoverComponent {
  @Input() public closeReason: null | HttpErrorResponse = null;
  @Input() public downloadPathTemplate: string | null = null;
  @Input() public indexNarrative: GeneralLoadedContent<CountryRiskIndexNarrative> = INITIAL_GENERAL_LOADING_CONTENT;

  @ViewChild('sectionsContainer', { read: ElementRef })
  public sectionsElementRef: ElementRef<HTMLDivElement> | null = null;
  @ViewChild('popoverContainer', { read: ElementRef })
  public popoverContainerElementRef: ElementRef<HTMLDivElement> | null = null;

  public readonly RequestStatus = LoadingRequestStatus;
  public showScrollToTop = false;

  public downloading = false;
  public downloadFailed = false;
  private downloadSubscription: Subscription | null = null;

  public constructor(
    public activeModal: NgbActiveModal,
    private windowUtilitiesService: WindowUtilitiesService,
    private readonly downloadResourceService: DownloadResourceService,
  ) {
    if (this.downloadSubscription && !this.downloadSubscription.closed) {
      this.downloadSubscription.unsubscribe();
    }
  }

  public downloadPdfUrl(): string | null {
    if (this.indexNarrative.content !== null && this.indexNarrative.content.id && this.downloadPathTemplate) {
      const URI: string = format(this.downloadPathTemplate, { id: this.indexNarrative.content.id });
      return URI + '?format=pdf';
    } else {
      return null;
    }
  }

  public onDownloadPdfUrl(): void {
    const url = this.downloadPdfUrl();

    this.downloading = true;
    this.downloadFailed = false;

    if (url) {
      this.downloadSubscription = this.downloadResourceService
        .download(url)
        .pipe(finalize(() => (this.downloading = false)))
        .subscribe(
          (response: HttpResponse<Blob>) => {
            if (response && response.headers) {
              const contentDisposition = response.headers.get('content-disposition');
              const filename = contentDisposition
                ? this.downloadResourceService.getFilenameByContendDisposition(contentDisposition)
                : 'Verisk Maplecroft.pdf';
              const blob = new Blob([response.body as BlobPart], { type: 'application/pdf' });
              saveAs(blob, filename);
            }
          },
          (err) => {
            this.downloadFailed = true;
            throw err;
          },
        );
    }
  }

  public handleWrittenContentLinkAction(action: WrittenContentLinkActionsUnion): void {
    switch (action.type) {
      case WrittenContentLinkActionsType.OPEN_EXTERNAL_LINK:
      case WrittenContentLinkActionsType.OPEN_PORTAL_ROUTE_IN_NEW_TAB:
        this.windowUtilitiesService.openNewTab(action.payload.url);
        break;
      case WrittenContentLinkActionsType.NAVIGATE_TO_PORTAL_ROUTE:
      case WrittenContentLinkActionsType.SHOW_INDEX_INFORMATION:
        this.activeModal.close(action);
    }
  }

  public onScroll(event: any): void {
    this.showScrollToTop = event.target.offsetHeight + event.target.scrollTop >= 900;
  }

  public retryDataLoad(): void {
    this.activeModal.close(this.indexNarrative.error);
  }

  public scrollToBookmark(index: number): void {
    if (this.sectionsElementRef) {
      let bookmarkTop = 0;
      for (let i = 0; i < index; i++) {
        bookmarkTop += this.sectionsElementRef.nativeElement.children[i].getBoundingClientRect().height;
      }
      this.sectionsElementRef.nativeElement.scrollTo({ top: bookmarkTop, behavior: 'smooth' });
    }
  }

  public scrollToTopClicked(): void {
    if (this.sectionsElementRef) {
      this.sectionsElementRef.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
      this.showScrollToTop = false;
    }
  }
}
