import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule, LoadingModule } from '@grid-ui/common';
import { StoreModule } from '@ngrx/store';
import { DataWizardExtractModalsModule } from '../../shared-ui-components/data-wizard-extract-modals';
import { GridSharedPipesModule } from '../../shared-ui-components/shared-pipes';
import { DataTableExtractNotificationComponent, ExtractNotificationComponent, NotificationBarComponent } from './components';
import {
  CountryRiskDataTableExtractNotificationService,
  CountryRiskDataTableExtractProcessHandlerService,
  DataWizardExtractNotificationService,
  DataWizardExtractProcessHandlerService,
  DataWizardRootModalManagerService,
  NotificationBarService,
} from './services';
import { reducers } from './store/reducers';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('notificationBar', reducers),
    DataWizardExtractModalsModule,
    GridSharedPipesModule,
    LoadingModule,
    IconModule,
  ],
  declarations: [NotificationBarComponent, ExtractNotificationComponent, DataTableExtractNotificationComponent],
  exports: [NotificationBarComponent],
  providers: [
    DataWizardExtractNotificationService,
    CountryRiskDataTableExtractNotificationService,
    CountryRiskDataTableExtractProcessHandlerService,
    DataWizardExtractProcessHandlerService,
    DataWizardRootModalManagerService,
    NotificationBarService,
  ],
})
export class ExtractManagementModule {}
