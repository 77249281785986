import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { ScoreCategoryType, getScoreCategory } from '../../../..';

type ScoredCellRendererParams = { scoreFormat: string } & ICellRendererParams;

@Component({
  selector: 'grid-ui-scored-cell-renderer',
  templateUrl: './scored-cell-renderer.component.html',
  styleUrls: ['./scored-cell-renderer.component.scss'],
})
export class ScoredCellRendererComponent implements ICellRendererAngularComp {
  public value!: number;
  public valueCategory!: ScoreCategoryType | null;
  public scoreFormat?: string;

  public agInit(params: ScoredCellRendererParams): void {
    this.value = params.value;
    this.valueCategory = getScoreCategory(this.value);
    this.scoreFormat = params.scoreFormat;
  }

  public refresh(params: ScoredCellRendererParams): boolean {
    this.value = params.value;
    this.valueCategory = getScoreCategory(this.value);
    this.scoreFormat = params.scoreFormat;
    return true;
  }
}
