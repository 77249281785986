import { Injectable } from '@angular/core';
import { ClientDimensionsService, LG_BREAKPOINT, MD_BREAKPOINT, SM_BREAKPOINT } from './client-dimensions.service';

@Injectable({
  providedIn: 'root',
})
export class GridColumnsService {
  constructor(private readonly clientDimensionsService: ClientDimensionsService) {}

  public getColumnCount(): number {
    const totalWidth = this.clientDimensionsService.getClientWidth();
    return this.getColumnCountForWindowWidth(totalWidth);
  }

  private getColumnCountForWindowWidth(windowWidth: number): number {
    if (windowWidth < SM_BREAKPOINT) {
      return 1;
    } else if (SM_BREAKPOINT <= windowWidth && windowWidth < MD_BREAKPOINT) {
      return 2;
    } else if (MD_BREAKPOINT <= windowWidth && windowWidth < LG_BREAKPOINT) {
      return 3;
    } else {
      return 4;
    }
  }
}
