<div class="cookies-notification-banner-container">
  <div class="cookies-notification-banner-notice" data-hook="cookies-notification-banner-notice">
    We use cookies to ensure you get the best experience on our website. By continuing to use our site, you are agreeing to this usage. For
    more information, see our
    <a
      href="https://www.maplecroft.com/privacy-notice/"
      target="_blank"
      rel="noopener noreferer"
      data-hook="cookies-notification-banner-notice-link"
      >privacy notice</a
    >
  </div>

  <button class="btn mc-btn-primary mc-accept-close-btn" (click)="acceptCookies()" data-hook="cookies-notification-banner-button">
    Accept & Close
  </button>
</div>
