<div class="dropdown" [ngClass]="{ home: isHome }">
  <button class="drop-button">
    <span data-hook="users-name" class="name" data-hj-suppress>
      {{ (username$ | async)?.first_name }} {{ (username$ | async)?.last_name }}
    </span>
    <grid-ui-icon class="dropdown-caret" name="chevron-down" [useInlineSVG]="true"></grid-ui-icon>
  </button>
  <div class="dropdown-content">
    <ng-container *gridUiFeatureFlag="'subscriptions'">
      <p
        class="dropdown-item"
        data-hook="topUserMenuSubscriptions"
        (click)="clickSubscriptions()"
        *mcPermission="PermissionSelector.SUBSCRIPTIONS.MODULE"
      >
        My Subscriptions
      </p>
    </ng-container>
    <ng-container *gridUiFeatureFlag="'release:new-account-settings'; else oldAccountSettings">
      <p *mcPermission="PermissionSelector.GRID.ACCOUNT_SETTINGS" class="dropdown-item" data-hook="topUserMenuAccountSettings">
        <a [routerLink]="'/account-settings'">Account Settings</a>
      </p>
    </ng-container>
    <ng-template #oldAccountSettings>
      <p
        *mcPermission="PermissionSelector.GRID.ACCOUNT_SETTINGS"
        class="dropdown-item"
        data-hook="topUserMenuAccountSettings"
        (click)="clickAccountSettings()"
      >
        Account Settings
      </p>
    </ng-template>
    <p *mcPermission="PermissionSelector.GRID.ACCOUNT_SETTINGS" class="dropdown-item" data-hook="topUserMenuLogout" (click)="clickLogout()">
      Logout
    </p>
  </div>
</div>
