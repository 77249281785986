import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataWizardModalActionsEnum, DataWizardModalOptions } from '../../../shared-models';

@Component({
  selector: 'mc-data-wizard-extract-confirm-cancel-modal',
  templateUrl: './data-wizard-extract-confirm-cancel-modal.component.html',
  styleUrls: ['./data-wizard-extract-confirm-cancel-modal.component.scss'],
})
export class DataWizardExtractConfirmCancelModalComponent {
  @Input() options: DataWizardModalOptions;
  @Input() loading: boolean;

  @Output() modalAction = new EventEmitter<DataWizardModalActionsEnum>();

  public continue(): void {
    this.modalAction.emit(DataWizardModalActionsEnum.ContinueExtract);
  }

  public cancel(): void {
    this.modalAction.emit(DataWizardModalActionsEnum.CancelExtract);
  }
}
