import { ScoreCategoryType } from './score-category.type';
import { ScoreCategoryFilter } from './score-category-filter-options.model';

export const SCORE_CATEGORY_MAP: { [s in ScoreCategoryType]: ScoreCategoryFilter } = {
  extreme: { name: 'Extreme', id: 'extreme', selected: true },
  high: { name: 'High', id: 'high', selected: true },
  medium: { name: 'Medium', id: 'medium', selected: true },
  low: { name: 'Low', id: 'low', selected: true },
};

export const SCORE_CATEGORY_COUNT = Object.keys(SCORE_CATEGORY_MAP).length;
