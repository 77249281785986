import { LocationAttribute, LocationAttributeWithChoices } from '../../shared-models';

/**
 * An array of pseudo-Location Attributes, which the user is able to
 * show/hide in certain location listings (E.g. DW Site List or Analyse Show All Locations).
 *
 * Pseudo-attributes are understood to be those, which have corresponding keys in
 * the Location API Response even though they do not have a matching Location Attribute
 * in the Location Attributes API response
 */
export const ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES: LocationAttribute[] = [];

export const ACCESSIBLE_LOCATION_PSEUDO_ATTRIBUTES_WITH_CHOICES: LocationAttributeWithChoices[] = [];

/**
 * An array of keys of pseudo-Location Attributes, which the user _cannot_
 * show/hide in any location listings, but which are reserved so cannot be used
 * as names for Custom Location Attributes.
 *
 * Naming a custom column with these reserved keys, may not create a conflict
 * with a Location Attribute in the Location Attributes API response, but they
 * correspond to keys in the Locations API response.
 */
export const OTHER_RESERVED_LOCATION_PSEUDO_ATTRIBUTE_KEYS: string[] = ['type', 'last_viewed', 'source', 'detail'];
