import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { TableItemValue, TableItemData, TableItemDataElementType } from '../../../shared-models';

@Component({
  selector: 'grid-ui-body-item-table',
  templateUrl: './body-item-table.component.html',
  styleUrls: ['./body-item-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemTableComponent implements OnChanges {
  @Input() public value: TableItemValue | null = null;

  public rowHeaders: Array<TableItemDataElementType> | null = null;
  public columnHeaders: Array<TableItemDataElementType> | null = null;
  public innerData: Array<TableItemDataElementType>[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes['value'].currentValue;

    if (!changes['value'] || !currentValue) {
      return;
    }
    this.prepareTableData(currentValue);
  }

  private prepareTableData(currentValue: TableItemValue): void {
    const item: TableItemData = currentValue.data;
    let dataRows: Array<TableItemDataElementType>[];

    if (item.first_row_is_table_header) {
      this.rowHeaders = item.data[0];
      dataRows = item.data.slice(1);
    } else {
      this.rowHeaders = null;
      dataRows = item.data;
    }

    if (item.first_col_is_header) {
      this.columnHeaders = dataRows.map((row: Array<TableItemDataElementType>) => row[0]);
      this.innerData = dataRows.map((row: Array<TableItemDataElementType>) => row.slice(1));
    } else {
      this.columnHeaders = null;
      this.innerData = dataRows;
    }
  }
}
