import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchAll } from 'rxjs/operators';
import { EmitOnType } from './models';

export const DEFAULT_DEBOUNCE_TIME = 0;
export const DEFAULT_PLACEHOLDER = 'Type search term here';
export const DEFAULT_EMIT_ON = EmitOnType.CHANGE;

/**
 * Search term input box which optionally debounces inputs and only emits
 * changed search terms, when they are distinct from the prior term after
 * the debounce time.
 *
 * If [debounce] is omitted or set to 0, search term changes will be emitted
 * for every key stroke immediately.
 *
 * Accepts a [value] input binding to set a search term from the parent component.
 * This can be used, in a Redux pattern, to set the search term if it was persisted
 * in the store state of the parent beyond the component lifecycle.
 */
@Component({
  selector: 'grid-ui-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, OnDestroy {
  /**
   * Boolean value to disable search input
   */
  public disabled$ = new BehaviorSubject(false);
  @Input() public set disabled(value: boolean) {
    if (value) this.searchTerm.disable();
    else this.searchTerm.enable();
    this.disabled$.next(value);
  }

  /**
   * Value to set the search term to
   */
  @Input()
  public set value(value: string) {
    if (value !== this.searchTerm.value) {
      this.searchTerm.reset(value);
    }
  }

  /**
   * Debounce time for the key strokes before a searchTermChange event is emitted,
   * if the search term is distinct from prior search term.
   */
  @Input()
  public set debounce(debounce: number) {
    this.debounceTime = Math.max(Math.floor(debounce), 0);
    this.updateDebouncedObservable(this._emitOn, this.debounceTime);
  }

  public get debounce(): number {
    return this.debounceTime;
  }

  /**
   * Used to confirm when to emit the change
   * Options are EmitOnType.CHANGE and EmitOnType.ENTER.
   * The default is to emit on search term change.
   */
  @Input() public set emitOn(emitOn: 'change' | 'enter') {
    // Only allow change and enter, use the default
    switch (emitOn) {
      case 'change':
        this._emitOn = EmitOnType.CHANGE;
        break;
      case 'enter':
        this._emitOn = EmitOnType.ENTER;
        break;
      default:
        this._emitOn = DEFAULT_EMIT_ON;
    }
    this.updateDebouncedObservable(this._emitOn, this.debounceTime);
  }
  public get emitOn(): 'change' | 'enter' {
    return this._emitOn === EmitOnType.CHANGE ? 'change' : 'enter';
  }

  /**
   * Placeholder text of the input element
   */
  @Input() public placeholder: string = DEFAULT_PLACEHOLDER;
  @Input() public size = '';

  /**
   * Emits a distinctly new search term.
   */
  @Output() public searchTermChange = new EventEmitter<string>();

  /**
   * Reference to the input element
   */
  @ViewChild('searchTermInput', { read: ElementRef }) public searchTermInputElement: ElementRef<HTMLInputElement> | null = null;

  public inputHasValue = false;
  public isFocussed = false;
  public searchTerm = new UntypedFormControl('');

  private debounceTime = DEFAULT_DEBOUNCE_TIME;
  private _emitOn: EmitOnType = DEFAULT_EMIT_ON;
  private debounceable$ = new Subject<Observable<string>>();
  private searchTermSubscription: Subscription | null = null;

  public ngOnInit(): void {
    this.searchTermSubscription = this.debounceable$.pipe(switchAll()).subscribe((term) => {
      this.searchTermChange.emit(term);
      this.inputHasValue = !!term;
    });
    this.updateDebouncedObservable(this._emitOn, this.debounceTime);
  }

  public ngOnDestroy(): void {
    // Unsubscribe to the term subscription if required
    if (this.searchTermSubscription && !this.searchTermSubscription.closed) {
      this.searchTermSubscription.unsubscribe();
    }
  }

  public checkForEnter(event: any): void {
    // Listen to the enter key, but ignore all keys if emit on is on change
    if (this._emitOn === 'change') {
      return;
    }

    // Emit the search term on enter
    if (event.key === 'Enter' || event.keyCode === 13) {
      this.searchTermChange.emit(this.searchTerm.value);
    }
  }

  public clearSearchTerm(): void {
    this.searchTerm.reset('');

    // If emit on is manual (i.e. on enter) - emit the change
    if (this._emitOn === 'enter') {
      this.searchTermChange.emit('');
    }
  }

  public focus(): void {
    if (this.searchTermInputElement) {
      this.searchTermInputElement.nativeElement.focus();
    }
  }

  private updateDebouncedObservable(emitOn: EmitOnType, debounce: number): void {
    if (emitOn === EmitOnType.ENTER) {
      this.debounceable$.next(
        this.searchTerm.valueChanges.pipe(
          // HACK: Never emit, as on enter emission is controlled by checkForEnter event handler
          filter((change) => false),
        ),
      );
    } else if (debounce === 0) {
      this.debounceable$.next(this.searchTerm.valueChanges);
    } else {
      this.debounceable$.next(this.searchTerm.valueChanges.pipe<string, string>(debounceTime(debounce), distinctUntilChanged()));
    }
  }
}
