/**
 * Country Risk Index
 */

export enum CountryRiskUpdateCycles {
  quarterly = 'quarterly',
  annual = 'annual',
  atemporal = 'atemporal',
}

export interface CountryRiskIndex {
  /**
   * A description of the index (HTML string)
   */
  description: string;
  /**
   * API link to the index detail
   */
  detail: string;
  /**
   * The index edition
   */
  edition: string;
  /**
   * The editions the index was last published at
   */
  editions: string[];
  /**
   * The index string identifier
   */
  id: string;
  /**
   * The name of the index
   */
  name: string;
  /**
   * ISO date string with date the Risk Index was last viewed.
   * null if the risk index has not been previously viewed by the user.
   */
  last_viewed: string | null;

  // TODO: Seems to be null, based on the latest API response data,
  // when probably it should always be defined

  /**
   * API link to the latest version of this index
   */
  latest: string | null;

  // TODO: The api response contains a "next" property to the next index edition
  // It is undocumented. Also, it seems to contain an empty string, rather than being null
  // when there is no next edition

  /**
   * API link to the next version of this index (null if not available)
   */
  next: string | null;

  /**
   * API link to the previous version of this index (null if not available)
   */
  previous: string | null;
  /**
   * The Verisk Maplecroft default 'theme' assignment for this index
   */
  theme: string;
  /**
   * The Verisk Maplecroft default 'group' assignment for this index
   */
  group: string | null;

  /**
   * The is_subnational property for this index
   */
  is_subnational?: boolean;

  /**
   * A flag indicating that, if true, the user is entitled to this index based on their
   * current subscription. If false, the user must subscribe before they are able to use
   * this index.
   *
   * IMPORTANT: This property is only included in the response, if the Risk Index listing
   * end point was queried with `show_restricted=1` query parameter.
   */
  restricted?: boolean;

  approximate_countries_count: number;

  subgroup: string | null;

  update_cycle: `${CountryRiskUpdateCycles}` | null;
}
