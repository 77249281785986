import { IServerSideGetRowsParams } from '@ag-grid-community/core';
import { mapGridSortModelToApiSortParam } from './map-grid-sort-model-to-api-sort-param.util';
import { updateQueryParametersWithFields } from './update-query-parameters-with-fields.util';
import { updateQueryParametersWithFilters } from './update-query-parameters-with-filters.util';

interface RequestParams {
  page_size: number;
  page: number;
}

interface QueryParams extends RequestParams {
  sort?: string;
}

interface ModelParams {
  mandatoryFields?: string[];
  legacyFilter?: boolean;
}

export function mapGridParamsToQueryParams<T>(
  gridParams: IServerSideGetRowsParams,
  reqParams: T & RequestParams,
  modelParams?: ModelParams,
): T & QueryParams {
  let queryParams: T & QueryParams = { ...reqParams };

  const sort = mapGridSortModelToApiSortParam(gridParams.request.sortModel);

  if (sort) {
    queryParams.sort = sort;
  }

  if (gridParams.request.filterModel) {
    queryParams = updateQueryParametersWithFilters(queryParams, gridParams.request.filterModel, undefined, modelParams?.legacyFilter) as T &
      QueryParams;
  }

  if (modelParams?.mandatoryFields?.length) {
    queryParams = updateQueryParametersWithFields(
      queryParams,
      modelParams.mandatoryFields,
      gridParams.api.getAllDisplayedColumns().map((col) => col.getColId()),
    );
  }

  return queryParams;
}
