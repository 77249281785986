import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@grid-ui/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { WindowUtilitiesService } from '../../../../shared-utilities/window-services/services';

export const URLS_EXCLUDED_LOGIN_REDIRECT_REGEX = [
  /fe-api\/analytics\/events/,
  /v4\/location\/delete-request/,
  /fe-api\/shared-link/,
  /fe-api\/feature-flag/,
  /api\/v3\/auth\/whoami\/user/,
  /api\/v3\/announcements/,
  /fe-api\/module-announcement/,
];

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  public constructor(
    private readonly windowUtilitiesService: WindowUtilitiesService,
    @Inject(ENVIRONMENT_CONFIG) private environmentConfig: any,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const redirecting = this.checkError(error);
        if (!redirecting) {
          return throwError(error);
        }
        // we need to return something to stop Angular cancelling the redirect, however we don't want it actioned on
        // therefore adding a very long delay (20 seconds) to ensure redirect is complete before observable emits
        return of({} as any).pipe(delay(20000));
      }),
    );
  }

  private checkError(error: HttpErrorResponse): boolean {
    if (
      [HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status) &&
      !URLS_EXCLUDED_LOGIN_REDIRECT_REGEX.some((regex) => regex.test(error.url || ''))
    ) {
      const currentLocation = encodeURIComponent(this.windowUtilitiesService.getFullPath());

      let redirectUrl = '/no-access.html';

      if (error.status === HttpStatusCode.Unauthorized) {
        redirectUrl = '/accounts/login';

        if (currentLocation && currentLocation !== '%2F') {
          redirectUrl += `?next=${currentLocation}`;
        }
      }

      this.windowUtilitiesService.setHref(redirectUrl);

      return true;
    }

    return false;
  }
}
