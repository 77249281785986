export enum NotificationBarNotificationType {
  dynamic,
  embedded,
}

export enum NotificationBarEmbeddedNotification {
  dataExtract,
  dataTableExtract,
}

export class NotificationBarNotification {
  public type: NotificationBarNotificationType = NotificationBarNotificationType.dynamic;
  public embedded?: NotificationBarEmbeddedNotification;
  // only used if type is dynamic
  public message?: string;
  // only used if type is dynamic
  public closeMessage?: string | null;

  constructor(message?: string) {
    if (message) {
      this.message = message;
    }
  }
}

// to be used internally in store only, for creating notications use NotificationBarNotification instead
export class NotificationBarNotificationInternal extends NotificationBarNotification {
  public _id: number;
}
