import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { DataWizardUploadMetadata, DataWizardUploadSessionResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataWizardUploadSitesCompleteSessionService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.sessions.session._configuration;
  }

  public startValidation(id: number): Observable<DataWizardUploadMetadata> {
    return this.http.post<DataWizardUploadMetadata>(this.resourceConfig, {
      body: { steps: [1, 2] },
      pathParams: { id },
    });
  }

  public triggerSave(id: number): Observable<DataWizardUploadMetadata> {
    return this.http.post<DataWizardUploadMetadata>(this.resourceConfig, {
      body: { steps: [3] },
      pathParams: { id },
    });
  }

  public getSession(id: number): Observable<DataWizardUploadSessionResponse> {
    return this.http.get<DataWizardUploadSessionResponse>(this.resourceConfig, {
      pathParams: { id },
    });
  }
}
