import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapTooltipComponent } from './map-tooltip/map-tooltip.component';
import { MapsService } from './maps-service.service';

@NgModule({
  imports: [CommonModule],
  providers: [MapsService],
  declarations: [MapTooltipComponent],
  exports: [MapTooltipComponent],
})
export class MapsModule {}
