import { FilterByOption } from '../filter-by-option.interface';
import { IndexInfoPopoverToggleEvent } from '../index-info-popover-event.interface';
import { ItemInformationListingItemDatatype } from '../item-information-listing';
import { SortCriterion } from '../parameterization';
import { RiskViewSelectionDropdownActionsUnion } from '../risk-view-selection-dropdown';
import { AnalyseIndicatorGroupNodeSelection, AnalyseNodeSelection, AnalyseReferenceNodeSelection } from './analyse-node-selection.type';
import { AnalyseScorecardSortColumn } from './analyse-scorecard-sort-column.type';
import { AnalyseScorecardTreeViewType } from './analyse-scorecard-tree-view.type';

export enum AnalyseScorecardActionType {
  TREE_NODE_SELECTED = '[Analyse Scorecard] Select Tree Node',
  TREE_SCROLLED = '[Analyse Scorecard] Scroll Tree',
  TOGGLE_INDEX_INFO_POPOVER = '[Analyse Scorecard] Toggle Risk Index Info',
  TOGGLE_TREE_GROUP_NODE_EXPAND = '[Analyse Scorecard] Toggle Tree Group Node Expand',
  TOGGLE_INDICATOR_SUBTREE_NODE_EXPAND = '[Analyse Scorecard] Toggle the Expansion of an Indicator Subtree Node',
  OPTIONS_CHANGE_FROM = '[Analyse Scorecard] Change "Change From" Edition',
  OPTIONS_TOGGLE_CHANGE_FROM = '[Analyse Scorecard] Toggle Change From',
  OPTIONS_TOGGLE_GROUPED = '[Analyse Scorecard] Toggle Grouped',
  OPTIONS_TOGGLE_HIDE_OTHERS = '[Analyse Scorecard] Toggle Hide Others',
  OPTIONS_TOGGLE_COMPARE = '[Analyse Scorecard] Toggle Compare',
  SCORE_FILTER_CHANGED = '[Analyse Scorecard] Change Score Filter',
  SORT_CHANGED = '[Analyse Scorecard] Change Sort Order',
  RETRY_GET_SCORECARD_DATA = '[Analyse Scorecard] Retry Scorecard Data Load',
  RELOAD_APP = '[Analyse Scorecard] Reload App',
}

/**
 * An action emitted by a scorecard to change the selected tree node.
 * E.g. the Risk Index Scorecard emitting an action to change the selected risk index.
 */
export class ScorecardTreeNodeSelectedAction {
  public readonly type = AnalyseScorecardActionType.TREE_NODE_SELECTED;
  constructor(public payload: { nodeSelection: AnalyseNodeSelection }) {}
}

/**
 * An action emitted by a score card to scroll the tree to new nodes
 */
export class ScorecardTreeScrolledAction {
  public readonly type = AnalyseScorecardActionType.TREE_SCROLLED;
  constructor(public payload: { treeViewType: AnalyseScorecardTreeViewType }) {}
}

/**
 * An action emitted by a scorecard indicating the Risk Index Methodology
 * should be shown for a specific risk index.
 */
export class ScorecardToggleIndexInfoPopoverAction {
  public readonly type = AnalyseScorecardActionType.TOGGLE_INDEX_INFO_POPOVER;
  constructor(public payload: IndexInfoPopoverToggleEvent) {}
}

/**
 * An action emitted by a scorecard indicating that the expansion/collapse
 * status of a regular Group node should be changed.
 */
export class ScorecardToggleTreeGroupNodeExpandAction {
  public readonly type = AnalyseScorecardActionType.TOGGLE_TREE_GROUP_NODE_EXPAND;
  constructor(public payload: { groupNodeId: string; treeView: AnalyseScorecardTreeViewType }) {}
}

/**
 * Action payload for the expansion/collapse action within an indicator subtree.
 */
export interface ScorecardIndicatorSubtreeExpansionPayload {
  /**
   * Target node of expand/collapse action in the indicator subtree. This can be a reference node
   * which supports an indicator subtree (e.g. Risk Index), or an indicator group node.
   */
  targetNode: AnalyseReferenceNodeSelection | AnalyseIndicatorGroupNodeSelection;
  /** Tree view type to address action to */
  treeView: AnalyseScorecardTreeViewType;
}

/**
 * An action emitted by a scorecard indicating that the expansion/collapse
 * status of a an expandable indicator subtree node should be changed.
 * This can be the reference node that serves as the "quasi-root" of the indicator subtree (e.g.
 * a Risk Index), or an Indicator group node.
 */
export class ScorecardToggleIndicatorSubtreeNodeExpandAction {
  public readonly type = AnalyseScorecardActionType.TOGGLE_INDICATOR_SUBTREE_NODE_EXPAND;
  constructor(public payload: ScorecardIndicatorSubtreeExpansionPayload) {}
}

/** Action payload specifying a new "change from" edition */
export interface ScorecardOptionsChangeFromPayload {
  /** "Change From" edition string in YYYY-QQ format, e.g. 2017-Q3 */
  changeFromEdition: string;
  /**
   * Flag indicating whether the Change From view should be automatically
   * toggled on for the scorecard emitting this action if the changeFromEdition
   * (i.e. start edition) is different from the current edition (i.e. end edition).
   */
  toggleOn?: boolean;
}

/** An action emitted by a scorecard to change the "Change From" edition */
export class ScorecardOptionsChangeFromAction {
  public readonly type = AnalyseScorecardActionType.OPTIONS_CHANGE_FROM;
  constructor(public payload: ScorecardOptionsChangeFromPayload) {}
}

/** An action emitted by a scorecard toggling the "change from" view of the scorecard */
export class ScorecardOptionsToggleChangeFromAction {
  public readonly type = AnalyseScorecardActionType.OPTIONS_TOGGLE_CHANGE_FROM;
  constructor() {}
}

/** An action emitted by a scorecard toggling off the "compare" view of the scorecard */
export class ScorecardOptionsToggleCompareAction<T extends ItemInformationListingItemDatatype = ItemInformationListingItemDatatype> {
  public readonly type = AnalyseScorecardActionType.OPTIONS_TOGGLE_COMPARE;
  constructor() {}
}

/** An action emitted by a scorecard toggling the "grouped" option of the scorecard */
export class ScorecardOptionsToggleGroupedAction {
  public readonly type = AnalyseScorecardActionType.OPTIONS_TOGGLE_GROUPED;
  constructor() {}
}

/** An action emitted by a scorecard toggling the "hide others" option of the scorecard */
export class ScorecardOptionsToggleHideOthersAction {
  public readonly type = AnalyseScorecardActionType.OPTIONS_TOGGLE_HIDE_OTHERS;
  constructor() {}
}

/** An action emitted by a scorecard to request a retry of a failed data load*/
export class ScorecardRetryGetData {
  public readonly type = AnalyseScorecardActionType.RETRY_GET_SCORECARD_DATA;
  constructor() {}
}

/** An action emitted by a scorecard to request a reload of the app */
export class ScorecardReloadApp {
  public readonly type = AnalyseScorecardActionType.RELOAD_APP;
  constructor() {}
}

/** An action emitted by a scorecard to indicate a changed sort criterion */
export class ScorecardScoreFilterChanged {
  public readonly type = AnalyseScorecardActionType.SCORE_FILTER_CHANGED;
  constructor(public options: FilterByOption[]) {}
}

/** An action emitted by a scorecard to indicate a changed sort criterion */
export class ScorecardSortChanged {
  public readonly type = AnalyseScorecardActionType.SORT_CHANGED;
  constructor(public sortCriterion: SortCriterion<AnalyseScorecardSortColumn>) {}
}

/** A type union of analyse scorecard options related actions */
export type AnalyseScorecardOptionsActionUnion =
  | ScorecardOptionsChangeFromAction
  | ScorecardOptionsToggleChangeFromAction
  | ScorecardOptionsToggleCompareAction
  | ScorecardOptionsToggleGroupedAction
  | ScorecardOptionsToggleHideOthersAction;

/** A type union of analyse scorecard actions */
export type AnalyseScorecardActionUnion =
  | AnalyseScorecardOptionsActionUnion
  | RiskViewSelectionDropdownActionsUnion<ItemInformationListingItemDatatype>
  | ScorecardTreeNodeSelectedAction
  | ScorecardTreeScrolledAction
  | ScorecardToggleIndexInfoPopoverAction
  | ScorecardToggleTreeGroupNodeExpandAction
  | ScorecardToggleIndicatorSubtreeNodeExpandAction
  | ScorecardRetryGetData
  | ScorecardReloadApp
  | ScorecardScoreFilterChanged
  | ScorecardSortChanged;
