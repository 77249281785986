import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../icon';
import { GlobalLegendExpandedComponent } from './global-legend-expanded/global-legend-expanded.component';
import { GlobalLegendComponent } from './global-legend/global-legend.component';

@NgModule({
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule, IconModule],
  declarations: [GlobalLegendComponent, GlobalLegendExpandedComponent],
  exports: [GlobalLegendComponent, GlobalLegendExpandedComponent],
})
export class GlobalLegendModule {}
