export enum ContentTableColumnType {
  /** Used to render the actions menu */
  ACTIONS = 'actions',
  /** Renders the value with fixed Date pipe */
  DATE = 'date',
  /** Passes the value through AuthorInformationService.completeAuthorInformation and extracts the name */
  AUTHOR_INFORMATION = 'author-information',
  /** Just prints the value */
  PLAIN = 'plain',
  /** Internally used to mark the position in which to show the dropdown if necessary */
  DROPDOWN = 'dropdown',
}
