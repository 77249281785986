import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { IconModule, SharedPipesModule } from '@grid-ui/common';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GlobalModuleAnnouncementFacade } from './data-access/+state/global-module-announcement.facade';
import { DataAccessGlobalModuleAnnouncementModule } from './data-access/data-access-global-module-announcement.module';
import { AnnouncementsDialogComponent } from './feature/components/announcements-dialog/announcements-dialog.component';

export function loadAnnouncements(facade: GlobalModuleAnnouncementFacade): () => Promise<void> {
  return () => {
    facade.loadAnnouncements();
    return Promise.resolve();
  };
}

@NgModule({
  declarations: [AnnouncementsDialogComponent],
  imports: [
    CommonModule,
    DataAccessGlobalModuleAnnouncementModule.forRoot(),
    NgbModalModule,
    NgbTooltipModule,
    SharedPipesModule,
    IconModule,
    FormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadAnnouncements,
      deps: [GlobalModuleAnnouncementFacade],
      multi: true,
    },
  ],
  exports: [AnnouncementsDialogComponent],
})
export class GlobalAnnouncementsModule {}
