import { AnalyseScoreMapActionsUnion } from './analyse-score-map.action';
import { AnalyseOverviewPanelTabType } from './analyse-overview-panel-tab.type';
import { AnalyseScoreTableActionUnion } from './analyse-data-table.action';

export enum AnalyseOverviewDirectActionsType {
  SELECT_TAB = '[Analyse Overview Panel] Select Tab',
}

export class AnalyseOverviewSelectTab {
  public readonly type = AnalyseOverviewDirectActionsType.SELECT_TAB;
  constructor(public payload: { tab: AnalyseOverviewPanelTabType }) {}
}

export type AnalyseOverviewActionsUnion = AnalyseScoreMapActionsUnion | AnalyseScoreTableActionUnion | AnalyseOverviewSelectTab;
