export class GoogleAnalyticsEvent {
  event = 'GAEvent';

  constructor(
    public eventCategory: string,
    public eventAction: string,
    public eventLabel?: string,
    public eventValue?: string,
  ) {}
}
