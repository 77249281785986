import { FeatureLike } from 'ol/Feature';
import { Style } from 'ol/style';
import { StyleColorPalette } from '../color/style-color-palette';

export abstract class LayerStyle {
  constructor(protected colorPalette: StyleColorPalette) {}

  /**
   * Rebuild all styles.
   */
  protected abstract rebuildStyleCache(): void;

  /**
   * Sets the color palette. Used by dark/light toggles.
   * @param colorPalette The color palette.
   */
  public setColorPalette(colorPalette: StyleColorPalette): void {
    this.colorPalette = colorPalette;
    this.rebuildStyleCache();
  }

  /**
   * Gets the color palette set for this style.
   *
   * Useful for unit testing.
   *
   * @returns The color palette.
   */
  public getColorPalette(): StyleColorPalette {
    return this.colorPalette;
  }

  /**
   * The Style function which OpenLayers will need to call.
   *
   * @example
   *
   * const mapStyle = new SitesMapStyle(this.props.colorPalette);
   *
   * new VectorTile({
   *   style: (f: FeatureLike, r: number) => mapStyle.getStyleFunction(f, r)
   * });
   */
  public abstract getStyleFunction(feature: FeatureLike, resolution: number): Style | void;
}
