import { PaginatedV4Response } from '@grid-ui/common';
import { LocationAttributeChoice } from './location-attribute-detail.interface';

export interface LocationAttribute {
  dynamic: boolean;
  global: boolean;
  key: string;
  label: string;
  min_value?: number | null;
  max_value?: number | null;
  required?: boolean;
  attr_type?: string;
}

export interface LocationAttributeWithCount extends LocationAttribute {
  /** Count of currently available choices for this location attribute */
  choicesCount: number;
}

export interface LocationAttributeWithChoices extends LocationAttributeWithCount {
  choices: LocationAttributeChoice[];
}

export enum LocationAttributeTypeEnumV4 {
  integer = 'integer',
  float = 'float',
  decimal = 'decimal',
  string = 'string',
  date = 'date',
  dateTime = 'datetime',
}

export interface LocationAttributeV4 {
  key: string;
  description: string;
  type: `${LocationAttributeTypeEnumV4}`;
  is_reference: boolean;
  distinct_value_count: number;
}

export interface LocationAttributeWithCountV4 extends LocationAttributeV4 {
  choicesCount: number;
}
