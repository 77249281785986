export interface DataAlertDownloadBody {
  notification_id: string;
  fields: string[];
  use_field_labels: boolean;
}

export enum DownloadRequestStatus {
  pending = 'pending',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
}

export interface DataAlertDownloadRequest {
  download_request_id: string;
  status: `${DownloadRequestStatus}`;
  initiated: string;
  url: string;
  download_url: string;
  updated: string;
  created: string;
}
