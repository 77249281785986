import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../icon';
import { LoadingModule } from '../loading';
import { AskAnAnalystButtonComponent } from './ask-an-analyst-button';
import { AskAnAnalystModalComponent } from './ask-an-analyst-modal';
import { AskAnAnalystModalService } from './services';

@NgModule({
  imports: [CommonModule, FormsModule, NgbModalModule, NgbTooltipModule, LoadingModule, IconModule],
  declarations: [AskAnAnalystModalComponent, AskAnAnalystButtonComponent],
  exports: [AskAnAnalystModalComponent, AskAnAnalystButtonComponent],
  providers: [AskAnAnalystModalService],
})
export class AskAnAnalystModule {}
