export enum DataWizardModalActionsEnum {
  Cancel = 'Cancel',
  Close = 'Close',
  Disclaimer = 'Disclaimer',
  SelectDataDownloadType = 'SelectDataDownloadType',
  DataTableExtractInProgress = 'DataTableExtractInProgress',
  DownloadCSV = 'DownloadCSV',
  DownloadXLSX = 'DownloadXLSX',
  DownloadCSV_ZIP = 'DownloadFile',
  ShowDownloadFileAnimation = 'ShowDownloadFileAnimation',
  ExtractComplete = 'ExtractComplete',
  GoToDocumentLibrary = 'DownloadExtract',
  ExtractDownloaded = 'ExtractDownloaded',
  AskForCancelConfirmation = 'AskForCancelConfirmation',
  ContinueExtract = 'ContinueExtract',
  CancelExtract = 'CancelExtract',
}

export type DataWizardModalFileDownloadActionsEnum =
  | DataWizardModalActionsEnum.DownloadCSV
  | DataWizardModalActionsEnum.DownloadCSV_ZIP
  | DataWizardModalActionsEnum.DownloadXLSX;
