import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteBreadcrumb } from '../../../shared-models';

@Component({
  selector: 'mc-top-route-breadcrumbs',
  templateUrl: './top-route-breadcrumbs.component.html',
  styleUrls: ['./top-route-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopRouteBreadcrumbsComponent {
  @Input() breadcrumbs: RouteBreadcrumb[] = [];
  @Input() isHome = false;

  @Output() routeSelected = new EventEmitter<string>();

  public onRouteSelected(route: string): void {
    this.routeSelected.emit(route);
  }

  public trackByIndex(item: RouteBreadcrumb, index: number): number {
    return index;
  }
}
