import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { DownloadResourceService } from '../../../shared-utilities/download-resource';
import { DataWizardUploadSitesFailedResponse } from '../models/data-wizard-upload-sites-failed-response.model';
import { DataWizardUploadSitesFailedQueryParams } from '../models/data-wizard-upload-sites-failed-query-params.model';

@Injectable({
  providedIn: 'root',
})
export class DataWizardUploadSitesFailedService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(
    private readonly downloadResourceService: DownloadResourceService,
    private readonly http: PortalHttpClient,
  ) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.upload.failures._configuration;
  }

  public getFailedSitesAsXlsx(id: string): Observable<Blob> {
    const url = this.resourceConfig.path.replace('{id}', id) + '?reupload=true';
    return this.downloadResourceService.downloadAsXlsx(url);
  }

  public getFailedSites(id: string, queryParams: DataWizardUploadSitesFailedQueryParams): Observable<DataWizardUploadSitesFailedResponse> {
    return this.http.get<DataWizardUploadSitesFailedResponse>(this.resourceConfig, { pathParams: { id }, queryParams });
  }
}
