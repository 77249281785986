import { Injectable } from '@angular/core';
import { ApiLocalCacheEntry } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ApiLocalCacheService {
  private cacheEntries: Map<string, ApiLocalCacheEntry>;

  public constructor() {
    this.cacheEntries = new Map();
  }

  /**
   * Retrieves any cached response for the given key that has not
   * expired and whose queryString matches the specified queryString.
   *
   * @param key
   * @param queryString
   */
  public getValidCachedResponse<T>(key: string, queryString: string | null): T | null {
    const cachedValue = this.cacheEntries.get(key);
    if (cachedValue && this.cachedValueIsValid(cachedValue)) {
      if (this.cachedValueIsValid(cachedValue) && queryString === cachedValue.queryString) {
        return cachedValue.response;
      } else {
        this.cacheEntries.delete(key);
      }
    }
    return null;
  }

  /**
   * Stores a new entry on the cache using any given string as the key, although
   * its intended purpose is to use the path with all path param placeholders replaced
   * with their actual value.
   * The expiration of the new entry is calculated using the specified seconds value.
   * The queryString value is used to discriminate the cached response on retrieval.
   * @param key
   * @param expirationSecs
   * @param queryString
   * @param response
   */
  public cacheResponse(key: string, expirationSecs: number, queryString: string | null, response: any): void {
    this.cacheEntries.set(key, {
      queryString,
      response,
      expiration: new Date().getTime() + expirationSecs * 1000,
    });
  }

  private cachedValueIsValid(cachedValue: ApiLocalCacheEntry): boolean {
    return cachedValue.expiration > new Date().getTime();
  }
}
