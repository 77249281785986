import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LearningItem } from '../../../shared-models';
import { ApiLearningItemCollection } from '../models';

@Injectable()
export class LearningService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly httpClient: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.learning._configuration;
  }

  /** Returns an observable-wrapped array of LearningItem video details. */
  public getTopVideos(module: string, limit: number): Observable<LearningItem[]> {
    return this.httpClient
      .get<ApiLearningItemCollection>(this.resourceConfig, {
        pathParams: { module },
        queryParams: { max: limit },
      })
      .pipe(map((body) => body.results));
  }
}
