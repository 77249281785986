// Custom AG Grid Icons
// We can set the icon fill, stroke and background colors using classes defined in _ag-grid-custom.scss

// Column header sort icons
export const SORT_ICON_TEXT_ASC = `<svg xmlns="http://www.w3.org/2000/svg" class="icon-ag-sort-background-color" width="16px" height="16px" viewBox="0 0 13.643 15">
  <g id="Group_21470" data-name="Group 21470" transform="translate(0.387)">
    <text class="icon-ag-sort-fill" id="A" transform="translate(8.022 6)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">A</tspan></text>
    <text class="icon-ag-sort-fill" id="Z" transform="translate(8.256 13)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">Z</tspan></text>
    <g id="Group_21469" data-name="Group 21469" transform="translate(20595.377 12255)">
      <line class="icon-ag-sort-stroke" id="Line_694" data-name="Line 694" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-sort-stroke" id="Path_8387" data-name="Path 8387" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;
export const SORT_ICON_TEXT_DESC = `<svg xmlns="http://www.w3.org/2000/svg" class="icon-ag-sort-background-color" width="16px" height="16px" viewBox="0 0 13.643 15">
  <g id="Group_21474" data-name="Group 21474" transform="translate(20595.764 12257)">
    <text class="icon-ag-sort-fill" id="A" transform="translate(-20587.355 -12244)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">A</tspan></text>
    <text class="icon-ag-sort-fill" id="Z" transform="translate(-20587.121 -12251)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">Z</tspan></text>
    <g id="Group_21473" data-name="Group 21473" transform="translate(0 -2)">
      <line class="icon-ag-sort-stroke" id="Line_696" data-name="Line 696" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-sort-stroke" id="Path_8389" data-name="Path 8389" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;
export const SORT_ICON_TEXT_UNSORT = `<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 13.643 15">
  <g id="Group_21470" data-name="Group 21470" transform="translate(0.387)">
    <text class="icon-ag-unsort-fill" id="A" transform="translate(8.022 6)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">A</tspan></text>
    <text class="icon-ag-unsort-fill" id="Z" transform="translate(8.256 13)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">Z</tspan></text>
    <g id="Group_21469" data-name="Group 21469" transform="translate(20595.377 12255)">
      <line class="icon-ag-unsort-stroke" id="Line_694" data-name="Line 694" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-unsort-stroke" id="Path_8387" data-name="Path 8387" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;
export const SORT_ICON_NUM_ASC = `<svg xmlns="http://www.w3.org/2000/svg" class="icon-ag-sort-background-color" width="16px" height="16px" viewBox="0 0 13.756 15">
  <g id="Group_21472" data-name="Group 21472" transform="translate(20595.764 12257)">
    <text class="icon-ag-sort-fill" id="_1" data-name="1" transform="translate(-20587.008 -12251)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">1</tspan></text>
    <text class="icon-ag-sort-fill" id="_9" data-name="9" transform="translate(-20587.008 -12244)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">9</tspan></text>
    <g id="Group_21471" data-name="Group 21471" transform="translate(0 -2)">
      <line class="icon-ag-sort-stroke" id="Line_695" data-name="Line 695" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-sort-stroke" id="Path_8388" data-name="Path 8388" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;
export const SORT_ICON_NUM_DESC = `
<svg xmlns="http://www.w3.org/2000/svg" class="icon-ag-sort-background-color" width="16px" height="16px" viewBox="0 0 13.756 15">
  <g id="Group_21476" data-name="Group 21476" transform="translate(20595.764 12257)">
    <text class="icon-ag-sort-fill" id="_1" data-name="1" transform="translate(-20587.008 -12244)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">1</tspan></text>
    <text class="icon-ag-sort-fill" id="_9" data-name="9" transform="translate(-20587.008 -12251)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">9</tspan></text>
    <g id="Group_21475" data-name="Group 21475" transform="translate(0 -2)">
      <line class="icon-ag-sort-stroke" id="Line_697" data-name="Line 697" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-sort-stroke" id="Path_8390" data-name="Path 8390" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;
export const SORT_ICON_NUM_UNSORT = `
<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 13.756 15">
  <g id="Group_21472" data-name="Group 21472" transform="translate(20595.764 12257)">
    <text class="icon-ag-unsort-fill" id="_1" data-name="1" transform="translate(-20587.008 -12251)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">1</tspan></text>
    <text class="icon-ag-unsort-fill" id="_9" data-name="9" transform="translate(-20587.008 -12244)" font-size="7" font-family="Roboto" font-weight="700"><tspan x="0" y="0">9</tspan></text>
    <g id="Group_21471" data-name="Group 21471" transform="translate(0 -2)">
      <line class="icon-ag-unsort-stroke" id="Line_695" data-name="Line 695" y2="11" transform="translate(-20592.5 -12254.5)" fill="none" stroke-width="1"/>
      <path class="icon-ag-unsort-stroke" id="Path_8388" data-name="Path 8388" d="M-20598.377-12234.15l2.873,3.484,2.873-3.484" transform="translate(3 -12)" fill="none" stroke-width="1"/>
    </g>
  </g>
</svg>`;

export const SWAP_ICON =
  '<img src="assets/img/icons/swap.svg" style="height: 18px; width: 18px;padding: 2px; background-color: #2671cb; border-radius: 5px;"/>';

export const TEXT_CUSTOM_ICONS = {
  sortAscending: SORT_ICON_TEXT_ASC,
  sortDescending: SORT_ICON_TEXT_DESC,
  sortUnSort: SORT_ICON_TEXT_UNSORT,
  swapIcon: SWAP_ICON,
};

export const NUMERIC_CUSTOM_ICONS = {
  sortAscending: SORT_ICON_NUM_ASC,
  sortDescending: SORT_ICON_NUM_DESC,
  sortUnSort: SORT_ICON_NUM_UNSORT,
};

// Row group expanded icons
export const GROUP_CUSTOM_ICONS = {
  groupExpanded: '<img src="assets/img/icons/chevron-up.svg" style="height: 8px; width: 8px; transform: rotate(180deg)"/>',
  groupContracted: '<img src="assets/img/icons/chevron-up.svg" style="height: 8px; width: 8px; transform: rotate(90deg)"/>',
};
