<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="'grid-toast ' + toast.classname"
  [autohide]="true"
  [delay]="toast.delay"
  (hidden)="toastService.remove(toast)"
>
  <ng-container *ngIf="toast.type === 'error'">
    <svg class="me-3">
      <use xlink:href="#shape-exclamation-rounded-primary" />
    </svg>
  </ng-container>
  <ng-container *ngIf="toast.type === 'success'">
    <svg class="me-3" xmlns="http://www.w3.org/2000/svg" width="14.224" height="11.482" viewBox="0 0 14.224 11.482">
      <path
        id="Tick"
        d="M-145.433,1456.218l2.808,2.807,2.978-2.977,4.377-4.377"
        transform="translate(-56.419 -1454.771) rotate(-8)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  </ng-container>
  <ng-container *ngIf="toast.type === 'custom'">
    <div [innerHTML]="sanitizeHtml(toast.content)"></div>
  </ng-container>
  <ng-container *ngIf="toast.type === 'success' || toast.type === 'error'">
    {{ toast.content }}
    <grid-ui-icon type="button" class="close-icon ms-3" aria-label="Dismiss" (click)="toastService.remove(toast)" name="cross">
    </grid-ui-icon>
  </ng-container>
</ngb-toast>
