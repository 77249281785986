import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  Country,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiCountriesCollection } from '../../countries';

/**
 * Service for accessing the Commotity Countries
 */
@Injectable()
export class CommodityRiskCountriesService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.commodityRisk.countries._configuration;
  }

  public getCountries(searchParam?: any | null, options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<Country[]> {
    const countries$: Observable<Country[]> = this.http
      .get<ApiCountriesCollection>(this.resourceConfig, options)
      .pipe(map((countriesCollection) => countriesCollection.results));

    if (searchParam) {
      return this.filterCountries(countries$, searchParam);
    } else {
      return countries$;
    }
  }

  private filterCountries(countries$: Observable<Country[]>, searchParam: any): Observable<Country[]> {
    countries$ = countries$.pipe(map((countries) => countries.filter((country) => country.name.toLocaleLowerCase().includes(searchParam))));
    return countries$;
  }
}
