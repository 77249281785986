import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlobalRoutingStateEffects } from './data-access/+state/routing-state.effects';
import { GLOBAL_ROUTING_FEATURE_KEY, globalRoutingReducerFn } from './data-access/+state/routing-state.reducer';
import { RoutingComponent } from './feature/routing.component';

const STORE_MODULES = [
  StoreModule.forFeature(GLOBAL_ROUTING_FEATURE_KEY, globalRoutingReducerFn),
  EffectsModule.forFeature([GlobalRoutingStateEffects]),
];

@NgModule({
  declarations: [RoutingComponent],
  imports: [STORE_MODULES, CommonModule],
  exports: [RoutingComponent],
})
export class GlobalRoutingModule {
  static forRoot(): ModuleWithProviders<GlobalRoutingModule> {
    return {
      ngModule: GlobalRoutingModule,
    };
  }
}
