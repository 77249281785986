<div class="mc-header-area align-items-center" [ngClass]="{ home: route === '/home' }">
  <div class="header-content">
    <mc-top-route-breadcrumbs
      [breadcrumbs]="breadcrumbs$ | async"
      (routeSelected)="triggerRouteChange($event)"
      [isHome]="route === '/home'"
    >
    </mc-top-route-breadcrumbs>
  </div>

  <!-- If a feature env, the branch name is displayed. -->
  <div *ngIf="environmentHeaderHint" class="environment-header-hint" data-test="environment-header-hint">
    <span>{{ environmentHeaderHint }}</span>
  </div>

  <div class="header-profile">
    <div class="header-menu">
      <mc-top-user-menu (menuActionEmit)="handleMenuAction($event)" [isHome]="route === '/home'"> </mc-top-user-menu>
    </div>
    <mc-top-right-help-menu [helpMenuRoutes]="helpMenuRoutes" [isHome]="route === '/home'"></mc-top-right-help-menu>
  </div>
</div>
