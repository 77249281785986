import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PollingEffects } from './store/effects/polling.effects';
import { reducers } from './store/reducers';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('polling', reducers), EffectsModule.forFeature([PollingEffects])],
  declarations: [],
})
export class PollingModule {}
