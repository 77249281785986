import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GridCommonModule } from '@grid-ui/common';
import { WhoAmIService } from '@grid-ui/whoami';
import { AnnouncementBarService } from './announcement-bar';
import { AnnouncementService } from './announcements/service/announcements.service';
import { CommodityRiskCommoditiesService } from './commodity-risk-commodities';
import { CommodityRiskCountriesService } from './commodity-risk-countries';
import { CommodityRiskFeaturedContentService } from './commodity-risk-featured-content';
import { CommodityRiskViewsService } from './commodity-risk-views/service/commodity-risk-views.service';
import { CountriesService } from './countries';
import { CountryRiskFeaturedContentService } from './country-risk-featured-content';
import { CountryRiskIndicesService } from './country-risk-indices';
import { CountryRiskProfilesService } from './country-risk-profiles';
import { CountryRiskRecentViewsService } from './country-risk-recent-views';
import { CountryRiskDynamicViewsService, CountryRiskViewsService } from './country-risk-views';
import { CountryRiskViewsIndicesTreeService } from './country-risk-views-indices-tree';
import { CountryRiskViewsIndicesTreesSearchService } from './country-risk-views-indices-trees-search';
import { CountryRiskViewsLocationsTreeService } from './country-risk-views-locations-tree';
import { CountryRiskViewsLocationsTreesSearchService } from './country-risk-views-locations-trees-search';
import { CountryRiskViewsScoreMapService } from './country-risk-views-score-map';
import { CurrentEditionService } from './current-edition';
import { DataWizardExtractService } from './data-wizard-extract';
import {
  DataWizardUploadMetadataService,
  DataWizardUploadSitesColumnMappingService,
  DataWizardUploadSitesCompleteSessionService,
  DataWizardUploadSitesSpreadsheetService,
} from './data-wizard-upload';
import { DocumentLibraryService } from './document-library';
import { HttpDeprecatedBlockInterceptor } from './http-deprecated-block-interceptor';
import { LearningService } from './learning';
import { SubscriptionService } from './my-subscriptions/service/subscription.service';
import { RegistrationService } from './registration';
import { ReleaseNoteService } from './release-notes/service/release-note.service';
import { RiskInsightsService } from './risk-insights';

@NgModule({
  imports: [CommonModule, GridCommonModule],
  declarations: [],
})
export class ApiServicesModule {
  static forRoot(): ModuleWithProviders<ApiServicesModule> {
    return {
      ngModule: ApiServicesModule,
      providers: [
        AnnouncementService,
        CommodityRiskCommoditiesService,
        CommodityRiskCountriesService,
        CommodityRiskFeaturedContentService,
        CommodityRiskViewsService,
        CountriesService,
        CountryRiskDynamicViewsService,
        CountryRiskFeaturedContentService,
        CountryRiskIndicesService,
        CountryRiskProfilesService,
        CountryRiskRecentViewsService,
        CountryRiskViewsIndicesTreeService,
        CountryRiskViewsLocationsTreeService,
        CountryRiskViewsScoreMapService,
        CountryRiskViewsIndicesTreesSearchService,
        CountryRiskViewsLocationsTreesSearchService,
        CountryRiskViewsService,
        CurrentEditionService,
        DataWizardExtractService,
        DataWizardUploadSitesColumnMappingService,
        DataWizardUploadSitesSpreadsheetService,
        DataWizardUploadSitesCompleteSessionService,
        DataWizardUploadMetadataService,
        DocumentLibraryService,
        LearningService,
        SubscriptionService,
        WhoAmIService,
        AnnouncementBarService,
        ReleaseNoteService,
        RegistrationService,
        RiskInsightsService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpDeprecatedBlockInterceptor, multi: true },
      ],
    };
  }
}
