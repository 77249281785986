import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  public constructor(private sanitizer: DomSanitizer) {}

  public transform(value: any, args: any, onlyFirstMatch: boolean = true): SafeHtml {
    if (!args) {
      return value;
    }

    // Match in a case insensitive maneer
    const re = new RegExp(args, onlyFirstMatch ? 'i' : 'gi');
    const match = value.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    const value2 = value.replace(re, '<b style="color: black !important;">' + match[0] + '</b>');

    return this.sanitizer.bypassSecurityTrustHtml(value2);
  }
}
