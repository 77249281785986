import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../shared-components';
import { ToastContainerComponent } from './toast-container/toast-container.component';

@NgModule({
  declarations: [ToastContainerComponent],
  imports: [CommonModule, NgbToastModule, IconModule],
  exports: [ToastContainerComponent],
})
export class ToastModule {}
