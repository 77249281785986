import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HighlightSearchPipe } from './highlight.pipe';
import { PrefixPipe } from './prefix.pipe';
import { RemoveSimpleHtmlTagsPipe } from './remove-simple-html-tags.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ThousandSuffixesPipe } from './thousand-suffixes.pipe';
import { TimesPipe } from './times.pipe';
import { ToggleColumnsPipe } from './toggle-columns.pipe';
import { TruncatePipe } from './truncate.pipe';

const components = [
  SafeHtmlPipe,
  SafeUrlPipe,
  TruncatePipe,
  HighlightSearchPipe,
  PrefixPipe,
  RemoveSimpleHtmlTagsPipe,
  TimesPipe,
  ToggleColumnsPipe,
  ThousandSuffixesPipe,
];

@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
  providers: [DecimalPipe],
})
export class SharedPipesModule {}
