export enum AnalyseScoreMapActionTypes {
  CHANGE_WORLD_VIEW = '[Analyse Score Map] Change World View',
  RELOAD_APP = '[Analyse Score Map] Reload App',
  RETRY_MAP_LOAD = '[Analyse Score Map] Retry Loading Map',
}

export class AnalyseScoreMapChangeWorldView {
  public readonly type = AnalyseScoreMapActionTypes.CHANGE_WORLD_VIEW;
  constructor(public payload: { worldViewSelected: boolean }) {}
}

export class AnalyseScoreMapReloadApp {
  public readonly type = AnalyseScoreMapActionTypes.RELOAD_APP;
  constructor() {}
}

export class AnalyseScoreMapRetryMapLoad {
  public readonly type = AnalyseScoreMapActionTypes.RETRY_MAP_LOAD;
  constructor() {}
}

export type AnalyseScoreMapActionsUnion = AnalyseScoreMapChangeWorldView | AnalyseScoreMapReloadApp | AnalyseScoreMapRetryMapLoad;
