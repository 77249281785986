import { AnalyseNonEntitledScores } from './analyse-non-entitled-scores.interface';
import { AnalyseDataTableNonHistoricalScores } from './analyse-data-table-flat-tree-node.interface';
import { AnalyseDataTableHistoricalScores } from './analyse-data-table-historical-flat-tree-node.interface';

export function isNotEntitledAnalyseDataTableNonHistoricalScores(
  scores: AnalyseDataTableNonHistoricalScores,
): scores is AnalyseNonEntitledScores {
  return (<AnalyseNonEntitledScores>scores).entitled === false;
}

export function isNotEntitledAnalyseDataTableHistoricalScores(
  scores: AnalyseDataTableHistoricalScores,
): scores is AnalyseNonEntitledScores {
  return (<AnalyseNonEntitledScores>scores).entitled === false;
}

export function isAnalyseDataTableNonEntitledScores(
  scores: AnalyseDataTableNonHistoricalScores | AnalyseDataTableHistoricalScores,
): scores is AnalyseNonEntitledScores {
  return (<AnalyseNonEntitledScores>scores).entitled === false;
}
