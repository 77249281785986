import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  CountryRiskConfigurationDetailLocationTreeResourcesConfig,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PathParams,
  PortalHttpClient,
  QueryParams,
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyseScoreMap, ScoreMapRequestParams } from '../../../shared-models';
import { ApiAnalyseScoreMap } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CountryRiskViewsScoreMapService {
  private locationTreeResourceConfig: CountryRiskConfigurationDetailLocationTreeResourcesConfig;
  private siteScoreResourceConfig: NonPaginatedResourceConfig;

  private readonly siteMapFixedQueryParams: QueryParams = { hide_others: 'true', page: '1', page_size: 1 };

  constructor(private readonly http: PortalHttpClient) {
    this.locationTreeResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.locationTree;
    this.siteScoreResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.indexTree.siteScore._configuration;
  }

  public getScores(
    reqParams: ScoreMapRequestParams,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<AnalyseScoreMap> {
    const { configurationId, group, index_group, index, all_countries, edition } = reqParams;
    const queryParams = {
      ...(index_group ? { index_group } : {}),
      ...(index ? { index } : {}),
      ...(all_countries ? { all_countries } : {}),
      ...(edition ? { edition } : {}),
    };
    let resourceConfig: NonPaginatedResourceConfig;
    let pathParams: PathParams;
    if (group) {
      resourceConfig = this.locationTreeResourceConfig.groupScoreMap._configuration;
      pathParams = { viewId: configurationId, group };
    } else {
      resourceConfig = this.locationTreeResourceConfig.scoreMap._configuration;
      pathParams = { viewId: configurationId };
    }
    return this.http
      .get<ApiAnalyseScoreMap>(resourceConfig, {
        ...options,
        queryParams,
        pathParams,
      })
      .pipe(
        map((apiScoreMap) => ({
          ...apiScoreMap,
          // TODO: P2-3111 Remove 1 fallback, once API is always providing scoring_progress property
          scoring_progress: typeof apiScoreMap.scoring_progress === 'number' ? apiScoreMap.scoring_progress : 1,
        })),
      );
  }
}
