<div *ngIf="loadingStatus === RequestStatus.loaded" class="mc-content mb-6">
  <div class="background-gradient"></div>
  <div class="mc-content-header">
    <div class="heading-section">
      <div class="header">
        <div class="heading-text-container">
          <img class="heading-logo" src="{{ 'vm-logo-tm.svg' | mcImageUrl }}" alt="Verisk Maplecroft" />
          <h1 class="title">
            {{ heading }}<span class="splash-head">&nbsp;{{ splashPageHeading }}</span>
          </h1>
        </div>
        <div class="index-catalogue-container">
          <div class="index-catalogue-header-container">
            <h2 class="index-catalogue-title">Index Catalogue</h2>
          </div>
          <div class="index-catalogue-body-container">
            <div class="index-catalogue-text">
              <span>The <b>Index Catalogue</b> provides detailed information about each of Verisk Maplecroft's indices</span>
            </div>
            <div class="index-catalogue-download">
              <button class="btn mc-btn-primary download-index-catalogue-button">
                <a
                  class="not-text-link"
                  download
                  rel="noopener noreferrer"
                  data-hook="index-catalogue-download-link"
                  [href]="indexCatalogueDownloadURL"
                >
                  Download Index Catalogue (.xlsx)
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-container" [style.width.px]="cardContainerWidth">
    <mc-home-service-offering-card
      *ngFor="let item of permittedServiceOfferingCards"
      (navClick)="navigate($event)"
      [card]="item"
      [imageHref]="item.imageHref"
    >
    </mc-home-service-offering-card>
  </div>
</div>
<div *ngIf="loadingStatus === RequestStatus.loading" class="grid-ui-loading-container">
  <grid-ui-loading></grid-ui-loading>
</div>
<div *ngIf="loadingStatus === RequestStatus.error" class="grid-ui-error-container">
  <grid-ui-error
    [errorCode]="error!.errorCode"
    [errorMessage]="error!.errorMessage"
    [errorPrimaryAction]="error!.errorPrimaryAction"
    [errorSecondaryAction]="error!.errorSecondaryAction"
    [showContact]="error!.showContact"
    [contactEmail]="error!.contactEmail"
    (primaryActionClick)="setServiceOfferings()"
    (secondaryActionClick)="reloadApp()"
  >
  </grid-ui-error>
</div>
