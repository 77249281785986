<mc-notification-bar (isVisible)="handleNotificationBarUpdate($event)"></mc-notification-bar>

<div [class.notification-bar-open]="notificationBarOpen">
  <ng-container *ngIf="isPreviewMode">
    <mc-preview-mode-watermark></mc-preview-mode-watermark>
  </ng-container>
  <mc-app-top-area (actionEmit)="handleTopMenuAction($event)"> </mc-app-top-area>
  <div class="mc-main-area-container" [class.with-notification-bar]="notificationBarOpen">
    <mc-app-side-area
      [class.mc-side-navigation-shown]="!isSplashScreen"
      [showSideNavigation]="!isSplashScreen"
      [isNotificationBarOpen]="notificationBarOpen"
    >
    </mc-app-side-area>
    <div class="mc-main-area" [class.mc-side-navigation-shown]="!isSplashScreen">
      <div *ngIf="loading">
        <div class="loading-wrapper d-flex justify-content-center align-items-center">
          <grid-ui-loading [size]="'large'"></grid-ui-loading>
        </div>
      </div>
      <div [hidden]="loading" class="mc-main-area-loading-container" [class.announcement-active]="announcementBarActive">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<grid-ui-toast-container></grid-ui-toast-container>
<mc-cookies-notification-banner
  *ngIf="privacySettingsStorageService.showCookiesBannerChanges | async"
  (action)="handleCookiesNotificationBannerAction($event)"
>
</mc-cookies-notification-banner>
