import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from '../../shared-pipes';
import { CitationModule } from '../citation';
import { IconModule } from '../icon';
import { WrittenContentAnchorClickListenerModule } from '../listeners/written-content-anchor-click-listener';
import { LoadingModule } from '../loading';
import { ScorePanelImageDownloadButtonModule } from '../score-panel-image-download-button';
import { VegaChartModule } from '../vega-chart';
import { BodyItemComponent } from './body-item';
import { BodyItemBlockLayoutComponent } from './body-item-block-layout';
import { BodyItemFocusboxComponent } from './body-item-focusbox';
import { BodyItemExpandedImageComponent, BodyItemImageComponent } from './body-item-image';
import { BodyItemLopTableComponent } from './body-item-lop-table';
import { BodyItemMajorRisksComponent } from './body-item-major-risks';
import { BodyItemOutlookComponent } from './body-item-outlook';
import { BodyItemParagraphComponent } from './body-item-paragraph';
import { BodyItemQuoteComponent } from './body-item-quote';
import { BodyItemExpandedScorecardComponent, BodyItemScorecardComponent } from './body-item-scorecard';
import { BodyItemTableComponent } from './body-item-table';
import { BodyItemVideoComponent, BodyItemVideoPlayerComponent } from './body-item-video';
import { UpdateLinkPipe } from './pipes/update-link.pipe';

const components = [
  BodyItemComponent,
  BodyItemBlockLayoutComponent,
  BodyItemFocusboxComponent,
  BodyItemExpandedImageComponent,
  BodyItemExpandedScorecardComponent,
  BodyItemImageComponent,
  BodyItemLopTableComponent,
  BodyItemMajorRisksComponent,
  BodyItemOutlookComponent,
  BodyItemParagraphComponent,
  BodyItemQuoteComponent,
  BodyItemScorecardComponent,
  BodyItemTableComponent,
  BodyItemVideoComponent,
  BodyItemVideoPlayerComponent,
  UpdateLinkPipe,
];

@NgModule({
  imports: [
    CommonModule,
    WrittenContentAnchorClickListenerModule,
    CitationModule,
    LoadingModule,
    ScorePanelImageDownloadButtonModule,
    SharedPipesModule,
    VegaChartModule,
    IconModule,
    NgbTooltipModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class BodyItemModule {}
