export enum PrivacySettingsActionTypes {
  ACCEPT = '[Privacy Settings] Accept Cookies',
}

export class PrivacySettingsAcceptAction {
  public readonly type = PrivacySettingsActionTypes.ACCEPT;
  constructor() {}
}

export type PrivacySettingsActionsUnion = PrivacySettingsAcceptAction;
