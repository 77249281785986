import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { DataWizardUploadSitesColumnMapping, DataWizardUploadSitesColumnMappingResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataWizardUploadSitesColumnMappingService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.sessions.session._configuration;
  }

  public updateColumnMappings(
    columnMapping: DataWizardUploadSitesColumnMapping,
    id: number,
  ): Observable<DataWizardUploadSitesColumnMappingResponse> {
    return this.http.patch<DataWizardUploadSitesColumnMappingResponse>(this.resourceConfig, {
      body: columnMapping,
      pathParams: { id },
    });
  }
}
