import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingNavItem } from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import { PermissionSelector } from '@grid-ui/permissions';

@Component({
  selector: 'mc-top-right-help-menu',
  templateUrl: './top-right-help-menu.component.html',
  styleUrls: ['./top-right-help-menu.component.scss'],
})
export class TopRightHelpMenuComponent {
  @Input() helpMenuRoutes: RoutingNavItem[] | null = null;
  @Input() isHome = false;

  public readonly PermissionSelector = PermissionSelector;
  public isMenuOpen = false;

  constructor(private readonly router: Router) {}

  public onMouseEnter(): void {
    this.isMenuOpen = true;
  }

  public onMouseLeave(): void {
    this.closeMenu();
  }

  public onRouteSelected(route: any[]): void {
    this.closeMenu();
    this.router.navigate([route[0]]);
  }

  public get industryApiDocLink(): string {
    return environment.industry.frontendApi + 'industry-api-doc.pdf';
  }

  private closeMenu(): void {
    this.isMenuOpen = false;
  }
}
