import { Component, Input } from '@angular/core';

@Component({
  selector: 'grid-ui-global-legend-expanded',
  templateUrl: './global-legend-expanded.component.html',
  styleUrls: ['./global-legend-expanded.component.scss'],
})
export class GlobalLegendExpandedComponent {
  // when enabled it shows a lighter version of the index explanation.
  @Input() public lite = false;

  public get indexExplanation(): string {
    const text =
      'The index score is presented on a scale of 0.00-10.00, ' + 'where 0.00 represents highest risk and 10.00 represents lowest risk.';

    return this.lite ? text : text + ' The risk category is based on the index score as follows:';
  }
}
