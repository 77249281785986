import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiWhoAmI, ApiWhoAmIUserPersonalInformation, UserOrganisation, UserPermissions, WhoAmI, WhoAmILimits } from '@grid-ui/api-models';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { WhoAmIUserPersonalInformation, WhoAmIUserPersonalInformationChange } from '../models';

// TODO: Implement caching once ETags are implemented server-side. See P2-1683 and P2-1684

@Injectable()
export class WhoAmIService {
  private resourceConfig: NonPaginatedResourceConfig;
  private userResourceConfig: NonPaginatedResourceConfig;

  private currentWhoAmIRequest$: Observable<WhoAmI> | null = null;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.whoAmI._configuration;
    this.userResourceConfig = API_SERVICES_CONFIG.v3.whoAmI.user._configuration;
  }

  /**
   * Save changes to the user personal information.
   */
  public changeUserPersonalInformation(change: WhoAmIUserPersonalInformationChange): Observable<WhoAmIUserPersonalInformation> {
    return this.http.patch<ApiWhoAmIUserPersonalInformation>(this.userResourceConfig, {
      body: change,
      retryOptions: {
        customRetryAttempts: 0,
      },
    });
  }

  /**
   * Get user permissions for Maplecroft service offerings.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getPermissions(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<UserPermissions> {
    return this.getWhoAmI(options).pipe(map((whoAmI) => whoAmI.permissions));
  }

  /**
   * Get Account number for Maplecroft user
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getOrganisation(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<UserOrganisation> {
    return this.getWhoAmI(options).pipe(
      map((whoAmI: WhoAmI) => ({
        accountIdentifier: whoAmI.account_uuid || '',
        internalAccount: whoAmI.is_internal,
        organisation: whoAmI.organisation,
        userIdentifier: whoAmI.user_uuid || '',
      })),
    );
  }

  /**
   * Get current user personal information.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getUserPersonalInformation(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  ): Observable<WhoAmIUserPersonalInformation> {
    return this.http.get<ApiWhoAmIUserPersonalInformation>(this.userResourceConfig, options);
  }

  /**
   * Get a WhoAmI information.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getWhoAmI(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<WhoAmI> {
    // TODO: Implement caching once ETags are supported server-side. See P2-1683 and P2-1684
    if (this.currentWhoAmIRequest$) {
      return this.currentWhoAmIRequest$;
    } else {
      const whoAmI$ = this.http.get<ApiWhoAmI>(this.resourceConfig, options).pipe(
        share(),
        tap(() => (this.currentWhoAmIRequest$ = null)),
        catchError((err) => {
          this.currentWhoAmIRequest$ = null;
          return throwError(err);
        }),
      );
      this.currentWhoAmIRequest$ = whoAmI$;
      return whoAmI$;
    }
  }

  public getWhoAmILimits(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<WhoAmILimits> {
    return this.getWhoAmI(options).pipe(map((whoAmI: WhoAmI) => whoAmI.limits));
  }

  public isCurrentUserLoggedIn(): Observable<boolean> {
    return this.getUserPersonalInformation({
      retryOptions: { customRetryAttempts: 1 },
    }).pipe(
      catchError((e) => of(e.status !== HttpStatusCode.Unauthorized)),
      map((v) => !!v),
    );
  }
}
