export const CREATE_LOCATION_ROOT_PROPERTIES = [
  'attributes',
  'city',
  'country_code',
  'country_name',
  'customer_id',
  'latitude',
  'longitude',
  'name',
  'postcode',
  'region',
];

export interface ApiCreateLocation {
  attributes?: { [key: string]: any };
  city?: string;
  country_code?: string;
  country_name?: string;
  customer_id: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  postcode?: string;
  region?: string;
}
