import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingRequestStatus, RoutingNavItem } from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { RouteBreadcrumb } from '../../../shared-models';
import { TopUserMenuActionsUnion } from '../../models';
import { TopBreadcrumbTrailService } from '../../services';

export const HOME_ROUTE_BREADCRUMB: RouteBreadcrumb = {
  label: 'GRiD',
  route: '/',
};

@Component({
  selector: 'mc-app-top-area',
  templateUrl: './app-top-area.component.html',
  styleUrls: ['./app-top-area.component.scss'],
})
export class AppTopAreaComponent {
  @Output() actionEmit = new EventEmitter<TopUserMenuActionsUnion>();

  public breadcrumbs$: Observable<RouteBreadcrumb[]> = of([]);
  public readonly RequestStatus = LoadingRequestStatus;
  public readonly helpMenuRoutes: RoutingNavItem[] = [
    {
      name: 'FAQ',
      link: ['learn/faq'],
    },
    {
      name: 'Learn',
      link: ['learn/videos'],
    },
  ];
  public status: LoadingRequestStatus = LoadingRequestStatus.loading;
  public route = '/home';

  /**
   * If an environment header hint is provided,
   * we'll display it in the header. This was
   * written for feature environments to quickly
   * see what we're working on.
   */
  public environmentHeaderHint?: string = environment.environmentHeaderHint;

  constructor(
    private readonly router: Router,
    private readonly breadcrumbTrailService: TopBreadcrumbTrailService,
  ) {
    this.breadcrumbs$ = this.router.events.pipe(
      debounceTime(300),
      map(() =>
        this.breadcrumbTrailService.createBreadcrumbsTrail(this.router.routerState.snapshot.root, this.router.routerState.snapshot, [
          HOME_ROUTE_BREADCRUMB,
        ]),
      ),
    );

    this.router.events.pipe(filter<NavigationEnd>((e) => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.route = event.urlAfterRedirects;
    });
  }

  public handleMenuAction(action: TopUserMenuActionsUnion): void {
    this.actionEmit.emit(action);
  }

  public triggerRouteChange(newRoute: string): void {
    this.router.navigateByUrl(newRoute);
  }
}
