import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VegaChartComponent } from './vega-chart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [VegaChartComponent],
  exports: [VegaChartComponent],
})
export class VegaChartModule {}
