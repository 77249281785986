type RGB = [number, number, number];

/**
 * A risk color palette represented as RGB values.
 *
 * The first array is high risk, the last is low risk.
 *
 * @example
 *
 * export const defaultRiskColorPalette: RiskColorPalette = [
 *     [255, 0, 0],    // Red
 *     [255, 165, 0],  // Orange
 *     [255, 255, 0],  // Yellow
 *     [0, 128, 0]     // Green
 * ];
 */
export type RiskColorPalette = RGB[];

/**
 * Over saturated for compatibility with lower opacities by default.
 *
 * Red, orange, yellow, green.
 */
export const vibrantRiskColorPalette: RiskColorPalette = [
  [255, 0, 0], // Red
  [255, 165, 0], // Orange
  [255, 255, 0], // Yellow
  [0, 128, 0], // Green
];

/**
 * Maplecroft's default subnational risk colour scale.
 * Red, orange, yellow, green.
 */
export const subnationalRiskColorPalette: RiskColorPalette = [
  [255, 0, 0], // Red
  [245, 152, 59], // Orange
  [250, 246, 140], // Yellow
  [141, 179, 27], // Green
];

/**
 * Maplecroft's default national risk colour scale.
 * Red, orange, yellow, lime green.
 *
 * These were taken from getScoreRgba().
 */
export const nationalRiskColorPalette: RiskColorPalette = [
  [229, 57, 53], // Red
  [251, 141, 0], // Orange
  [251, 192, 45], // Yellow
  [204, 221, 57], // Green
];

/**
 * Reds.
 * Yellow, red, orange, off-white.
 */
export const redRiskColorPalette: RiskColorPalette = [
  [255, 255, 0], // Yellow
  [255, 0, 0], // Red
  [255, 190, 190], // Pale red
  [241, 234, 224], // Off White
];

/**
 * Extreme dark red and yellow.
 * Not particularly accurate, mainly for visual effect.
 */
export const darkRedColorPalette: RiskColorPalette = [
  [255, 255, 0], // Yellow
  [255, 0, 0], // Red
  [80, 50, 50], // Dark red
  [85, 85, 85], // Dark grey
];
