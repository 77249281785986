import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import {
  AnnouncementBarItemDetails,
  AnnouncementBarResponse,
  ModuleAnnouncementBarItemDetails,
  ModuleAnnouncementBarQueryParams,
  ModuleAnnouncementBarResponse,
} from '../../../shared-models';
import { ApiAnnouncementBarItemDetails, ApiAnnouncementBarResponse } from '../models';

@Injectable()
export class AnnouncementBarService {
  private ResourceConfig: NonPaginatedResourceConfig;
  private announcementDetailResourcesConfig: NonPaginatedResourceConfig;
  private moduleAnnouncementResourceConfig: PaginatedResourceConfig;
  private moduleAnnouncementDetailResourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.ResourceConfig = API_SERVICES_CONFIG.v3.announcementBar._configuration;
    this.announcementDetailResourcesConfig = API_SERVICES_CONFIG.v3.announcementBar.detail._configuration;
    this.moduleAnnouncementResourceConfig = API_SERVICES_CONFIG.feApi.moduleAnnouncements._configuration;
    this.moduleAnnouncementDetailResourceConfig = API_SERVICES_CONFIG.feApi.moduleAnnouncements.detail._configuration;
  }

  public getAnnouncements(): Observable<AnnouncementBarResponse> {
    return this.http.get<ApiAnnouncementBarResponse>(this.ResourceConfig, { queryParams: { detail: true } });
  }

  public getAnnouncement(id: number): Observable<AnnouncementBarItemDetails> {
    return this.http.get<ApiAnnouncementBarItemDetails>(this.announcementDetailResourcesConfig, { pathParams: { id } });
  }

  public getModuleAnnouncements(queryParams?: ModuleAnnouncementBarQueryParams): Observable<ModuleAnnouncementBarResponse> {
    return this.http.get<ModuleAnnouncementBarResponse>(this.moduleAnnouncementResourceConfig, queryParams ? { queryParams } : {});
  }

  public getModuleAnnouncement(announcementId: string): Observable<ModuleAnnouncementBarItemDetails> {
    return this.http.get<ModuleAnnouncementBarItemDetails>(this.moduleAnnouncementDetailResourceConfig, { pathParams: { announcementId } });
  }
}
