import { Component } from '@angular/core';
import { GridApi } from '@ag-grid-community/core';
import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { WindowUtilitiesService } from '../../shared-utilities';
import { ErrorComponentData } from '../../shared-models';

export interface SubscriptionDetailsNoRowsOverlayAdditionalParams {
  api?: GridApi;
  name: string;
  namePlural: string;
  image: string;
  noResultsMessage?: string | null;
  showNoResultsMsg?: () => boolean;
  showLoadingMsg: () => boolean;
  showAdditionalMsg?: () => boolean;
  showSubscribedToAllMsg?: () => boolean;
  showNoMatchMsg?: () => boolean;
  showErrorMsg: () => boolean;
  getError: () => ErrorComponentData | null;
  loadData: () => null | void;
}

export type SubscriptionDetailsNoRowsOverlayParams = SubscriptionDetailsNoRowsOverlayAdditionalParams;

@Component({
  selector: 'grid-ui-subscription-details-norows-overlay',
  templateUrl: './subscription-details-norows-overlay.component.html',
  styleUrls: ['./subscription-details-norows-overlay.component.scss'],
})
export class SubscriptionDetailsNorowsOverlayComponent implements ILoadingOverlayAngularComp {
  public params!: SubscriptionDetailsNoRowsOverlayParams;

  public get error() {
    return this.params.getError();
  }

  public constructor(private readonly windowUtilitiesService: WindowUtilitiesService) {}

  public agInit(params: any): void {
    this.params = params;
  }

  public reloadApp(): void {
    this.windowUtilitiesService.reloadApp();
  }

  public showAdditionalMsg(): boolean {
    return !!this.params?.showAdditionalMsg && this.params?.showAdditionalMsg();
  }

  public showSubscribedToAllMsg(): boolean {
    return !!this.params?.showSubscribedToAllMsg && this.params?.showSubscribedToAllMsg();
  }

  public showNoResultMsg(): boolean {
    return !!this.params?.showNoResultsMsg && this.params?.showNoResultsMsg();
  }

  public showNoMatchMsg(): boolean {
    return !!this.params?.showNoMatchMsg && this.params?.showNoMatchMsg();
  }
}
