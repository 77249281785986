import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserWindowRef, WINDOW, windowFactory, WindowRef } from './providers';

@NgModule({
  imports: [CommonModule],
})
export class WindowProviderModule {
  static forRoot(): ModuleWithProviders<WindowProviderModule> {
    return {
      ngModule: WindowProviderModule,
      providers: [
        { provide: WindowRef, useClass: BrowserWindowRef },
        {
          provide: WINDOW,
          useFactory: windowFactory,
          deps: [WindowRef, PLATFORM_ID],
        },
      ],
    };
  }
}
