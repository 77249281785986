<div class="grid-ui-unexpected-error-status p-3 pt-4 pb-4">
  <h3>
    Sorry, something has gone wrong{{ errorCodeSegment }}
    <span>
      Please try saving again.
      <a href="mailto:support@maplecroft.com">Contact us</a> or try the <a href="learn/faq?module=country-risk" target="_blank">FAQ</a> if
      the issue persists
    </span>
  </h3>
</div>
