import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PollStatusEnum } from '../../../shared-models';
import { DataWizardExtractProcessHandlerService } from './data-wizard-extract-process-handler.service';

@Injectable()
export class DataWizardExtractNotificationService {
  private downloaded = new Subject<boolean>();
  public downloaded$: Observable<boolean>;

  constructor(private readonly handlerService: DataWizardExtractProcessHandlerService) {
    this.downloaded$ = this.downloaded.asObservable();
  }

  public cancel(): void {
    this.handlerService.cancel();
  }

  public closeNotification(): void {
    this.handlerService.setSideNavNotificationOpenState(false);
  }

  public getDownloadExtractUrl(): Observable<string> {
    return this.handlerService.getExtractDownloadUrl();
  }

  public downloadExtract(): void {
    this.handlerService.setSideNavNotificationOpenState(false);
    this.downloaded.next(true);
  }

  public extractActive(): Observable<boolean> {
    return this.handlerService.pollingProgress$.pipe(
      map((x) => x.status === PollStatusEnum.Active || x.status === PollStatusEnum.NotStarted),
    );
  }

  public extractComplete(): Observable<boolean> {
    return this.handlerService.pollingProgress$.pipe(map((x) => x.status === PollStatusEnum.Completed));
  }

  public extractCompleteVisible(): Observable<boolean> {
    return combineLatest([this.extractComplete(), this.handlerService.showSideNavNotification$]).pipe(
      map(([isComplete, isVisible]) => isComplete && isVisible),
    );
  }
}
