<div class="modal-container">
  <div class="modal-header">
    <input ngbAutofocus class="no-size" />
    <span class="modal-title">{{ title }}</span>
    <grid-ui-icon
      type="button"
      class="mc-dismiss-icon close-icon"
      aria-label="Dismiss"
      (click)="dismiss()"
      ngbTooltip="Close window"
      name="cross"
      *ngIf="allowHeaderDismiss"
    >
    </grid-ui-icon>
  </div>

  <div class="modal-body p-4">
    <div class="align-items-center d-flex flex-column justify-content-center">
      <span *ngIf="isProcessing" class="mc-processing mt-4 mb-5">
        <i class="fas fa-spinner fa-4x fa-spin"></i>
      </span>

      <span *ngIf="responseState === ActionResponseState.Success" class="mc-success">
        <i class="fas fa-check-circle fa-4x"></i>
      </span>

      <span
        *ngIf="responseState === ActionResponseState.Info || hasFailed"
        class="d-flex flex-column justify-content-center align-items-center"
        [class.mc-info]="!warning && !hasFailed"
        [class.mc-failure]="warning || hasFailed"
      >
        <i class="fas fa-exclamation-circle fa-4x"></i>
        <h2 class="mt-4 mb-4" *ngIf="hasFailed">Sorry, something went wrong.</h2>
      </span>

      <h3 class="mc-message center" [attr.data-hook]="'asm-modal-' + responseState + '-message'">
        {{ message }}
      </h3>

      <p class="center mt-2" *ngIf="subMessage">
        {{ subMessage }}
      </p>

      <div class="d-flex">
        <button class="btn mc-btn-secondary mt-4 mb-1 me-3" *ngIf="allowSecondaryButton" (click)="closeSecondary()">
          {{ hasFailedOrSucceeded ? 'Close' : secondaryButtonLabel }}
        </button>

        <button
          *ngIf="buttonLabel && buttonLabel!.length > 0"
          type="button"
          class="mc-close-button btn mt-4 mb-1"
          [class.mc-btn-danger]="warning"
          [class.mc-btn-primary]="!warning"
          [class.disabled]="isProcessing || (hasFailedOrSucceeded && allowSecondaryButton)"
          [attr.disabled]="isProcessing || (hasFailedOrSucceeded && allowSecondaryButton) || null"
          (click)="close()"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="messageDetail">
    <p class="mc-message-detail center">
      <span class="small">{{ messageDetail }}</span>
    </p>
  </div>
</div>
