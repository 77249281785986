import { ContentBlock } from '../content-choice';
import { CountryRiskProfile } from '../country-risk';

enum GROUP_TITLES {
  CountrySnapshot = 'Country Snapshot',
}
// TODO: This typeguard was written before the introduction of
// Commodity Risk related written content, it may have to be
// revisited

export function isCountryRiskProfile(block: ContentBlock): block is CountryRiskProfile {
  return Object.prototype.hasOwnProperty.call(block, 'grouped') && Object.prototype.hasOwnProperty.call(block, 'region');
}

export function isCountrySnapshot(groupTitle: string): boolean {
  return groupTitle.toLowerCase() === GROUP_TITLES.CountrySnapshot.toLowerCase();
}
