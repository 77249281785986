/** Colors representing each score cathegory */
export enum ScoreColor {
  /** Extreme */
  red = '#CE181F',
  /** High */
  orange = '#F47721',
  /** Medium */
  yellow = '#FFC709',
  /** Low */
  green = '#D6E040',
  /** No Score */
  noScore = '#CECECE',
}
