import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ImageItemValue } from '../../../shared-models';
import { BodyItemExpandedImageComponent } from './body-item-expanded-image';

/**
 * The image to show when an image is not found
 */
export const DEFAULT_IMAGE_NOT_FOUND_IMAGE = 'assets/img/no_image.png';

/**
 * The image component use to display the image blocks in content.
 *
 * @class BodyItemImageComponent
 * @implements OnInit
 */
@Component({
  selector: 'grid-ui-body-item-image',
  templateUrl: './body-item-image.component.html',
  styleUrls: ['./body-item-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemImageComponent {
  public IMAGE_NOT_FOUND = DEFAULT_IMAGE_NOT_FOUND_IMAGE;

  @Input() public value: ImageItemValue = {
    id: -1,
    alt: '',
    title: '',
    subtitle: '',
    caption: '',
    url: this.IMAGE_NOT_FOUND,
    attribution: '',
  };
  @Output() public loaded = new EventEmitter<void>();

  constructor(private readonly ngBoostrapModalService: NgbModal) {}

  public handleImageLoaded(): void {
    this.loaded.emit();
  }

  /**
   * Opens the expanded image component to the body
   * @return {void}@memberof ImageComponent
   */
  public openExpander(): void {
    const modalOptions: NgbModalOptions = { centered: true, backdrop: true, keyboard: true, size: 'xl' } as NgbModalOptions;

    const modalRef = this.ngBoostrapModalService.open(BodyItemExpandedImageComponent, modalOptions);

    modalRef.componentInstance.alt = this.value.alt;
    modalRef.componentInstance.url = this.value.url;
    modalRef.componentInstance.title = this.value.title;
    modalRef.componentInstance.subtitle = this.value.subtitle;
    modalRef.componentInstance.caption = this.value.caption;
    modalRef.componentInstance.attribution = this.value.attribution;
  }

  /**
   * Handles the onError event in the image i.e. when the image does not have a valid src property
   *
   * @param  {any} $event
   * @return {void}@memberof ImageComponent
   */
  public imageLoadError(): void {
    // change the image url to invalid photo url
    this.value.url = this.IMAGE_NOT_FOUND;
  }
}
