import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalLegendModule } from '../global-legend';
import { IconModule } from '../icon/icon.module';
import { ScorePanelImageDownloadButtonComponent } from './score-panel-image-download-button.component';

@NgModule({
  declarations: [ScorePanelImageDownloadButtonComponent],
  exports: [ScorePanelImageDownloadButtonComponent],
  imports: [CommonModule, NgbModalModule, NgbTooltipModule, GlobalLegendModule, IconModule],
})
export class ScorePanelImageDownloadButtonModule {}
