import { AnalyseOverviewActionsUnion } from './analyse-overview.action';
import { AnalyseContentSubpanelType } from './analyse-content-subpanel.type';

export enum AnalyseMainPanelActionTypes {
  CHANGE_EXPANDED_SUBPANEL = '[Main Panel] Change Expanded Sub-Panel',
}

export class AnalyseMainPanelChangeExpandedSubPanel {
  public readonly type = AnalyseMainPanelActionTypes.CHANGE_EXPANDED_SUBPANEL;
  constructor(public payload: { expandedPanel: AnalyseContentSubpanelType }) {}
}

// TODO: Add actions from scorecard panels and any other actions covering main panel related events

export type AnalyseMainPanelActionsUnion = AnalyseOverviewActionsUnion | AnalyseMainPanelChangeExpandedSubPanel;
