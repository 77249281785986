/** A regex for picking up parts of a template for Python-style formatting. */
const pyFormat = /{([_a-zA-Z0-9]+)}/g;

/**
 * Format a template string like Python.
 *
 * @example
 * format('{foo} is a really {bar} {baz}!', {
 *   foo: 'This',
 *   bar: 'great',
 *   baz: 'function'
 * });
 * // 'This is a really great function!'
 *
 * @throws Error if the template contains values not included in the params
 * dict.
 */
export const format = (tpl: string, params: { [key: string]: any }): string =>
  tpl.replace(pyFormat, (match: string, name: string): string => {
    if (!Object.prototype.hasOwnProperty.call(params, name)) {
      throw new Error(`Missing param '${name}'`);
    }
    return params[name];
  });
