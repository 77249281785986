import { Injectable, ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';

interface Toast<T> {
  content: T;
  classname: string;
  type: 'success' | 'error' | 'custom';
  delay: number;
}

const DISPLAY_TIME_MS = 3000;

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public constructor() {}

  public toasts: Toast<any>[] = [];

  public showSuccess(text: string): void {
    this.toasts.push({ content: text, type: 'success', classname: 'grid-toast-success', delay: DISPLAY_TIME_MS });
  }

  public showError(text: string): void {
    this.toasts.push({ content: text, type: 'error', classname: 'grid-toast-error', delay: DISPLAY_TIME_MS });
  }

  public showCustom<T>(content: T, classname: string, type: 'success' | 'error' | 'custom', delay: number = DISPLAY_TIME_MS): void {
    this.toasts.push({ content, type, classname, delay });
  }

  public remove(toast: Toast<any>): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  public clearAll(): void {
    this.toasts = [];
  }

  public getToasts(): Toast<any>[] {
    return [...this.toasts];
  }
}
