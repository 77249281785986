<div class="scorecard-wrapper" *ngIf="value">
  <h5 *ngIf="value.title">{{ value.title }}</h5>
  <p *ngIf="value.subtitle">{{ value.subtitle }}</p>

  <div class="chart-content" (click)="openExpander()">
    <div #chart>
      <grid-ui-vega-chart *ngIf="chartData" [id]="scorecardID" [data]="chartData"> </grid-ui-vega-chart>
    </div>

    <span class="expand-icon">
      <i class="fas fa-expand-arrows-alt"></i>
    </span>
  </div>
  <span class="attribution" *ngIf="value.caption">Source: {{ value.caption }}</span>
</div>
