import { NgModule } from '@angular/core';
import { SharedPipesModule } from '../../shared-pipes';
import { NoDataAvailableComponent } from './no-data-available.component';

@NgModule({
  imports: [SharedPipesModule],
  declarations: [NoDataAvailableComponent],
  exports: [NoDataAvailableComponent],
})
export class NoDataAvailableModule {}
