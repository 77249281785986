import { Directive, EmbeddedViewRef, inject, Input, ɵstringify as stringify, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagKeyArg } from '../../feature-flag-key.model';
import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[gridUiFeatureFlag]',
})
export class FeatureFlagDirective {
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly featureFlagService = inject(FeatureFlagService);

  private thenTemplateRef: TemplateRef<any> | null = inject(TemplateRef);
  private elseTemplateRef: TemplateRef<any> | null = null;
  private thenViewRef: EmbeddedViewRef<any> | null = null;
  private elseViewRef: EmbeddedViewRef<any> | null = null;

  private value = false;

  /**
   * The feature flag key to evaluate as the condition for showing a template.
   */
  @Input()
  public set gridUiFeatureFlag(key: FeatureFlagKeyArg) {
    this.value = this.featureFlagService.getFlagValue(key);

    this.updateView();
  }

  /**
   * A template to show if the condition expression evaluates to true.
   */
  @Input()
  public set gridUiFeatureFlagThen(templateRef: TemplateRef<any> | null) {
    this.assetTemplate('gridUiFeatureFlagThen', templateRef);

    this.thenTemplateRef = templateRef;
    this.thenViewRef = null;

    this.updateView();
  }

  /**
   * A template to show if the condition expression evaluates to false.
   */
  @Input()
  public set gridUiFeatureFlagElse(templateRef: TemplateRef<any> | null) {
    this.assetTemplate('gridUiFeatureFlagElse', templateRef);

    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;

    this.updateView();
  }

  private updateView(): void {
    if (this.value) {
      if (!this.thenViewRef) {
        this.viewContainerRef.clear();
        this.elseViewRef = null;

        if (this.thenTemplateRef) {
          this.thenViewRef = this.viewContainerRef.createEmbeddedView(this.thenTemplateRef);
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainerRef.clear();
        this.thenViewRef = null;

        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
        }
      }
    }
  }

  private assetTemplate(property: string, templateRef: TemplateRef<any> | null): void {
    const isTemplateRefOrNull = !!(templateRef || templateRef.createEmbeddedView);

    if (!isTemplateRefOrNull) {
      throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
    }
  }
}
