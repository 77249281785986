import { AnalyseDataTableFlatTreeNode } from '../../../shared-models';
import { ApiAnalyseDataTableFlatTreeNode } from '../../models';

export function mapAllDataTableNonHistoricalFlatNodeFromApiToApp(apiNode: ApiAnalyseDataTableFlatTreeNode): AnalyseDataTableFlatTreeNode {
  if (apiNode.type === 'group') {
    return {
      ...apiNode,
      parent: apiNode.parent !== null ? `${apiNode.parent}` : null,
      id: `${apiNode.id}`,
      is_subnational: false,
    };
  }

  return {
    ...apiNode,
    parent: `${apiNode.parent}`,
  };
}
