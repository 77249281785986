import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionSelector, PermissionsService } from './services';

@Injectable()
export class CanLoadPermissionGuard {
  constructor(
    private readonly router: Router,
    private readonly permissionsService: PermissionsService,
  ) {}

  public canLoad(reqRoute: Route): boolean | Observable<boolean> | Promise<boolean> {
    if (!reqRoute.data || !reqRoute.data['permissionGuard']) {
      throw new Error('permissionGuard data attribute must be provided in router config when using CanLoadPermissionGuard');
    }
    const selector = reqRoute.data['permissionGuard'] as PermissionSelector;
    return this.permissionsService.isAllowed(selector).then((allowed) => {
      if (allowed) {
        return true;
      }
      console.warn(`CanLoadPermissionGuard: Insufficient permissions to navigate to: ${reqRoute.path}`);
      const redirect = selector.routingDeniedRedirect;
      if (redirect) {
        this.router.navigate([redirect]);
      }
      return false;
    });
  }
}

@Injectable()
export class PermissionGuard {
  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!route.data || !route.data['permissionGuard']) {
      throw new Error('permissionGuard data attribute must be provided in router config when using PermissionGuard');
    }
    const selector = route.data['permissionGuard'] as PermissionSelector;
    return this.permissionsService.isAllowed(selector).then((allowed) => {
      if (allowed) {
        return true;
      }
      console.warn(`PermissionGuard: Insufficient permissions to navigate to: ${route.url}`);
      const redirect = selector.routingDeniedRedirect;
      if (redirect) {
        this.router.navigate([redirect]);
      }
      return false;
    });
  }
}
