import { AnalyseTreeHierarchyVisualModel } from '../../country-risk/country-risk-analyse';
import { AnalyseFlatTreeGroupNode, AnalyseFlatTreeReferenceNode } from './analyse-flat-tree-node.interface';

export enum AnalyseTreeViewNodeSelectionState {
  UNSELECTED,
  PARTIAL,
  SELECTED,
}

export interface PreRoot {
  depth: number;
}

export class AnalyseViewTreeLeafNode {
  public id: string;
  public parent: AnalyseViewTreeGroupNode;
  public depth: number;
  public selected = false;
  public hierarchyModel?: AnalyseTreeHierarchyVisualModel;
  public isSubnational?: boolean;

  constructor(public data: AnalyseFlatTreeReferenceNode) {}

  public eachBefore(fn: (node: AnalyseViewTreeLeafNode) => void): AnalyseViewTreeLeafNode {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const node: AnalyseViewTreeLeafNode = this;
    fn(node);
    return this;
  }
}

export class AnalyseViewTreeGroupNode {
  public id: string;
  public parent: AnalyseViewTreeGroupNode | PreRoot | null = null;
  public from: AnalyseViewTreeNode[] = [];
  public depth: number;
  public isRoot?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public expanded: boolean = false;
  public selected = false;
  public hasChildrenSelected = false;
  public loading?: boolean;
  public hierarchyModel?: AnalyseTreeHierarchyVisualModel;

  constructor(public data: AnalyseFlatTreeGroupNode) {}

  public eachBefore(fn: (node: AnalyseViewTreeNode) => void): AnalyseViewTreeGroupNode {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let node: AnalyseViewTreeNode | undefined = this;
    const nodes: AnalyseViewTreeNode[] = [node];
    let from: AnalyseViewTreeNode[];
    let i: number;
    node = nodes.pop();
    while (node) {
      fn(node);
      if (node instanceof AnalyseViewTreeGroupNode) {
        from = node.from;
        for (i = from.length - 1; i >= 0; --i) {
          nodes.push(from[i]);
        }
      }
      node = nodes.pop();
    }
    return this;
  }
}

export type AnalyseViewTreeNode = AnalyseViewTreeGroupNode | AnalyseViewTreeLeafNode;
