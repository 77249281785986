import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageSelectorComponent } from './page-selector.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PageSelectorComponent],
  exports: [PageSelectorComponent],
})
export class PageSelectorModule {}
