import { SelectionChangedEvent } from '@ag-grid-community/core';

export interface CustomSelectAllEvent extends SelectionChangedEvent {
  type: 'selectionChanged';
  readonly customType: 'selectAllChanged';
  selectAll: boolean;
}

export interface CustomResetSelectAllEvent extends SelectionChangedEvent {
  type: 'selectionChanged';
  readonly customType: 'selectAllReset';
  resetSelectAll: boolean;
}

export type CustomSelectionChangedEvent = CustomSelectAllEvent | CustomResetSelectAllEvent;

export function isCustomSelectionChangedEvent(
  event: CustomSelectionChangedEvent | SelectionChangedEvent,
): event is CustomSelectionChangedEvent {
  return typeof (<CustomSelectionChangedEvent>event).customType === 'string';
}
