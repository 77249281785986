import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG, NonPaginatedResourceConfig, PortalHttpClient } from '@grid-ui/common';
import { Observable } from 'rxjs';
import { DataWizardUploadSitesApiStatus } from '../enums/data-wizard-upload-sites-toplevel-status.enum';
import { DataWizardUploadSitesColumnMappingResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DataWizardUploadSitesCancelSessionService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.locations.uploads.sessions.session._configuration;
  }

  public cancelSession(id: number): Observable<DataWizardUploadSitesColumnMappingResponse> {
    return this.http.patch<DataWizardUploadSitesColumnMappingResponse>(this.resourceConfig, {
      body: {
        status: DataWizardUploadSitesApiStatus.Cancelled,
      },
      pathParams: { id },
    });
  }
}
