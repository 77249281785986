import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PortalHttpClient, API_SERVICES_CONFIG, NonPaginatedResourceConfig } from '@grid-ui/common';
import { ApiCountryRiskAutocompleteSuggestions, CountryRiskAutocompleteParams } from '../model';

@Injectable()
export class CountryRiskAutocompleteService {
  private resourcesConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.content.autocomplete._configuration;
  }

  public getAutocompleteSuggestions(params: CountryRiskAutocompleteParams): Observable<ApiCountryRiskAutocompleteSuggestions> {
    return this.http.get<ApiCountryRiskAutocompleteSuggestions>(this.resourcesConfig, {
      queryParams: { search: params.search },
      pathParams: { section: params.section },
    });
  }
}
