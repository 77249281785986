import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AllCountriesService,
  CountryChoice,
  GeneralLoadedContent,
  HttpErrorHandlerService,
  INITIAL_GENERAL_LOADING_CONTENT,
  LoadingRequestStatus,
  SavingRequestStatus,
  SearchableDropdownActionsUnion,
  SearchableDropdownActionTypes,
  SearchableDropdownTheme,
  SearchableItem,
  UnexpectedErrorStatusComponent,
  WindowUtilitiesService,
} from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import {
  SavingState,
  selectWhoAmIPersonalInformation,
  selectWhoAmIUserSavingStatus,
  whoAmIResetSaveUser,
  whoAmISaveUser,
  WhoAmIUserPersonalInformation,
  WhoAmIUserPersonalInformationChange,
  WhoAmIUserProfileChange,
} from '@grid-ui/whoami';
import { select, Store } from '@ngrx/store';
import * as R from 'ramda';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { AppState } from '../store/reducers';

enum AccountSettingsControlNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  CITY = 'city',
  COUNTRY = 'country',
  JOB_TITLE = 'jobTitle',
  DEPARTMENT = 'department',
}

enum AccountSettingsModalResolvedValues {
  CHANGE_PASSWORD = '[Account Settings Modal] Change Password',
}

interface ParsedSavingError {
  first_name: string[];
  last_name: string[];
  phone_number: string[];
  job_title: string[];
  department: string[];
  country: string[];
  city: string[];
  general?: number;
}

const CLEAN_PARSED_ERRORS: ParsedSavingError = {
  first_name: [],
  last_name: [],
  phone_number: [],
  job_title: [],
  department: [],
  country: [],
  city: [],
};

interface AccountSettingsFormValue {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string | null;
  country: number | null;
  jobTitle: string;
  department: string;
}

@Component({
  selector: 'grid-ui-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent {
  public currentAccountSettings: WhoAmIUserPersonalInformation;
  public savingStatus$: Observable<SavingState>;
  public countries$: Observable<CountryChoice[]>;
  public saveDispatcher: (change: WhoAmIUserPersonalInformationChange) => void;

  @ViewChild(UnexpectedErrorStatusComponent, { read: ElementRef }) unexpectedErrorStatusEl: ElementRef<HTMLElement>;

  private readonly defaultChangePasswordHref = '/accounts/password/change/';
  public readonly AccountSettingsModalResolvedValues = AccountSettingsModalResolvedValues;
  public countries: GeneralLoadedContent<SearchableItem<number>[]> = INITIAL_GENERAL_LOADING_CONTENT;
  public controlNames = AccountSettingsControlNames;
  public form: UntypedFormGroup;
  public savingStatus: SavingState = {
    status: SavingRequestStatus.initial,
    error: null,
  };
  public readonly SavingRequestStatus = SavingRequestStatus;
  public parsedSavingError: ParsedSavingError = CLEAN_PARSED_ERRORS;
  public countriesSearchTerm = '';
  public emailPreferenceUrl = 'https://go.maplecroft.com/l/456202/2020-10-20/3w4c3g?email=';
  public readonly dropdownTheme = SearchableDropdownTheme.FORM_CONTROL;
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store<AppState>,
    private readonly allCountriesService: AllCountriesService,
    private readonly windowUtilitiesService: WindowUtilitiesService,
    private readonly httpErrorHandlingService: HttpErrorHandlerService,
  ) {
    this.store
      .select(selectWhoAmIPersonalInformation)
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .subscribe((userPersonalInformation) => {
        if (userPersonalInformation !== null) {
          this.currentAccountSettings = userPersonalInformation;
          this.savingStatus$ = this.store.pipe(select(selectWhoAmIUserSavingStatus));
          this.countries$ = this.allCountriesService.getAllCountries();
          this.saveDispatcher = this.getSaveDispatcher();

          this.initializeForm();
          this.createSavingStatusSubscription();
          this.prepareCountryChoices();
        }
      });
  }

  public close(reason: AccountSettingsModalResolvedValues | null): void {
    this.store.dispatch(whoAmIResetSaveUser());
    if (reason === AccountSettingsModalResolvedValues.CHANGE_PASSWORD) {
      const changePasswordUrl = environment.changePasswordUrl || this.defaultChangePasswordHref;
      this.windowUtilitiesService.setHref(changePasswordUrl);
    }
  }

  public getSubmitDisabled(): boolean {
    return (
      this.form.pristine || this.form.invalid || this.savingStatus.status === SavingRequestStatus.saving || this.hasFieldLevelBackendError()
    );
  }

  public handleCountryDropdownAction(action: SearchableDropdownActionsUnion<number>): void {
    const control = this.form.controls[this.controlNames.COUNTRY] as UntypedFormControl | undefined;
    let id: number | null;
    switch (action.type) {
      case SearchableDropdownActionTypes.CHANGE_SELECTION:
        id = action.selection ? action.selection.id : null;
        if (control && control.value !== id) {
          control.setValue(id);
          control.markAsDirty();
        }
        break;
      case SearchableDropdownActionTypes.CHANGE_SEARCHTERM:
        this.countriesSearchTerm = action.searchterm;
        break;
      case SearchableDropdownActionTypes.RETRY_LOAD:
        console.warn('Account Settings Modal Countries list load retry not yet implemented');
        break;
      case SearchableDropdownActionTypes.TOUCHED:
        if (control) {
          control.markAsTouched();
        }
        break;
      case SearchableDropdownActionTypes.CLOSED:
        this.countriesSearchTerm = '';
    }
  }

  public getBackendErrors(fieldName: AccountSettingsControlNames): string[] {
    let backendErrors: string[] = [];

    switch (fieldName) {
      case this.controlNames.FIRST_NAME: {
        backendErrors = this.parsedSavingError.first_name;
        break;
      }
      case this.controlNames.LAST_NAME: {
        backendErrors = this.parsedSavingError.last_name;
        break;
      }
      case this.controlNames.PHONE: {
        backendErrors = this.parsedSavingError.phone_number;
        break;
      }
      case this.controlNames.CITY: {
        backendErrors = this.parsedSavingError.city;
        break;
      }
      case this.controlNames.COUNTRY: {
        backendErrors = this.parsedSavingError.country;
        break;
      }
      case this.controlNames.JOB_TITLE: {
        backendErrors = this.parsedSavingError.job_title;
        break;
      }
      case this.controlNames.DEPARTMENT: {
        backendErrors = this.parsedSavingError.department;
        break;
      }
      default: {
        break;
      }
    }

    return backendErrors;
  }

  public isErrorInFormValue(fieldName: AccountSettingsControlNames): boolean {
    const hasBackendValidationError = this.getBackendErrors(fieldName).length > 0;

    if (
      hasBackendValidationError ||
      ((this.form.controls[fieldName].touched || this.form.controls[fieldName].dirty) && this.form.controls[fieldName].invalid)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public saveSettings(): void {
    if (this.form.valid) {
      const payload = this.mapFormValueToSettingsChange(this.form.value);
      if (!R.isEmpty(payload)) {
        this.saveDispatcher(payload);
      } else {
        this.close(null);
      }
    }
  }

  private createSavingStatusSubscription(): void {
    const sub = this.savingStatus$
      .pipe(
        tap((savingStatus) => {
          this.savingStatus = savingStatus;
          switch (savingStatus.status) {
            case SavingRequestStatus.saving:
              this.parsedSavingError = CLEAN_PARSED_ERRORS;
              this.form.disable();
              break;
            case SavingRequestStatus.error:
              this.setParsedSavingError(savingStatus.error);
              this.form.enable({ emitEvent: false });
              this.enforceBackendErrorResets();
              break;
            case SavingRequestStatus.saved:
              this.form.enable();
              this.form.markAsPristine();
              break;
          }
        }),
        filter((savingStatus) => savingStatus.status === SavingRequestStatus.saved),
      )
      .subscribe(() => {});

    this.subscriptions.add(sub);
  }

  private enforceBackendErrorResets(): void {
    this.form.controls[this.controlNames.FIRST_NAME].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.first_name.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, first_name: [] }));

    this.form.controls[this.controlNames.LAST_NAME].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.last_name.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, last_name: [] }));

    this.form.controls[this.controlNames.PHONE].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.phone_number.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, phone_number: [] }));

    this.form.controls[this.controlNames.JOB_TITLE].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.job_title.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, job_title: [] }));

    this.form.controls[this.controlNames.DEPARTMENT].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.department.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, department: [] }));

    this.form.controls[this.controlNames.CITY].valueChanges
      .pipe(
        filter<string>(() => this.parsedSavingError.city.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, city: [] }));

    this.form.controls[this.controlNames.COUNTRY].valueChanges
      .pipe(
        filter(() => this.parsedSavingError.country.length > 0),
        take(1),
      )
      .subscribe(() => (this.parsedSavingError = { ...this.parsedSavingError, country: [] }));
  }

  private hasFieldLevelBackendError(): boolean {
    return (
      this.parsedSavingError.first_name.length > 0 ||
      this.parsedSavingError.last_name.length > 0 ||
      this.parsedSavingError.phone_number.length > 0 ||
      this.parsedSavingError.job_title.length > 0 ||
      this.parsedSavingError.department.length > 0 ||
      this.parsedSavingError.city.length > 0 ||
      this.parsedSavingError.country.length > 0
    );
  }

  private initializeForm(): void {
    this.form = this.fb.group({});
    this.form.addControl(
      this.controlNames.FIRST_NAME,
      new UntypedFormControl(this.currentAccountSettings.first_name, [Validators.required]),
    );
    this.form.addControl(this.controlNames.LAST_NAME, new UntypedFormControl(this.currentAccountSettings.last_name, [Validators.required]));
    this.form.addControl(this.controlNames.PHONE, new UntypedFormControl(this.currentAccountSettings.profile.phone_number));
    this.form.addControl(this.controlNames.CITY, new UntypedFormControl(this.currentAccountSettings.profile.city));
    this.form.addControl(this.controlNames.COUNTRY, new UntypedFormControl(this.currentAccountSettings.profile.country));
    this.form.addControl(this.controlNames.JOB_TITLE, new UntypedFormControl(this.currentAccountSettings.profile.job_title));
    this.form.addControl(this.controlNames.DEPARTMENT, new UntypedFormControl(this.currentAccountSettings.profile.department));
  }

  private mapFormValueToSettingsChange(formValue: AccountSettingsFormValue): WhoAmIUserPersonalInformationChange {
    const profileChange: WhoAmIUserProfileChange = {};
    const personalInfoChange: WhoAmIUserPersonalInformationChange = {};

    const firstName = formValue[this.controlNames.FIRST_NAME].trim();
    if (this.currentAccountSettings.first_name !== firstName) {
      personalInfoChange.first_name = firstName;
    }
    const lastName = formValue[this.controlNames.LAST_NAME].trim();
    if (this.currentAccountSettings.last_name !== lastName) {
      personalInfoChange.last_name = lastName;
    }
    const department = formValue[this.controlNames.DEPARTMENT].trim();
    if (this.currentAccountSettings.profile.department !== department) {
      profileChange.department = department;
    }
    const jobTitle = formValue[this.controlNames.JOB_TITLE].trim();
    if (this.currentAccountSettings.profile.job_title !== jobTitle) {
      profileChange.job_title = jobTitle;
    }
    const phone = formValue[this.controlNames.PHONE].trim();
    if (this.currentAccountSettings.profile.phone_number !== phone) {
      profileChange.phone_number = phone;
    }

    if (this.currentAccountSettings.profile.country !== formValue[this.controlNames.COUNTRY]) {
      profileChange.country = formValue[this.controlNames.COUNTRY];
    }

    const city =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      formValue[this.controlNames.CITY] !== null && formValue[this.controlNames.CITY]!.trim() !== ''
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          formValue[this.controlNames.CITY]!.trim()
        : null;
    if (this.currentAccountSettings.profile.city !== city) {
      profileChange.city = city;
    }

    if (!R.isEmpty(profileChange)) {
      personalInfoChange.profile = profileChange;
    }

    return personalInfoChange;
  }

  private prepareCountryChoices(): void {
    this.countries = INITIAL_GENERAL_LOADING_CONTENT;
    const sub = this.countries$
      .pipe(
        map((countryChoices) => ({
          loadingStatus: LoadingRequestStatus.loaded,
          content: countryChoices.map((country) => ({ id: country.id, label: country.name }) as SearchableItem<number>),
          error: null,
        })),
        catchError((err: HttpErrorResponse) =>
          of({
            loadingStatus: LoadingRequestStatus.error,
            content: null,
            error: this.httpErrorHandlingService.mapHttpErrorResponseToUIComponent(err, 'Try Again', null, 'reduced'),
          } as GeneralLoadedContent<SearchableItem<number>[]>),
        ),
      )
      .subscribe((countries) => (this.countries = countries));

    this.subscriptions.add(sub);
  }

  private scrollUnexpectedErrorIntoView(): void {
    setTimeout(() => {
      if (this.unexpectedErrorStatusEl) {
        this.unexpectedErrorStatusEl.nativeElement.scrollIntoView(true);
      }
    }, 0);
  }

  private setParsedSavingError(error: HttpErrorResponse | null): void {
    if (error === null) {
      return;
    }

    const parsedError: ParsedSavingError = R.clone(CLEAN_PARSED_ERRORS);

    if (error.status === 400) {
      if (error.error.first_name !== undefined) {
        parsedError.first_name = error.error.first_name;
      }
      if (error.error.last_name !== undefined) {
        parsedError.last_name = error.error.last_name;
      }

      if (error.error.profile !== undefined) {
        if (error.error.profile.country !== undefined) {
          parsedError.country = error.error.profile.country;
        }
        if (error.error.profile.phone_number !== undefined) {
          parsedError.phone_number = error.error.profile.phone_number;
        }
        if (error.error.profile.job_title !== undefined) {
          parsedError.job_title = error.error.profile.job_title;
        }
        if (error.error.profile.department !== undefined) {
          parsedError.department = error.error.profile.department;
        }
        if (error.error.profile.city !== undefined) {
          parsedError.city = error.error.profile.city;
        }
      }
      if (R.equals(parsedError, CLEAN_PARSED_ERRORS)) {
        parsedError.general = error.status;
      }
    } else {
      parsedError.general = error.status;
    }

    this.parsedSavingError = parsedError;
    if (this.parsedSavingError.general !== undefined) {
      this.scrollUnexpectedErrorIntoView();
    }
  }

  private getSaveDispatcher(): (payload: WhoAmIUserPersonalInformationChange) => void {
    const store = this.store;
    return function (payload: WhoAmIUserPersonalInformationChange): void {
      store.dispatch(whoAmISaveUser({ payload }));
    };
  }
}
