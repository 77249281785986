import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { CountryRiskFeaturedContent } from '../../../shared-models';
import { ApiCountryRiskFeaturedContentCollection } from '../models';

@Injectable()
/**
 * Service for accessing the Country Risk Feature Content API
 */
export class CountryRiskFeaturedContentService {
  private featureListResourcesConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.featureListResourcesConfig = API_SERVICES_CONFIG.v3.countryRisk.content.featuredList._configuration;
  }

  /**
   * Get a list of all featured content.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getFeaturedContents(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<CountryRiskFeaturedContent> {
    // TODO: Caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiCountryRiskFeaturedContentCollection>(this.featureListResourcesConfig, options);
  }
}
