import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorModule, IconModule, LoadingModule } from '@grid-ui/common';
import { GridSharedPipesModule } from '../shared-ui-components/shared-pipes/shared-pipes.module';
import { HomeComponent, HomeServiceOfferingCardComponent } from './home';

@NgModule({
  imports: [CommonModule, RouterModule, ErrorModule, LoadingModule, GridSharedPipesModule, IconModule],
  declarations: [HomeComponent, HomeServiceOfferingCardComponent],
  exports: [HomeComponent],
  providers: [],
})
export class HomeModule {}
