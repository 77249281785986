<div class="modal-container">
  <div class="modal-header" #modalHeaderRef>
    <div>{{ title }}</div>

    <div class="btn-actions d-flex align-items-center">
      <grid-ui-score-panel-image-download-button
        [linkedElement]="chartRef?.nativeElement"
        [headerRef]="modalHeaderElementRef"
        [title]="title"
        [subtitle]="subtitle"
        [useDownloadIcon]="true"
        [addScoreLegendToImage]="false"
      >
      </grid-ui-score-panel-image-download-button>

      <grid-ui-icon (click)="close()" class="close-icon" ngbTooltip="Close window" name="cross"> </grid-ui-icon>
    </div>
  </div>

  <div class="modal-body">
    <div class="wrapper">
      <div class="modal-sub-header">
        <h5 *ngIf="subtitle">{{ subtitle }}</h5>
      </div>
      <grid-ui-vega-chart #chart id="chart-preview" *ngIf="chartData" [data]="chartData" [scaled]="false"> </grid-ui-vega-chart>
    </div>
  </div>

  <div class="divider"></div>

  <div class="modal-footer" #modalFooterRef>
    <div class="footer-logo-attribution d-flex flex-row justify-content-between">
      <img class="vm-logo align-self-start m-2" src="assets/img/vm-logo-tm.svg" alt="vm logo" />
      <span class="attribution-text align-self-center m-2" *ngIf="caption">
        <span>Source: {{ caption }}</span>
      </span>
    </div>
  </div>
</div>
