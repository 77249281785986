import { FeatureFlagsConfig } from './lib/models';

export const featureFlagsConfig: FeatureFlagsConfig = {
  industry: {},
  industryBestWorst: {},
  industryGuidedTour: {},
  commodityRisk: {},
  showAllDisplayViews: {},
  subscriptions: {},
  uploadSites: {},
  embeddedCommodityDashboard: {},
  analyseScorecardsHideOthers: {},
  ['release:new-account-settings']: {
    removeBy: new Date('2025-10-08'),
  },
  ['release:api-tokens']: {
    removeBy: new Date('2025-10-08'),
  },
};
