import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryServiceV4, PaginatedCountryV4 } from '@grid-ui/common';

export enum ConcreteAttributeKeys {
  region = 'region',
  countryName = 'country_name',
  countryCode = 'country_code',
  supraRegion = 'supranational_region',
}

export class ConcreteAttributeSourceMapV4 {
  private countryService: CountryServiceV4;
  private attrSrcMap: {
    [ConcreteAttributeKeys.region]: Observable<PaginatedCountryV4>;
    [ConcreteAttributeKeys.countryName]: Observable<PaginatedCountryV4>;
    [ConcreteAttributeKeys.countryCode]: Observable<PaginatedCountryV4>;
    [ConcreteAttributeKeys.supraRegion]: Observable<PaginatedCountryV4>;
  };

  constructor(countryService: CountryServiceV4) {
    this.countryService = countryService;
    this.setAttrSrcMap();
  }

  public static isMappedAttribute(attrKey: string) {
    return Object.values(ConcreteAttributeKeys).includes(attrKey as ConcreteAttributeKeys);
  }

  public getSource(attrKey: `${ConcreteAttributeKeys}`) {
    return this.attrSrcMap[attrKey];
  }

  private setAttrSrcMap() {
    this.attrSrcMap = {
      [ConcreteAttributeKeys.region]: this.regionSource,
      [ConcreteAttributeKeys.countryCode]: this.countryCodeSource,
      [ConcreteAttributeKeys.countryName]: this.countryNameSource,
      [ConcreteAttributeKeys.supraRegion]: this.supraRegionSource,
    };
  }

  private get regionSource() {
    return this.countryService.getAllCountries({ fields: ConcreteAttributeKeys.region });
  }

  private get countryCodeSource() {
    return this.countryService.getAllCountries({ fields: ConcreteAttributeKeys.countryCode });
  }

  private get countryNameSource() {
    return this.countryService.getAllCountries({ fields: ConcreteAttributeKeys.countryName });
  }

  private get supraRegionSource() {
    return this.countryService.getAllCountries({ fields: ConcreteAttributeKeys.region }).pipe(
      map((resp) => ({
        ...resp,
        results: resp.results.map((country) => ({
          [ConcreteAttributeKeys.supraRegion]: country.region,
        })),
      })),
    );
  }
}
