<div
  *ngIf="navItems.length > 0"
  class="mc-nav-bar-container"
  [class.mc-boxed-appearance]="boxedAppearance !== BoxedAppearance.NONE"
  [class.transparent-background]="transparentBackground"
>
  <nav class="nav">
    <a
      *ngFor="let item of navItems; trackBy: trackByIndex; let i = index"
      class="nav-link"
      [class.mc-boxed-appearance-top-border]="boxedAppearanceTopBorder"
      [class.mc-left-borderless]="boxedAppearance === BoxedAppearance.ENCASED_LEFT || boxedAppearance === BoxedAppearance.ENCASED_BOTH"
      [class.mc-right-borderless]="boxedAppearance === BoxedAppearance.ENCASED_RIGHT || boxedAppearance === BoxedAppearance.ENCASED_BOTH"
      [class.view-dropdown-navbar]="extendClassName === 'viewDropdownNavbar'"
      [class.active]="i === selectedIndex"
      (click)="selectTab(i)"
      (mouseenter)="item.showTitleOnHover = !!item.hoverDisplay"
      (mouseleave)="item.showTitleOnHover = false"
      [attr.id]="item.htmlAttributeId"
      [class.mc-uppercase]="uppercase"
    >
      {{ uppercase ? item.name : item.name.toLowerCase() }}
    </a>
    <!-- Currently there is no way to capitalize only each letter of a word in a sentence with pure CSS if its initially all uppercase, first convert to lowercase then text transform: capitalize -->
  </nav>
</div>
