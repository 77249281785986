import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import {
  WrittenContentLinkActionsUnion,
  WrittenContentLinkNavigateToPortalRouteAction,
  WrittenContentLinkOpenExternalLinkAction,
  WrittenContentLinkOpenPortalRouteAction,
  WrittenContentLinkPortalNavigationPayload,
  WrittenContentLinkRiskIndexInformationAction,
} from './models';

/**
 * Directive for modifying click behaviour in contained anchor elements.
 *
 * - Enforces ctrl-click opening links in a new tab.
 * - Enforces all clicks opening anchors with 'navigate-newtab' class in a new
 *   tab.
 * - Forces use of the router for navigating to internal links.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[mcWrittenContentAnchorClickListener]',
})
export class WrittenContentAnchorClickListenerDirective {
  /**
   * An event emitter related to actions supported or clicks on
   * links embedded in written content.
   */
  @Output() public anchorClick = new EventEmitter<WrittenContentLinkActionsUnion>();

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.tagName !== 'A' || this.isDocLibraryLink(target.getAttribute('href'))) {
      return;
    }

    const linkPath = target.getAttribute('href') || target.getAttribute('indexpath');

    const useNewTab = event.ctrlKey || target.classList.contains('navigate-newtab');

    if (
      // Do nothing if the anchor has no href or indexpath attribute.
      !linkPath ||
      // Behave as usual for external links.
      (linkPath && !useNewTab && !linkPath.startsWith('/'))
    ) {
      return;
    } else {
      const action = this.determineWrittenContentAction(linkPath, useNewTab);
      this.anchorClick.emit(action);

      // Prevent any default navigation.
      event.preventDefault();
    }
  }

  /**
   * Check whether the clicked link is a GRiD route including
   * an index as an optional route-specific parameter.
   *
   * If so, return the index ID, otherwise return null.
   *
   * @param href href string retrieved from link click event.
   */
  private checkForIndexMethodologyLink(href: string): string | null {
    const indexParamMarker = ';index=';
    const markerLength = indexParamMarker.length;

    const position = href.indexOf(indexParamMarker);
    if (position < 0) {
      return null;
    }
    const nextSeparatorPosition = Math.min(
      href.indexOf(';', position + markerLength),
      href.indexOf('?', position + markerLength),
      href.indexOf('#', position + markerLength),
    );
    return href.slice(position + markerLength, nextSeparatorPosition > -1 ? nextSeparatorPosition : href.length);
  }

  /**
   * Convert the specified linkPath to a written content link action.
   *
   * @param linkPath linkPath string retrieved from link click event.
   * @param newTab A flag indicating, whether an external link or a GRiD route
   * should be opened in a new tab.
   */
  private determineWrittenContentAction(linkPath: string, newTab: boolean): WrittenContentLinkActionsUnion {
    const indexId = this.checkForIndexMethodologyLink(linkPath);

    if (indexId !== null) {
      return new WrittenContentLinkRiskIndexInformationAction({ id: indexId });
    }

    if (newTab) {
      if (!linkPath.startsWith('/')) {
        return new WrittenContentLinkOpenExternalLinkAction({ url: linkPath });
      } else {
        return new WrittenContentLinkOpenPortalRouteAction({ url: linkPath });
      }
    }

    return new WrittenContentLinkNavigateToPortalRouteAction(this.mapPortalUrlToRoutePayload(linkPath));
  }

  /**
   * Map an href for a GRiD route to the payload accepted by
   * by the corresponding action.
   *
   * IMPORTANT: Given current requirements, this parsing logic is fairly simplistic. Should the below be insufficient
   * for new link type, the logic should be updated.
   * The logic only handles only Angular routes which contain a path segment string representing the static segments and
   * mandatory path parameters and up to one optional route-specific URL parameters.
   *
   * E.g. '/country-risk/insights/1234' or '/country-risk/analyse;view=1234'
   *
   * @param href href string retrieved from link click event.
   */
  private mapPortalUrlToRoutePayload(href: string): WrittenContentLinkPortalNavigationPayload {
    const hrefSplit: string[] = href.split(';');
    const optionalRouteSpecificParams: Record<string, string> = {};

    if (hrefSplit[1]) {
      const assignments = hrefSplit[1].split(',');
      for (const assignment of assignments) {
        const split = assignment.split('=');
        if (split.length === 2) {
          optionalRouteSpecificParams[split[0]] = split[1];
        }
      }
    }
    const payload: WrittenContentLinkPortalNavigationPayload = {
      pathSegments: hrefSplit[0],
      optionalRouteSpecificParams,
    };

    return payload;
  }

  // TODO: this method was added to fix embedded country snapshot PDFs links not working. see P2-3261
  // BE needs to be updated to supply meta data for us to use instead
  private isDocLibraryLink(linkPath: string | null): boolean {
    if (!linkPath) {
      return false;
    }
    // Support Portal1 and GRiD doc library URLs
    // /portal/api/content/doc-1234/
    // /v3/document-library/document/123/
    return /.*\/doc-\d+\/?$/.test(linkPath) || /.*\/document-library\/document\/\d+\/?/.test(linkPath);
  }
}
