import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable } from 'rxjs';

@Injectable()
export class CurrentEditionService {
  private currentEditionResourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.currentEditionResourceConfig = API_SERVICES_CONFIG.v3.currentEdition._configuration;
  }

  public getCurrentEdition(options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<string> {
    return this.http.get<string>(this.currentEditionResourceConfig, options);
  }
}
