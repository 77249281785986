import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule, LoadingModule } from '@grid-ui/common';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DataWizardExtractConfirmCancelModalComponent } from './data-wizard-extract-confirm-cancel-modal';
import { DataWizardExtractErrorModalComponent } from './data-wizard-extract-error-modal/data-wizard-extract-error-modal.component';

@NgModule({
  imports: [CommonModule, NgbModalModule, NgbTooltipModule, LoadingModule, IconModule],
  declarations: [DataWizardExtractConfirmCancelModalComponent, DataWizardExtractErrorModalComponent],
  exports: [DataWizardExtractConfirmCancelModalComponent],
})
export class DataWizardExtractModalsModule {}
