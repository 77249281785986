import { TreeBulkLeafAdditionIndividualBody, TreeBulkLeafAdditionSelectAllBody } from '../../api-services';
import { AnalyseShowAllTabs } from './analyse-show-all-tabs.type';
import { AnalyseShowAllViewTreesSearchParams } from './analyse-show-all-view-tree-search-param.interface';
import { TreeBulkAddGroupingLevels } from './tree-bulk-add-grouping-levels.type';

export enum AnalyseShowAllActionTypes {
  BULK_ADD_LEAVES = '[Analyse Show All] Bulk Add individually selected Tree Leaves from a Leaf Listing to a Selected Active View Group Node',

  BULK_ADD_SELECT_ALL_LEAVES = '[Analyse Show All] Bulk Add Tree Leaves from a paginated Leaf Listing with Select All activated to a Selected Active View Group Node',
  BULK_COPY_GROUP = '[Analyse Show All] Bulk Copy a Tree Node Group from a View Tree to a Selected Active View Group Node',
  CHANGE_TAB = '[Analyse Show All] Change the Tab shown in Show All',
  REFRESH_NODE_SEARCH = '[Analyse Show All] Refresh the search for a Node Name in Risk View Trees',
  CLOSE_SHOW_ALL = '[Analyse Show All] Close Show All',
  OPEN_SHOW_ALL = '[Analyse Show All] Open Show All',
  RELOAD_APP = '[Analyse Show All] Reload App',
  RETRY_LOAD = '[Analyse Show All] Retry Loading the Risk Views Tree Search',
}

/** Payload for an action to bulk add individually selected tree leaf nodes from Show All feature */
export interface AnalyseShowAllBulkAddIndividualLeavesPayload {
  /**
   * Grouping hierarchy, if leaf nodes should be inserted in a nested subtree, instead
   * of flat sibling noded. E.g. ['theme', 'group', 'subgroup'], or ['theme', 'subgroup'].
   * This array can have up to three distinct elements from the permissible values, where
   * the ordering determines, which nesting criterion will be applied at which level of creating
   * a nesting structure for the added leaf nodes.
   *
   * Omitting "grouping" means the nodes will be added as a flat array of siblings.
   */
  grouping?: TreeBulkAddGroupingLevels[];

  /** Body for Bulk Add Leaves request with individually selected leaves */
  requestBody: TreeBulkLeafAdditionIndividualBody;
}

/** Action emitted to bulk add individually selected leaf nodes to an active view tree */
export class AnalyseShowAllBulkAddIndividualLeaves {
  public readonly type = AnalyseShowAllActionTypes.BULK_ADD_LEAVES;
  constructor(public payload: AnalyseShowAllBulkAddIndividualLeavesPayload) {}
}

/**
 * Payload for an action to bulk add tree leaf nodes from Show All feature
 * when select all is activated in a paginated data source
 */
export interface AnalyseShowAllBulkAddSelectAllLeavesPayload {
  /** Body for Bulk Add Leaves request for paginated resources with Select All */
  requestBody: TreeBulkLeafAdditionSelectAllBody;

  /**
   * Grouping hierarchy, if leaf nodes should be inserted in a nested subtree, instead
   * of flat sibling noded. E.g. ['theme', 'group', 'subgroup'], or ['theme', 'subgroup'].
   * This array can have up to three distinct elements from the permissible values, where
   * the ordering determines, which nesting criterion will be applied at which level of creating
   * a nesting structure for the added leaf nodes.
   *
   * Omitting "grouping" means the nodes will be added as a flat array of siblings.
   */
  grouping?: TreeBulkAddGroupingLevels[];
}

/**
 * Action emitted to bulk add leaf nodes to an active view tree when
 * select all has been activated in a paginated data source
 */
export class AnalyseShowAllBulkAddWithSelectAllLeaves {
  public readonly type = AnalyseShowAllActionTypes.BULK_ADD_SELECT_ALL_LEAVES;
  constructor(public payload: AnalyseShowAllBulkAddSelectAllLeavesPayload) {}
}

/** Payload for action emitted to bulk copy a group from a searched tree to an active view tree */
export interface AnalyseShowAllBulkCopyGroupPayload {
  /**  The view id of the Risk View associated with the group to copy from */
  viewToCopyFrom: number;
  /** The group id of the node group that should be copied */
  groupToCopy: string;
  /** A flag indicating whether to copy entire nested tree structure or just the leaves. */
  grouped?: boolean;
}

/** Action emitted to bulk copy a group from a searched tree to an active view tree */
export class AnalyseShowAllBulkCopyGroup {
  public readonly type = AnalyseShowAllActionTypes.BULK_COPY_GROUP;
  constructor(public payload: AnalyseShowAllBulkCopyGroupPayload) {}
}

/** Action emitted to trigger a refresh of the View Trees Search based on updated criteria */
export class AnalyseShowAllRefreshNodeSearch {
  public readonly type = AnalyseShowAllActionTypes.REFRESH_NODE_SEARCH;
  constructor(public payload: AnalyseShowAllViewTreesSearchParams) {}
}

/** Action emitted to change the active tab in the Show All panel */
export class AnalyseShowAllChangeTab {
  public readonly type = AnalyseShowAllActionTypes.CHANGE_TAB;
  constructor(public payload: { tab: AnalyseShowAllTabs }) {}
}

/** Action emitted to trigger closing of the Show All panel */
export class AnalyseShowAllCloseShowAll {
  public readonly type = AnalyseShowAllActionTypes.CLOSE_SHOW_ALL;
  constructor() {}
}

/** Action emitted to trigger opening of the Show All panel */
export class AnalyseShowAllOpenShowAll {
  public readonly type = AnalyseShowAllActionTypes.OPEN_SHOW_ALL;
  constructor() {}
}

/** Action emitted to trigger a reload of the application */
export class AnalyseShowAllReloadApp {
  public readonly type = AnalyseShowAllActionTypes.RELOAD_APP;
  constructor() {}
}

/** Action emitted to trigger an attempt to reload a failed View Trees Search */
export class AnalyseShowAllRetryLoad {
  public readonly type = AnalyseShowAllActionTypes.RETRY_LOAD;
  constructor() {}
}

/** A union type of bulk tree edit actions supported by the Show All feature */
export type AnalyseShowAllBulkEditActionsUnion =
  | AnalyseShowAllBulkAddIndividualLeaves
  | AnalyseShowAllBulkAddWithSelectAllLeaves
  | AnalyseShowAllBulkCopyGroup;

/** A union type of actions supported by the Show All feature */
export type AnalyseShowAllActionsUnion =
  | AnalyseShowAllBulkEditActionsUnion
  | AnalyseShowAllRefreshNodeSearch
  | AnalyseShowAllChangeTab
  | AnalyseShowAllCloseShowAll
  | AnalyseShowAllOpenShowAll
  | AnalyseShowAllReloadApp
  | AnalyseShowAllRetryLoad;
