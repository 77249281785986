import Feature, { FeatureLike } from 'ol/Feature';
import { Extent, createEmpty, extend } from 'ol/extent';
import { isCluster } from './is-cluster';

export function getFeatureExtent(feature: FeatureLike): Extent | undefined {
  if (isCluster(feature)) {
    const features = feature.get('features') as Feature[];
    const extent = createEmpty();

    features.forEach((childFeature) => {
      const geometry = childFeature.getGeometry();

      if (geometry) {
        extend(extent, geometry.getExtent());
      }
    });

    return extent;
  } else {
    return feature.getGeometry()?.getExtent();
  }
}
