import { SortModelItem } from '@ag-grid-community/core';

export function mapGridSortModelToApiSortParam(
  sortModel: SortModelItem[],
  colIdMap?: Record<string, string>,
  multi = false,
): string | undefined {
  const mapCriterion = (c: SortModelItem) => {
    const colId = colIdMap ? colIdMap[c.colId] || c.colId : c.colId;
    return c.sort === 'asc' ? colId : `-${colId}`;
  };

  if (sortModel.length === 0) {
    return undefined;
  } else if (multi) {
    return sortModel.map(mapCriterion).join(',');
  } else {
    return mapCriterion(sortModel[0]);
  }
}

export function mapGridSortModelToApiSortParamV4(
  sortModel: SortModelItem[],
  colIdMap: Record<string, string>,
  multi = false,
): string | undefined {
  const mappedModel = sortModel.map((s) => (s.colId in colIdMap ? s : { ...s, colId: `attributes__${s.colId}` }));

  return mapGridSortModelToApiSortParam(mappedModel, colIdMap, multi);
}
