import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ParagraphItemValue } from '../../../shared-models';
import { WrittenContentLinkActionsUnion } from '../../listeners/written-content-anchor-click-listener';

@Component({
  selector: 'grid-ui-body-item-paragraph',
  templateUrl: './body-item-paragraph.component.html',
  styleUrls: ['./body-item-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemParagraphComponent {
  @Input() public value: ParagraphItemValue | null = null;

  @Output() public writtenContentLinkActionEmit = new EventEmitter<WrittenContentLinkActionsUnion>();

  public emitWrittenContentLinkAction(action: WrittenContentLinkActionsUnion): void {
    this.writtenContentLinkActionEmit.emit(action);
  }
}
