import { Injectable } from '@angular/core';
import { environment } from '@grid-ui/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { PortalHttpClient, WindowUtilitiesService } from '../../../..';
import { IndexMetadataResponse, IndustryMetadataResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IndustryMetadataService {
  public constructor(
    private readonly windowUtilitiesService: WindowUtilitiesService,
    private readonly http: PortalHttpClient,
  ) {}

  public getIndustriesMetaData(pageSize = 1000): Observable<IndustryMetadataResponse> {
    const url = new URL(environment.industry.frontendApi + 'industry/', this.windowUtilitiesService.getHostWithProtocol() || undefined);
    url.searchParams.append('page_size', `${pageSize}`);
    url.searchParams.append('expand', 'sector');

    return this.http.get<IndustryMetadataResponse>({ path: url.href }).pipe(shareReplay(1));
  }

  public getIndustryIndicesMetadata(pageSize = 600): Observable<IndexMetadataResponse> {
    const url = new URL(environment.industry.frontendApi + 'index/', this.windowUtilitiesService.getHostWithProtocol() || undefined);
    url.searchParams.append('page_size', `${pageSize}`);

    return this.http.get<IndexMetadataResponse>({ path: url.href }).pipe(shareReplay(1));
  }
}
