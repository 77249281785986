import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FocusboxItemValue } from '../../../shared-models';
import { WrittenContentLinkActionsUnion } from '../../listeners/written-content-anchor-click-listener';

@Component({
  selector: 'grid-ui-body-item-focusbox',
  templateUrl: './body-item-focusbox.component.html',
  styleUrls: ['./body-item-focusbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyItemFocusboxComponent {
  @Input() public value: FocusboxItemValue | null = null;

  @Output() public writtenContentLinkActionEmit = new EventEmitter<WrittenContentLinkActionsUnion>();

  public emitWrittenContentLinkAction(action: WrittenContentLinkActionsUnion): void {
    this.writtenContentLinkActionEmit.emit(action);
  }
}
