import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ErrorModule } from '../error';
import { IconModule } from '../icon';
import { LoadingModule } from '../loading';
import { DetailsNorowsOverlayComponent } from './';

@NgModule({
  declarations: [DetailsNorowsOverlayComponent],
  imports: [CommonModule, IconModule, ErrorModule, RouterModule, LoadingModule],
  exports: [DetailsNorowsOverlayComponent],
})
export class DetailsNorowsOverlayModule {}
