import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'grid-ui-unexpected-error-status',
  templateUrl: './unexpected-error-status.component.html',
  styleUrls: ['./unexpected-error-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnexpectedErrorStatusComponent {
  @Input()
  public set errorStatus(errorStatus: number | null) {
    this.errorCodeSegment = errorStatus !== null && errorStatus !== 0 ? ` (${errorStatus}).` : '.';
    this._errorStatus = errorStatus;
  }
  public get errorStatus(): number | null {
    return this._errorStatus;
  }

  public errorCodeSegment = '.';
  private _errorStatus: number | null = null;
}
