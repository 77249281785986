<div class="majorrisks" *ngIf="value && value.length">
  <h3>Major Risks</h3>
</div>

<div
  class="majorrisks-container"
  *ngFor="let row of value; index as rowIndex; trackBy: trackByIndex"
  [ngClass]="{ odd: rowIndex % 2 === 0, even: rowIndex % 2 === 1 }"
>
  <h5>{{ row.title }}</h5>
  <p>{{ row.body }}</p>
</div>
