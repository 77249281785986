import { AnalyseDataTableTreeNodeColumn } from '../../../shared-models';
import { ApiAnalyseDataTableColumn } from '../../models';

export function mapDataTableNonHistoricalColumnsFromApiToApp(apiColumns: ApiAnalyseDataTableColumn[]): AnalyseDataTableTreeNodeColumn[] {
  let indicatorParent: { referenceParent: string; referenceParentGroup: string } | null = null;
  const columns: AnalyseDataTableTreeNodeColumn[] = [];
  apiColumns.forEach((apiColumn) => {
    let column: AnalyseDataTableTreeNodeColumn;
    switch (apiColumn.type) {
      case 'group':
        column = { ...apiColumn, parent: apiColumn.parent !== null ? `${apiColumn.parent}` : null };
        indicatorParent = null;
        break;
      case 'index':
        column = { ...apiColumn, parent: `${apiColumn.parent}` };
        indicatorParent = { referenceParentGroup: column.parent, referenceParent: column.id };
        break;
      case 'indicator_group':
        column = {
          ...apiColumn,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentReference: indicatorParent!.referenceParent,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentReferenceGroup: indicatorParent!.referenceParentGroup,
        };
        break;
      case 'indicator':
        column = {
          ...apiColumn,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentReference: indicatorParent!.referenceParent,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parentReferenceGroup: indicatorParent!.referenceParentGroup,
        };
        break;
      default:
        column = apiColumn;
    }
    columns.push(column);
  });

  return columns;
}
