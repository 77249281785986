import { GlobalRoutingState, selectGlobalRoutingState } from '@grid-ui/global-routing';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AnnouncementVM,
  GLOBAL_MODULE_ANNOUNCEMENT_FEATURE_KEY,
  GlobalModuleAnnouncementState,
} from './global-module-announcement.reducer';
export const selectGlobalModuleAnnouncementState = createFeatureSelector<GlobalModuleAnnouncementState>(
  GLOBAL_MODULE_ANNOUNCEMENT_FEATURE_KEY,
);

export const selectUndissmissedAnnouncements = createSelector(
  selectGlobalModuleAnnouncementState,
  selectGlobalRoutingState,
  (state: GlobalModuleAnnouncementState, routerState: GlobalRoutingState): AnnouncementVM[] => {
    const dismissedIds = new Set(state.dismissedItems?.map(String));
    const url = routerState.currentRouteUrl;
    const module = url?.toLowerCase()?.split('/')[1];

    const moduleFilterMap = {
      'country-risk': ['Country Risk', 'Global'],
      'commodity-risk': ['Commodity Risk', 'Global'],
      industry: ['Industry Risk', 'Global'],
    };

    const filterNames = Object.entries(moduleFilterMap).reduce((a, [k, v]) => (module === k.toLowerCase() ? v : a), ['Global']);

    const announcements = state.announcements.filter((ann) => filterNames.includes(ann.module.name) && !dismissedIds.has(ann.id));

    return announcements;
  },
);

export const globalModuleAnnouncementSelectors = {
  selectUndissmissedAnnouncements,
};
