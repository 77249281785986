import { GridApi, INoRowsOverlayParams } from '@ag-grid-community/core';
import { ErrorComponentData } from '@grid-ui/common';

export enum CustomNoRowsOverlayParamsTypes {
  EMPTY = 'emptyOverlay',
  ERROR = 'errorOverlay',
  NO_MATCHES = 'noMatchesOverlay',
}

export interface CustomEmptyOverlayParams extends INoRowsOverlayParams {
  readonly type: CustomNoRowsOverlayParamsTypes.EMPTY;
}

export interface CustomErrorOverlayParams extends INoRowsOverlayParams {
  readonly type: CustomNoRowsOverlayParamsTypes.ERROR;
  gridApi: GridApi;
  error: ErrorComponentData;
}

export interface CustomNoMatchesOverlayParams extends INoRowsOverlayParams {
  readonly type: CustomNoRowsOverlayParamsTypes.NO_MATCHES;
  message: string;
}

export type CustomNoRowsOverlayParams = CustomEmptyOverlayParams | CustomErrorOverlayParams | CustomNoMatchesOverlayParams;
