export const LIST_FILTERABLE_GLOBAL_SITE_ATTRIBUTES = [
  'customer_id',
  'country_code',
  'country_name',
  'city',
  'entity_collection',
  'income-group-wb',
  'jpm-embi',
  'postcode',
  'msci-designation',
  'subregion',
  'supranational_region',
];
