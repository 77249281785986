<div tabindex="-1" role="dialog">
  <div class="modal-container">
    <div class="modal-header" #modalHeaderRef>
      <div>{{ title }}</div>
      <span (click)="downloadImage()" class="download-button" ngbTooltip="Export image" placement="top" download>
        <grid-ui-icon class="icon" name="download"></grid-ui-icon>
      </span>
      <span (click)="close()" class="close-button" ngbTooltip="Close window" placement="top">
        <grid-ui-icon class="icon" name="cross"></grid-ui-icon>
      </span>
    </div>

    <div class="modal-body">
      <div #subTitleRef>
        <h5 class="image-sub-header" *ngIf="subtitle">{{ subtitle }}</h5>
      </div>
      <div><img class="img-fluid mx-auto d-block" src="{{ url }}" alt="{{ alt }}" /></div>
    </div>

    <div class="divider"></div>

    <div class="modal-footer" #modalFooterRef>
      <div class="footer-logo-attribution d-flex flex-row justify-content-between">
        <img class="vm-logo align-self-start m-2" src="assets/img/vm-logo-tm.png" alt="vm logo" />
        <span class="attribution-text align-self-center m-2" *ngIf="attribution || caption">
          <span *ngIf="caption">{{ caption }}</span>
          <span *ngIf="attribution">Source: {{ attribution }}</span>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="offscreen" #offscreenContainerRef></div>
