import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorConfigItem, HttpErrorConfigItems, HttpErrorHandlerService } from '../../../api/services';
import { AuthorInformation, SavingRequestStatus } from '../../../shared-models';
import { AskAnAnalystRequest, AskAnAnalystService, AskAnAnalystSourceEnum } from '../../../shared-services/ask-an-analyst';
import { WindowUtilitiesService } from '../../../shared-utilities/window-services';
import { MAPLECROFT_CORPORATE_LINKED_IN } from '../constants';
import { AskAnAnalystMetaData } from '../models';

@Component({
  selector: 'grid-ui-ask-an-analyst-modal',
  templateUrl: './ask-an-analyst-modal.component.html',
  styleUrls: ['./ask-an-analyst-modal.component.scss'],
})
export class AskAnAnalystModalComponent implements OnInit {
  @Input() public analystInfo: AuthorInformation | null = null;
  @Input() public metaData: AskAnAnalystMetaData | null = null;
  @Input() public fixedSubject: string | undefined;
  @Input() public questionPlaceholder = 'Ask a question';
  @Input() public source: AskAnAnalystSourceEnum | undefined;

  public get modalTitle() {
    const isAskTheAnalyst =
      this.source === AskAnAnalystSourceEnum.CountryRiskInsights || this.source === AskAnAnalystSourceEnum.CommodityRiskInsights;

    if (isAskTheAnalyst) {
      return 'Ask the Analyst';
    }

    return 'Support';
  }

  public apiError: HttpErrorConfigItem | null = null;
  public get subject() {
    return this.fixedSubject || this._subject;
  }

  public set subject(value: string) {
    this._subject = value;
  }
  public question = '';
  public saveStatus: SavingRequestStatus = SavingRequestStatus.initial;

  public readonly MAPLECROFT_CORPORATE_LINKED_IN = MAPLECROFT_CORPORATE_LINKED_IN;
  public readonly SavingRequestStatus = SavingRequestStatus;

  private apiErrors: HttpErrorConfigItems;
  private hostWithProtocol: string | null = null;
  private _subject = '';

  public constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly askAnAnalyst: AskAnAnalystService,
    private readonly httpErrorHandlerService: HttpErrorHandlerService,
    private readonly windowUtilitiesService: WindowUtilitiesService,
  ) {
    this.apiErrors = httpErrorHandlerService.globalErrorConfig;
  }

  public ngOnInit(): void {
    this.hostWithProtocol = this.windowUtilitiesService.getHostWithProtocol();
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  public close(): void {
    this.activeModal.close();
  }

  public clearError(): void {
    // Clear the errors and it automatically returns to the subject and
    // question form
    this.apiError = null;
    this.saveStatus = SavingRequestStatus.initial;
  }

  public sendQuestion(): void {
    const request: AskAnAnalystRequest | null = this.mapAskAnAnalystRequest(
      this.subject,
      this.question,
      this.analystInfo,
      this.metaData,
      this.hostWithProtocol,
    );

    if (request) {
      this.saveStatus = SavingRequestStatus.saving;
      this.askAnAnalyst.request(request).subscribe(
        (data) => {
          this.saveStatus = SavingRequestStatus.saved;
        },
        (error) => {
          // Get the error message from the api error
          this.apiError = this.apiErrors[error.status] ? this.apiErrors[error.status] : this.apiErrors['default'];

          this.saveStatus = SavingRequestStatus.error;
        },
      );
    }
  }

  private mapAskAnAnalystRequest(
    subject: string,
    message: string,
    analystInfo: AuthorInformation | null,
    metaData: AskAnAnalystMetaData | null,
    hostWithProtocol: string | null,
  ): AskAnAnalystRequest | null {
    if (hostWithProtocol === null) {
      return null;
    }

    let requesting_url = '';
    let meta = '';

    if (metaData !== null) {
      switch (metaData.type) {
        case 'fixedSubject':
          requesting_url = hostWithProtocol;
          break;
        case 'viewAsk':
          requesting_url = `${hostWithProtocol}${metaData.meta.module}/analyse;view=${metaData.meta.viewId}`;
          meta = JSON.stringify({ config_id: metaData.meta.viewId });
          break;
        case 'writtenContentAsk':
          requesting_url = `${hostWithProtocol}${metaData.meta.module}/${metaData.meta.writtenContent}/${metaData.meta.writtenContentId}`;
          if (metaData.meta.viewId !== undefined) {
            meta = JSON.stringify({ config_id: metaData.meta.viewId });
          }
          break;
      }
    }

    const request: AskAnAnalystRequest = {
      subject,
      message,
      requesting_url,
      target_analyst: analystInfo && analystInfo.email ? analystInfo.email : '',
      meta,
      source: this.source as AskAnAnalystSourceEnum,
    };

    return request;
  }
}
